import { useEffect, useMemo } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import useAnalytics from '../../utils/useAnalytics';
import { PRODUCT_AND_SERVICES } from '../../constants/analytics';
import {
  productsAndServicesBg,
  productsAndServicesBgMobile,
  expressMediaCard,
  expressMediaCardMob,
  economyMediaCard,
  economyMediaCardMob,
  superEconomyMediaCard,
  superEconomyMediaCardMob,
} from '../../assets/images';
import { PRODUCT_AND_SERVICES as S } from '../../constants/strings.js';
import { formatMessage } from '../../utils/strings.jsx';
import {
  SocialBox,
  ListWithTitle,
  HelpfulDetails,
  MediaCard,
} from './componnents';

const ProductAndServices = () => {
  const { setInterfaceId, Trackable } = useAnalytics();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery('(max-width:1100px)');
  const cards = [
    {
      cardMedia: {
        img: expressMediaCard,
        mobImg: expressMediaCardMob,
        overlineTitle: 'Express',
        title: 'Drop off at shop',
      },
      cardContent: {
        title: 'Express Delivery - 2 Working Days',
        description:
          'Delivery to France within 2 working days.\n' +
          'You drop off at a chosen shop.\n' +
          'We deliver to receiver’s address.',
        priceExcVat: 26.58,
        priceIncVat: 28.13,
      },
    },
    {
      cardMedia: {
        img: economyMediaCard,
        mobImg: economyMediaCardMob,
        overlineTitle: 'Economy',
        title: 'Drop off at shop',
      },
      cardContent: {
        title: 'Air Classic - 2 to 3 Working Days',
        description:
          'Delivery to France within 2 to 3 working days.\n' +
          'You drop off at a chosen shop \n' +
          'We deliver to receiver’s address.',
        priceExcVat: 26.66,
        priceIncVat: 31.99,
      },
    },
    {
      cardMedia: {
        img: superEconomyMediaCard,
        mobImg: superEconomyMediaCardMob,
        overlineTitle: 'Super economy',
        title: 'Drop off at shop',
      },
      cardContent: {
        title: 'DPD Classic - 3 to 4 Working Days',
        description:
          'Delivery to France within 3 to 4 working days.\n' +
          'You drop off at a chosen shop.\n' +
          'We deliver to receiver’s address.',
        priceExcVat: 10.79,
        priceIncVat: 12.95,
      },
    },
  ];

  useEffect(() => {
    setInterfaceId(PRODUCT_AND_SERVICES.INTERFACE_ID);
  }, []);

  const mainImage = useMemo(
    () => (isMobile ? productsAndServicesBgMobile : productsAndServicesBg),
    [isMobile]
  );

  return (
    <Trackable loadId={PRODUCT_AND_SERVICES.LOAD}>
      <Box component='img' src={mainImage} />
      <Box
        sx={{
          padding: isMobile ? '16px 32px' : '32px 64px',
        }}
      >
        <Box>
          <Typography
            variant='overline'
            sx={{
              textTransform: 'uppercase',
              lineHeight: '266%',
              letterSpacing: '1px',
              fontSize: '14px',
              paddingBottom: '8px',
            }}
          >
            {S.MAIN_OVERLINE_HEADER}
          </Typography>
          <Typography
            variant={'h1'}
            sx={{
              fontSize: '34px',
              fontStyle: 'normal',
              lineHeight: '116.7%',
              fontWeight: 400,
              paddingBottom: '8px',
            }}
          >
            {S.UK_TO_FRANCE_TITLE}
          </Typography>
          <Typography
            variant={'subtitle1'}
            sx={{
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '175%',
              letterSpacing: '0.15px',
              paddingBottom: '8px',
            }}
          >
            {formatMessage(S.PRICE_START_FROM, 10.79)}
          </Typography>
          <Typography
            variant={'body1'}
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '150%',
              letterSpacing: '0.15px',
            }}
          >
            {S.THE_CHOICE_IS_YOURS}
          </Typography>
          <SocialBox />
          {S.LISTS_WITH_TITLES.map(({ LIST, TITLE }, index) => (
            <ListWithTitle key={index} title={TITLE} listItems={LIST} />
          ))}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: '1200px',
              flexDirection: isSmallScreen ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            {cards.map(({ cardContent, cardMedia }, index) => (
              <MediaCard
                key={index}
                cardContent={cardContent}
                cardMedia={cardMedia}
                isMobile={isSmallScreen}
              />
            ))}
          </Box>
          <HelpfulDetails
            title={S.HELPFUL_DETAILS.TITLE}
            details={S.HELPFUL_DETAILS.DETAILS}
          />
        </Box>
      </Box>
    </Trackable>
  );
};

export default ProductAndServices;
