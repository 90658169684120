import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';

import { sentryReduxEnhancer } from '../sentry';
import { AuthReducer } from '../features/Auth';
import { VerifyEmailReducer } from '../pages/Auth/slice';
import { OrderReducer } from '../pages/Order/slice';
import AnalyticsReducer from '../features/Analytics/slice';
import addressBooksSlice from './addressBooksSlice';
import parcelsSlice from './parcelsSlice';
import accountDetails from './accountDetailsSlice';
import { countriesReducer } from './countriesSlice';
import { networksReducer } from './networksSlice';
import { BasketReducer } from './basket';
import parcelSlice from './parcelSlice';

const isDevMode = import.meta.env.NODE_ENV !== 'production';

export const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // Persist only these reducers data
  whitelist: ['basket', 'orders', 'countries'],
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  verifyEmail: VerifyEmailReducer,
  order: OrderReducer,
  analytics: AnalyticsReducer,
  addressBook: addressBooksSlice.reducer,
  parcels: parcelsSlice.reducer,
  parcel: parcelSlice.reducer,
  accountDetails: accountDetails.reducer,
  countries: countriesReducer,
  networks: networksReducer,
  basket: BasketReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const stateSyncMiddleware = createStateSyncMiddleware({
  predicate: action => {
    // Sync state only for the selected actions
    const actionPrefixes = ['auth/'];

    const stateSync = actionPrefixes.some(actionPrefix =>
      action.type.includes(actionPrefix)
    );

    return stateSync;
  },
  broadcastChannelOption: { type: 'localstorage' },
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(stateSyncMiddleware),
  enhancers: getDefaultEnhancers =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
  devTools: isDevMode,
});

// Initialize the message listener for syncing between tabs
initMessageListener(store);

export const persistor = persistStore(store);
