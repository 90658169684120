import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { fetchAccountDetails } from '../../../redux/accountDetailsSlice';
import Loader from '../../../features/Loader/Loader';
import AccountDetails from './components/AccountDetails';
import DeleteAccount from './components/DeleteAccount';
import CommunicationPreferences from './components/CommunicationPreferences';

const AccountSection = () => {
  const isSmallScreen = useBreakpoint('sm');
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        background: theme => theme.palette.primary.pageBackground,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 2,
        paddingBottom: 4,
        paddingX: isSmallScreen ? 0 : 1,
      }}
    >
      <Loader promiseFn={() => dispatch(fetchAccountDetails())}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: '680px' }}
        >
          <AccountDetails />
          <CommunicationPreferences />
          <DeleteAccount />
        </Box>
      </Loader>
    </Box>
  );
};

export default AccountSection;
