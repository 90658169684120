import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export const Step = ({ children, isActive }) => (
  <Box
    style={{
      opacity: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 0,
      ...(isActive && {
        opacity: 1,
        pointerEvents: 'inherit',
        position: 'relative',
        zIndex: 1,
        display: 'flex',
      }),
    }}
  >
    {children}
  </Box>
);

Step.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  transitions: PropTypes.string,
};

export default Step;
