import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { referenceApis } from '../apis';

const initialState = {
  loading: 'idle',
  data: [],
};

const fetchNetworks = createAsyncThunk('networks/fetch', async data => {
  const response = await referenceApis.fetchNetworks(data);
  return response.data;
});

const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNetworks.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

const getNetworks = state => state.networks.data || [];

export const networksReducer = networksSlice.reducer;

export const networksActions = {
  fetchNetworks,
};

export const networksSelectors = {
  getNetworks,
};
