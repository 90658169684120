import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname, search } = useLocation();
  const step = search?.match(/step=([^&]*)/)?.[1];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, step]);

  return null;
}

export default ScrollToTop;
