import { createContext, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
// import { purgeStoredState } from 'redux-persist';
import { useNavigate } from 'react-router';
import useErrorHandler from '../../hooks/useErrorHandler';
import * as Actions from '../../features/Auth/actions';
// import { persistor, persistConfig } from '../../redux/store';
import { AUTH } from '../../constants/strings';

export const AuthContext = createContext({});

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const { errorHandler } = useErrorHandler();
  const navigate = useNavigate();

  const signOut = useCallback(
    // Redirect to Home by default
    async (redirectUrl = '/') => {
      // Signout
      try {
        await dispatch(Actions.logoutSession());
      } catch (error) {
        errorHandler(AUTH.SIGNOUT_ERROR, error, 'SignOut');
      }

      // Clear persisted state from storage using persistConfig
      // await purgeStoredState(persistConfig);

      // Clear in-memory persistor state
      // await persistor.purge();

      // Renew session
      // await dispatch(Actions.getCurrentSession());

      // Redirect after signing out
      navigate(redirectUrl);
    },
    [dispatch, errorHandler, navigate]
  );

  return {
    ...auth,
    signOut,
  };
};

// eslint-disable-next-line react/display-name, react-refresh/only-export-components
export const withAuth = Component => props =>
  (
    <AuthContext.Consumer>
      {context => <Component {...props} auth={context} />}
    </AuthContext.Consumer>
  );
