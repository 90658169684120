import { useTracker, Trackable } from '@dpdgroupuk/react-event-tracker';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInterfaceId, selectInterfaceId } from '../features/Analytics/slice';
import { IS_DEV_MODE } from '../constants/env';

const ACTION_ID_ATTRIBUTE = 'actionid';

// Analytics event actions tracking
const useAnalytics = () => {
  const dispatch = useDispatch();
  const tracker = useTracker();
  const interfaceId = useSelector(selectInterfaceId);
  const showDebugMessage = (message, element) =>
    IS_DEV_MODE ? console.warn(message, element) : undefined;

  // Track action event by element ACTION_ID_ATTRIBUTE
  const trackAction = useCallback(
    event => {
      if (!event) {
        showDebugMessage('Bad event for tracking');

        return;
      }

      const actionId = event.currentTarget.getAttribute(ACTION_ID_ATTRIBUTE);
      if (actionId) {
        tracker.logEvent(actionId);
      } else {
        showDebugMessage(
          'actionId attribute is not defined for the element',
          event.currentTarget
        );
      }
    },
    [tracker]
  );

  const handleSetInterfaceId = newInterfaceId => {
    dispatch(setInterfaceId(newInterfaceId));
  };

  // Ensure Trackable component reacts on interfaceId change
  // Usage of pure Trackable doesn't change interfaceId
  const TrackableComponent = useCallback(
    props =>
      interfaceId
        ? React.createElement(Trackable, { ...props, interfaceId })
        : null,
    [interfaceId]
  );

  return {
    trackAction,
    Trackable: TrackableComponent,
    setInterfaceId: handleSetInterfaceId,
    interfaceId,
  };
};

export default useAnalytics;
