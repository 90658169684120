import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Portal,
  Typography,
  styled,
} from '@mui/material';
import { ArrowDropUp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { SUMMARY_DETAILS } from '../../constants/strings';

const StyledAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'flex-end',
  },
});

const SummaryDetails = ({ basicPrice }) => {
  const detailsContent = (
    <>
      <Typography variant='h3'>{SUMMARY_DETAILS.TITLE}</Typography>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='body2'>2 kg Parcel</Typography>
          <Typography variant='body2'>{basicPrice}</Typography>
        </Box>
        <Typography variant='body2' color='text.secondary'>
          20cm x 35cm x 50cm
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant='body2'>
            {SUMMARY_DETAILS.STANDARD_DELIVERY}
          </Typography>
          <Typography variant='body2'>Free</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant='body2'>
            {SUMMARY_DETAILS.COLLECT_PARCEL}
          </Typography>
          <Typography variant='body2'>5.00</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant='body2'>
            {SUMMARY_DETAILS.DELIVER_RECIPIENT}
          </Typography>
          <Typography variant='body2'>2.00</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant='body2'>
            {SUMMARY_DETAILS.INSURANCE_COVER}
          </Typography>
          <Typography variant='body2'>Free</Typography>
        </Box>
      </Box>
    </>
  );

  const totalContent = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: { xs: 'center', md: 'start' },
        }}
      >
        <Typography
          variant='h3'
          component='span'
          color='primary.main'
          sx={{ mr: 1 }}
        >
          {SUMMARY_DETAILS.TOTAL}
        </Typography>
        <Typography variant='body2' component='span' color='text.secondary'>
          {SUMMARY_DETAILS.TOTAL_VAT}
        </Typography>
      </Box>
      <Typography variant='h3' color='primary.main'>
        15.00
      </Typography>
    </Box>
  );

  return (
    <>
      <Paper sx={{ p: 2, mb: 2, display: { xs: 'none', md: 'block' } }}>
        {detailsContent}
        {totalContent}
      </Paper>
      <Portal>
        <Box
          sx={{
            display: { md: 'none' },
            position: 'sticky',
            bottom: 0,
            zIndex: 3,
            width: '100%',
          }}
        >
          <Accordion elevation={4}>
            <StyledAccordionSummary
              expandIcon={<ArrowDropUp />}
              sx={{
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
                p: 1,
              }}
            >
              {totalContent}
            </StyledAccordionSummary>
            <AccordionDetails>{detailsContent}</AccordionDetails>
          </Accordion>
        </Box>
      </Portal>
    </>
  );
};

SummaryDetails.propTypes = {
  basicPrice: PropTypes.number,
};

export default SummaryDetails;
