import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import StaticMapPreview from '../MapBox/StaticMapPreview';
import { ADDRESS_WIDGET } from '../../constants/strings';

const AddressWidget = ({ address, coordinates, onEditClick }) => {
  const { street, county, locality, postcode } = address;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          p: 1,
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          gap: 2,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        {coordinates ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <StaticMapPreview
              address={street}
              longitude={coordinates.longitude}
              latitude={coordinates.latitude}
              zoom={14}
              style={{
                borderRadius: 16,
              }}
              width={320}
              height={150}
            />
          </Box>
        ) : null}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ pl: 2 }}>
            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
              {street}
            </Typography>
            <Typography variant='body2'>{locality}</Typography>
            <Typography variant='body2'>{county}</Typography>
            <Typography variant='body2'>{postcode}</Typography>
          </Box>
          <Button
            variant='outlined'
            fullWidth
            sx={{ mt: 2 }}
            onClick={onEditClick}
          >
            {ADDRESS_WIDGET.EDIT}
          </Button>
        </Box>
      </Box>
    </>
  );
};

AddressWidget.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    county: PropTypes.string,
    locality: PropTypes.string,
    postcode: PropTypes.string,
    organisation: PropTypes.string,
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  onEditClick: PropTypes.func,
};

export default AddressWidget;
