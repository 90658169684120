import { createSelector } from 'reselect';

export const getAuthData = state => state.auth;

export const getAuthRetryAfter = createSelector(
  getAuthData,
  data => data.error?.retryAfter
);

export const getCurrentSession = createSelector(
  getAuthData,
  data => data.currentSession
);

export const getIsLoading = createSelector(getAuthData, data => data.isLoading);
