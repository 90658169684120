import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Typography,
  IconButton,
  styled,
  Box,
  Input,
  CircularProgress,
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { saveAs } from 'file-saver';
import { useTheme } from '@emotion/react';
import Close from '@mui/icons-material/Close';
import { ADDRESSES_SECTION } from '../../../../constants/strings';
import { addressBookApis } from '../../../../apis';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import addressBooksSlice from '../../../../redux/addressBooksSlice';
import { DEFAULT_PAGINATION_SEARCH_PAGE_SIZE } from '../../../../constants/pagination';

const HiddenInput = styled(Input)(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50 %)',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  height: 1,
}));

const LoadingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
}));

const AddressBookBulkUploadModal = ({ isOpen, onClose, currentPage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [abortController, setAbortController] = useState(null);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { showSuccess } = useCustomSnackbar();
  const { errorHandler } = useErrorHandler();

  const onCloseHandler = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const onUploadFileHandler = useCallback(
    async event => {
      const addressBookFile = event.target.files[0];

      if (addressBookFile) {
        setIsLoading(true);

        const controller = new AbortController();
        const { signal } = controller;
        setAbortController(controller);

        const formData = new FormData();
        formData.append('file', addressBookFile);

        try {
          await addressBookApis.uploadAddressBookFile(formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            signal,
          });
          await addressBookApis.importAddressBook({
            signal,
          });

          onClose();

          dispatch(
            addressBooksSlice.actions.fetchAddressBooks({
              searchPage: currentPage,
              searchPageSize: DEFAULT_PAGINATION_SEARCH_PAGE_SIZE,
            })
          );

          showSuccess({
            message: ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.BULK_UPLOAD,
            variant: 'default',
          });
        } catch (error) {
          if (error.code === 'ERR_CANCELED') {
            setError(ADDRESSES_SECTION.BULK_UPLOAD_MODAL.CANCEL_ERROR);
          } else if (error?.response) {
            setError(error.response.data.error.message);
          } else {
            setError(error?.message);
          }
        } finally {
          setIsLoading(false);
          setAbortController(null);
        }
      }
    },
    [currentPage, dispatch, onClose, showSuccess]
  );

  const onCancelRequestHandler = useCallback(() => {
    if (abortController) {
      abortController.abort();
    }
  }, [abortController]);

  const onDownloadFileHandler = useCallback(async () => {
    try {
      const response = await addressBookApis.downloadAddressBookTemplate();
      const blob = new Blob([response.data], {
        type: 'text/csv;charset=utf-8;',
      });
      saveAs(blob, 'template.csv');
    } catch (error) {
      errorHandler(
        ADDRESSES_SECTION.NOTIFICATIONS.ERROR.DOWNLOAD_TEMPLATE,
        error,
        'AddressBookBulkUploadModal'
      );
    }
  }, [errorHandler]);

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseHandler}
      onTransitionExited={() => setError(null)}
      PaperProps={{ sx: { borderRadius: '8px' } }}
    >
      <DialogTitle
        variant='h3'
        sx={{
          padding: theme.spacing(2),
          paddingBottom: theme.spacing(1),
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {error && (
            <ReportProblemIcon
              sx={{ color: theme.palette.primary.main, mr: theme.spacing(2) }}
            />
          )}
          {ADDRESSES_SECTION.BULK_UPLOAD_MODAL.TITLE}
        </Box>
        <IconButton aria-label='close' onClick={onCloseHandler}>
          <Close sx={{ height: 20, width: 20 }} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{ padding: theme.spacing(2), width: { xs: 340, md: 500 } }}
      >
        {isLoading ? (
          <>
            <Typography
              variant='subtitle2'
              sx={{ color: theme.palette.primary.main }}
            >
              {ADDRESSES_SECTION.BULK_UPLOAD_MODAL.LOADING}
            </Typography>
            <LoadingBox theme={theme}>
              <CircularProgress color='primary' />
            </LoadingBox>
          </>
        ) : (
          <>
            {error ? (
              <>
                <Typography
                  variant='subtitle2'
                  sx={{ color: theme.palette.primary.main }}
                >
                  {ADDRESSES_SECTION.BULK_UPLOAD_MODAL.ERROR}
                </Typography>
                <Typography sx={{ mt: theme.spacing(2) }}>{error}</Typography>
              </>
            ) : (
              <>
                <Typography
                  variant='subtitle2'
                  sx={{ color: theme.palette.primary.main }}
                >
                  {
                    ADDRESSES_SECTION.BULK_UPLOAD_MODAL
                      .DOWNLOAD_TEMPLATE_CSV_TITLE
                  }
                </Typography>
                <Typography variant='body1' sx={{ mt: theme.spacing(2) }}>
                  {
                    ADDRESSES_SECTION.BULK_UPLOAD_MODAL
                      .DOWNLOAD_TEMPLATE_CSV_TEXT
                  }
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{
                    color: theme.palette.primary.main,
                    mt: theme.spacing(2),
                  }}
                >
                  {
                    ADDRESSES_SECTION.BULK_UPLOAD_MODAL
                      .UPLOAD_TEMPLATE_CSV_TITLE
                  }
                </Typography>
                <Typography variant='body1' sx={{ mt: theme.spacing(2) }}>
                  {ADDRESSES_SECTION.BULK_UPLOAD_MODAL.UPLOAD_TEMPLATE_CSV_TEXT}
                </Typography>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          padding: theme.spacing(2),
          paddingTop: theme.spacing(1),
          flexDirection: { xs: 'column', md: 'row' },
        }}
        disableSpacing
      >
        {isLoading ? (
          <Button
            variant='contained'
            onClick={onCancelRequestHandler}
            sx={{
              paddingX: theme.spacing(3),
              width: { xs: '100%', md: 'auto' },
              justifySelf: 'right',
            }}
          >
            {ADDRESSES_SECTION.BULK_UPLOAD_MODAL.BUTTONS.CANCEL}
          </Button>
        ) : (
          <>
            {error ? (
              <>
                <Button
                  variant='outlined'
                  onClick={onCloseHandler}
                  sx={{ flex: 1, width: '100%' }}
                >
                  {ADDRESSES_SECTION.BULK_UPLOAD_MODAL.BUTTONS.CANCEL}
                </Button>
                <Button
                  component='label'
                  variant='contained'
                  sx={{
                    flex: 1,
                    width: '100%',
                    ml: { xs: 0, md: theme.spacing(1) },
                    mt: { xs: theme.spacing(1), md: 0 },
                  }}
                >
                  {ADDRESSES_SECTION.BULK_UPLOAD_MODAL.BUTTONS.TRY_AGAIN}
                  <HiddenInput
                    type='file'
                    inputProps={{ accept: '.csv' }}
                    onChange={onUploadFileHandler}
                  />
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant='outlined'
                  onClick={onDownloadFileHandler}
                  sx={{ flex: 1, width: '100%' }}
                >
                  {
                    ADDRESSES_SECTION.BULK_UPLOAD_MODAL.BUTTONS
                      .DOWNLOAD_TEMPLATE
                  }
                </Button>
                <Button
                  component='label'
                  variant='contained'
                  sx={{
                    flex: 1,
                    width: '100%',
                    ml: { xs: 0, md: theme.spacing(1) },
                    mt: { xs: theme.spacing(1), md: 0 },
                  }}
                >
                  {ADDRESSES_SECTION.BULK_UPLOAD_MODAL.BUTTONS.CHOOSE_FILE}
                  <HiddenInput
                    type='file'
                    inputProps={{ accept: '.csv' }}
                    onChange={onUploadFileHandler}
                  />
                </Button>
              </>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

AddressBookBulkUploadModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
};

export default AddressBookBulkUploadModal;
