import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { addressBookApis } from '../apis';
import {
  normalizeAddressBookDataFromResponse,
  sortAddressBooks,
} from '../utils/addressBook';
import { ADDRESS_BOOK_INITIAL_FORM_VALUES } from '../constants/addressBook';
import { DEFAULT_PAGINATION_SEARCH_OVERVIEW_SIZE } from '../constants/pagination';
import { ArrayUtil } from '../utils';

const initialState = {
  data: [],
  selectedAddressBook: ADDRESS_BOOK_INITIAL_FORM_VALUES,
};

const defaultFetchAddressBooksParams = {
  searchPage: 1,
  searchPageSize: DEFAULT_PAGINATION_SEARCH_OVERVIEW_SIZE,
};

const fetchAddressBooks = createAsyncThunk(
  'addressBook/filter',
  async ({ searchPage, searchPageSize } = defaultFetchAddressBooksParams) => {
    const response = await addressBookApis.getAddressBooks({
      searchPage,
      searchPageSize,
    });
    return response.data;
  }
);

const getAddressBook = createAsyncThunk(
  'addressBook/get',
  async addressBookId => {
    try {
      const { data } = await addressBookApis.getAddressBook(addressBookId);
      return data;
    } catch (error) {
      if (error.response) {
        throw error.response?.data;
      } else {
        throw error;
      }
    }
  }
);

const updateAddressBook = createAsyncThunk(
  'addressBook/update',
  async ({ addressBookId, addressBookData }) => {
    try {
      const { data } = await addressBookApis.updateAddressBook({
        addressBookId,
        addressBookData,
      });
      return data;
    } catch (error) {
      if (error.response) {
        throw error.response?.data;
      } else {
        throw error;
      }
    }
  }
);

const deleteAddressBook = createAsyncThunk(
  'addressBook/delete',
  async addressBookId => {
    try {
      const { data } = await addressBookApis.deleteAddressBook(addressBookId);
      return data?.addressBookId;
    } catch (error) {
      if (error.response) {
        throw error.response?.data;
      } else {
        throw error;
      }
    }
  }
);

const createAddressBook = createAsyncThunk(
  'addressBook/create',
  async addressBookData => {
    try {
      const { data } = await addressBookApis.createAddressBook(addressBookData);
      return data;
    } catch (error) {
      if (error.response) {
        throw error.response?.data;
      } else {
        throw error;
      }
    }
  }
);

const slice = createSlice({
  name: 'addressBook',
  initialState,
  reducers: {
    resetSelectedAddressBook(state) {
      state.selectedAddressBook = ADDRESS_BOOK_INITIAL_FORM_VALUES;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAddressBooks.fulfilled, (state, { payload }) => {
        const { addressBooks } = payload;

        addressBooks.forEach(ab => {
          const index = findIndex(
            state.data,
            a => a.addressBookId === ab.addressBookId
          );

          if (index !== -1) {
            state.data[index] = ab; // Update the item in the array
          } else {
            state.data = [...state.data, ab];
          }
        });
        return state;
      })
      .addCase(createAddressBook.fulfilled, (state, { payload }) => {
        state.data = [...state.data, payload];
      })
      .addCase(updateAddressBook.fulfilled, (state, { payload }) => {
        const index = findIndex(
          state.data,
          a => a.addressBookId === payload.addressBookId
        );
        if (index !== -1) {
          if (payload.isDefault) {
            // reset default value
            state.data.forEach(item => {
              item.isDefault = false;
            });
          }
          state.data[index] = payload; // Update the item in the array
        }
      })
      .addCase(deleteAddressBook.fulfilled, (state, { payload }) => {
        const index = findIndex(state.data, a => a.addressBookId === payload);
        if (index !== -1) {
          state.data.splice(index, 1);
        }
      })
      .addCase(getAddressBook.fulfilled, (state, { payload }) => {
        state.selectedAddressBook = {
          ...normalizeAddressBookDataFromResponse(payload),
        };
      });
  },
});

const getAddressBooksState = state => state.addressBook;

const getAddressBooks = createSelector([getAddressBooksState], state =>
  sortAddressBooks([...state.data])
);

const getAddressBooksByPage = createSelector(
  [getAddressBooks, (state, params) => params],
  (data, params) => ArrayUtil.slicePage(data, params)
);

const getAddressBookLength = createSelector(
  [getAddressBooksState],
  state => state.data.length
);

const getSelectedAddressBook = createSelector(
  [getAddressBooksState],
  addressBook => addressBook.selectedAddressBook
);

const getDefaultAddressBook = createSelector(
  [getAddressBooksState],
  addressBook => addressBook.data.find(addressBook => addressBook.isDefault)
);

export default {
  reducer: slice.reducer,
  actions: {
    fetchAddressBooks,
    getAddressBook,
    updateAddressBook,
    deleteAddressBook,
    createAddressBook,
    resetSelectedAddressBook: slice.actions.resetSelectedAddressBook,
  },
  selectors: {
    getAddressBookLength,
    getDefaultAddressBook,
    getSelectedAddressBook,
    getAddressBooksByPage,
  },
};
