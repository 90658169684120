import { Box, Typography, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { SALES_CONDITIONS } from '../../constants/strings';
import { DPO_PRIVACY_NOTICE } from '../../constants/externalLinks';

const InformationCollecting = ({ sx }) => (
  <Box sx={sx}>
    <Typography variant='body1'>
      {SALES_CONDITIONS.INFORMATION_COLLECTING}
      <Link href={DPO_PRIVACY_NOTICE} sx={{ ml: 1 }} target='_blank'>
        {SALES_CONDITIONS.MORE_ABOUT_DATA_MANAGEMENT}
      </Link>
    </Typography>
  </Box>
);

InformationCollecting.propTypes = {
  sx: PropTypes.object,
};

export default InformationCollecting;
