import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthSelectors } from '../features/Auth';
import { countriesActions } from './countriesSlice';
import addressBooksSlice from './addressBooksSlice';
import accountDetailsSlice from './accountDetailsSlice';
import { BasketActions } from './basket';

export const loadAppData = createAsyncThunk(
  'app/loadInitialData',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const promises = [dispatch(countriesActions.fetchCountries())];
      const currentSession = AuthSelectors.getCurrentSession(getState());
      if (currentSession.basketId) {
        promises.push(dispatch(BasketActions.getBasketItems()));
      }
      if (currentSession.uid) {
        promises.push(
          dispatch(accountDetailsSlice.actions.fetchAccountDetails())
        );
        // we are looking only for default address book here
        // need to do basic logic on BE to return default address always at first position
        promises.push(dispatch(addressBooksSlice.actions.fetchAddressBooks()));
      }
      return await Promise.all(promises);
    } catch (e) {
      if (e.response) {
        return rejectWithValue(e.response.data);
      } else {
        throw e;
      }
    }
  }
);
