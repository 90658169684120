import { Box, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import get from 'lodash/get';
import ReduxifiedWizard from '../../features/Wizard/ReduxifiedWizard';
import Error from '../Auth/Error.jsx';
import Loader from '../../features/Loader/Loader.jsx';
import { BasketSelectors } from '../../redux/basket';
import Navigation from './components/Navigation.jsx';
import ParcelDetails from './pages/ParcelDetails';
import { OrderSlice } from './slice';
import AddressDetails from './pages/AddressDetails';
import Payment from './pages/Payment';

import { REFERENCE_NAME } from './constants.js';

const Order = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const basketItemId = searchParams.get('basketItemId');
  const basketItem = useSelector(state =>
    BasketSelectors.getBasketItemById(state, basketItemId)
  );
  const basketItemFromHistoryState = get(location, 'state.shipment');

  // TODO: Potentially we will need to add redirect to home if no basketItem?.shipment or basketItemFromHistoryState present
  const onLoad = useCallback(async () => {
    dispatch(
      OrderSlice.actions.initialize({
        values: basketItem?.shipment || basketItemFromHistoryState,
        references: {
          networks: [],
          collectionDates: [],
          [REFERENCE_NAME.COLLECTION_PICKUP]: null,
          [REFERENCE_NAME.DELIVERY_PICKUP]: null,
        },
      })
    );
  }, []);

  return (
    <Loader
      promiseFn={onLoad}
      errorComponent={error => <Error values={{ error }} />}
    >
      <Box
        sx={{
          py: { xs: 2 },
          p: { md: 4 },
          background: theme.palette.primary.pageBackground,
          flexGrow: 1,
        }}
      >
        <Grid container spacing={0} alignItems='center' justifyContent='center'>
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <ReduxifiedWizard
              isLazyMount={true}
              isStepValues={false}
              slice={OrderSlice}
              nav={<Navigation />}
            >
              <ParcelDetails stepName='parcelDetails' />
              <AddressDetails stepName='addressDetails' />
              <Payment stepName='payment' />
            </ReduxifiedWizard>
          </Grid>
        </Grid>
      </Box>
    </Loader>
  );
};

export default Order;
