import { filter } from 'lodash';
import moment from 'moment-timezone';
import { USER_TYPE } from '../constants';
import { DATE_FORMAT } from '../../../constants';
import { DateUtil } from '../../../utils';

export const getSenderUserType = shipment => {
  let senderUserType = shipment.outboundConsignment.collectionDetails._userType;
  if (!senderUserType) {
    const organisation =
      shipment.outboundConsignment.collectionDetails.address.organisation;
    return organisation ? USER_TYPE.COMPANY : USER_TYPE.INDIVIDUAL;
  }
  return USER_TYPE.INDIVIDUAL;
};

export const getReceiverUserType = shipment => {
  let receiverUserType = shipment.outboundConsignment.deliveryDetails._userType;
  if (!receiverUserType) {
    const organisation =
      shipment.outboundConsignment.deliveryDetails.address.organisation;
    return organisation ? USER_TYPE.COMPANY : USER_TYPE.INDIVIDUAL;
  }
  return USER_TYPE.INDIVIDUAL;
};

export const getCutOffDates = dates =>
  filter(dates, date =>
    DateUtil.getUKCurrentMomentTime().isBefore(
      moment.tz(
        `${date.date} ${date.collectionCutOff}`,
        `${DATE_FORMAT.DEFAULT_DATE_FORMAT} ${DATE_FORMAT.HH_MM_TIME_FORMAT}`,
        DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
      )
    )
  );

export const formatShipmentDate = ({ date, collectionCutOff }) =>
  moment
    .tz(
      `${date} ${collectionCutOff}`,
      `${DATE_FORMAT.DEFAULT_DATE_FORMAT} ${DATE_FORMAT.HH_MM_TIME_FORMAT}`,
      DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
    )
    .format(DATE_FORMAT.DATE_TIME_FORMAT);
