import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, MenuItem, Paper, Typography } from '@mui/material';
import { TextField as TextFieldRFF } from 'mui-rff';
import { Add, Delete } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { cloneDeep, get, remove } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import * as STRINGS from '../../../../constants/strings';
import {
  ADD_ANOTHER_PARCEL,
  AVAILABLE_SERVICES,
  BACK,
  CHOSEN_PICKUP_POINT,
  COLLECTION_DATE_HEADER,
  DELETE_PARCEL,
  DELIVER_TO_RECIPIENT,
  DELIVER_TO_RECIPIENT_DESCRIPTION,
  DIRECT_TO_PICKUP,
  DIRECT_TO_PICKUP_DESCRIPTION,
  HEIGHT,
  HOW_BIG_IS_YOUR_PARCEL,
  LENGTH,
  MAX_PARCEL_HEIGHT_$,
  MAX_PARCEL_LENGTH_$,
  MAX_PARCEL_WEIGHT_$,
  MAX_PARCEL_WIDTH_$,
  MAX_QUANTITY_$,
  MOST_POPULAR,
  NEXT_STEP,
  NO_DROP_OFF,
  NO_DROP_OFF_DESCRIPTION,
  PARCEL_DESCRIPTION,
  QUANTITY,
  QUANTITY_DESCRIPTION,
  VIEW_MAX_PARCEL_SIZES,
  VIEW_NEARBY_PICKUPS,
  WE_ARE_CALCULATING_SERVICES,
  WEIGHT,
  WHEN_DELIVER,
  WHERE_DELIVER_PARCEL_TO,
  WHERE_YOUR_PARCEL_FROM,
  WHERE_YOUR_PARCEL_GOES,
  WIDTH,
  WOULD_YOU_LIKE_COLLECTION,
  WOULD_YOU_LIKE_INSURANCE,
  YES_COLLECT,
  YES_COLLECT_DESCRIPTION,
} from '../../../../constants/strings';
import useAnalytics from '../../../../utils/useAnalytics';
import { PARCEL_DETAILS } from '../../../../constants/analytics';
import { SummaryDetails } from '../../../../components/SummaryDetails';
import { networksActions } from '../../../../redux/networksSlice';
import {
  COLLECTION_ADDRESS_KEY,
  DESTINATION_ADDRESS_KEY,
  SHIPMENT_FIELDS,
} from '../../../../constants/forms';
import ActionButton from '../../../../components/ActionButton';
import MaxParcelDimsModal from '../../../../components/MaxParcelDimsModal/MaxParcelDimsModal';
import {
  getNetworksSchema,
  requireKeysSchema,
  validateWithJoi,
} from '../../../../utils/validators';
import PickupModal from '../../../../components/PickupModal';
import { trim } from '../../../../utils/formatters';
import WizardForm from '../../../../features/Wizard/Form';
import AddressDetailsForm from '../../../../components/AddressDetailsForm/AddressDetailsForm';
import {
  getAdditionalPriceForNetwork,
  getMinDoorPriceFromNetworks,
  getMinPriceFromNetworks,
  getMinShopPriceFromNetworks,
  getParcelsTotalWeight,
  getParcelTotalQuantity,
} from '../../../../utils/networks';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import PickupPointWidget from '../../../../components/PickupPointwidget/PickupPointWidget';
import { BasketActions } from '../../../../redux/basket';
import { POINT_TYPE } from '../../../../constants';
import { useOverlay } from '../../../../features/Overlay';
import { ShipmentValidator } from '../../validators';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import { PickupHelper } from '../../helpers';
import { useReferencesLoader } from '../../hooks';
import { REFERENCE_NAME } from '../../constants';
import { DATE_TIME_FORMAT } from '../../../../constants/dateFormat';
import * as StepSelectors from './selectors';
import { fetchCollectionDates } from './actions';

const ParcelDetails = ({ nextStep, values, references, setReferences }) => {
  const navigate = useNavigate();
  const { setInterfaceId, Trackable } = useAnalytics();
  const theme = useTheme();
  const overlay = useOverlay();
  const isSmallScreen = useBreakpoint('md');
  const [searchParams, setSearchParams] = useSearchParams();
  const snackbar = useCustomSnackbar();
  const referencesLoader = useReferencesLoader();
  const collectionDates = useSelector(StepSelectors.getCollectionDates);

  const { networks } = references;
  const filteredNetworks = useSelector(StepSelectors.getFilteredNetworks); // NOTE: Filtered by number of parcels and total weight
  const selectedNetwork = useSelector(StepSelectors.getSelectedNetwork);
  const parcelsInvalid = useSelector(StepSelectors.validateParcelsValues);
  const shipToShopAvailable = useSelector(StepSelectors.getShipToShopAvailable);

  const dispatch = useDispatch();
  const [maxDimsModalOpen, setMaxDimsModalOpen] = useState(false);

  const dataForGetNetworks = useSelector(
    StepSelectors.getOutboundNetworkParameters
  );

  useEffect(() => {
    setInterfaceId(PARCEL_DETAILS.INTERFACE_ID);
    overlay.show();
    (async () =>
      Promise.all([
        referencesLoader.loadDropOffCollection(),
        referencesLoader.loadDeliveryPickup(),
      ]))().finally(() => overlay.hide());
  }, []);

  const onSubmit = useCallback(async formValues => {
    try {
      overlay.show();
      const totalWeight = getParcelsTotalWeight(
        formValues.outboundConsignment.parcels
      );
      const numberOfParcels = getParcelTotalQuantity(
        formValues.outboundConsignment.parcels
      );

      let newShipment = {
        ...formValues,
        outboundConsignment: {
          ...formValues?.outboundConsignment,
          totalWeight,
          numberOfParcels,
        },
      };
      const basketItemId = searchParams.get('basketItemId');
      const updatedBasketItem = {
        basketItemId: basketItemId,
        shipment: newShipment,
      };

      if (basketItemId) {
        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();
      } else {
        const { basketItemId } = await dispatch(
          BasketActions.createBasketItem(newShipment)
        ).unwrap();

        setSearchParams({ ...searchParams, basketItemId });
      }

      nextStep(newShipment);
    } catch (e) {
      snackbar.showError({
        message: STRINGS.FAILED_TO_SAVE_BASKET,
      });
    } finally {
      overlay.hide();
    }
  }, []);

  const handleBack = useCallback(() => {
    navigate('/');
  }, [navigate]);

  // PRICING LOGIC
  const dropAtShop = get(values, SHIPMENT_FIELDS.DROP_AT_SHOP.KEY);
  const collectionPostcode = get(
    values,
    'outboundConsignment.collectionDetails.address.postcode'
  );
  const deliveryPostcode = get(
    values,
    'outboundConsignment.deliveryDetails.address.postcode'
  );
  const collectionCountryCode = get(
    values,
    'outboundConsignment.collectionDetails.address.countryCode'
  );

  useEffect(() => {
    const { error } = getNetworksSchema.validate(dataForGetNetworks, {
      presence: 'required',
    });

    if (!error) {
      dispatch(networksActions.fetchNetworks(dataForGetNetworks))
        .unwrap()
        .then(networks => {
          setReferences('networks', networks);
        });
    }
  }, [dataForGetNetworks, collectionPostcode, deliveryPostcode]);

  const collectFrom = get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY);
  const senderAddressDisabled = !get(
    values,
    SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
  );
  const parcelSizeDisabled =
    senderAddressDisabled ||
    !get(values, SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY) ||
    !networks?.length;
  const parcelCollectionAvailable = networks.some(
    ({ networkKey }) => networkKey !== '2^91'
  );
  const parcelDestinationDisabled =
    !networks?.length || parcelSizeDisabled || parcelsInvalid;
  const parcelCollectionDisabled =
    parcelDestinationDisabled || !get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY);
  const networkDisabled = parcelDestinationDisabled || !collectFrom;
  const insuranceVisible = selectedNetwork && selectedNetwork.insurance;
  const activeCollectionPickupPoint =
    references[REFERENCE_NAME.COLLECTION_PICKUP];
  const activeDestinationPickupPoint =
    references[REFERENCE_NAME.DELIVERY_PICKUP];
  // review
  const context = useSelector(StepSelectors.getMaxWeightAndNumberOfParcels);
  const requiredKeys = useSelector(
    StepSelectors.getParcelDetailsRequiredFields
  );
  const collectionDateAvailable = collectFrom === POINT_TYPE.DOOR;

  // TODO: Logic needs to be verified
  const minPrice = getMinPriceFromNetworks(
    filteredNetworks,
    get(values, SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY)
  );

  const minShopPrice = getMinShopPriceFromNetworks(filteredNetworks);
  const minDoorPrice = getMinDoorPriceFromNetworks(filteredNetworks);

  const loadCollectionDates = useCallback(async () => {
    const data = await dispatch(
      fetchCollectionDates({
        countryCode: collectionCountryCode,
        postcode: collectionPostcode,
      })
    ).unwrap();

    setReferences(REFERENCE_NAME.COLLECTION_DATES, data);
  }, [dispatch, collectionPostcode, collectionCountryCode]);

  return (
    <Trackable loadId={PARCEL_DETAILS.LOAD}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <WizardForm
          onSubmit={onSubmit}
          validate={values =>
            validateWithJoi(
              values,
              requireKeysSchema(
                ShipmentValidator.shipmentSchema(),
                requiredKeys
              ),
              {
                context,
              }
            )
          }
        >
          {({ handleSubmit, values, invalid, form }) => (
            <>
              <Grid item xs={12} md={8}>
                <form id='parcelDetailsForm' onSubmit={handleSubmit}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant='h3' sx={{ mb: 2 }}>
                      {WHERE_YOUR_PARCEL_GOES}
                    </Typography>

                    <AddressDetailsForm
                      values={
                        values?.outboundConsignment?.deliveryDetails.address ||
                        {}
                      }
                      config={{
                        fields: {
                          country: {
                            label: SHIPMENT_FIELDS.DESTINATION_COUNTRY.LABEL,
                          },
                          organisation: {
                            label:
                              SHIPMENT_FIELDS.DESTINATION_ORGANISATION.LABEL,
                          },
                          street: {
                            label: SHIPMENT_FIELDS.DESTINATION_STREET.LABEL,
                          },
                          locality: {
                            label: SHIPMENT_FIELDS.DESTINATION_LOCALITY.LABEL,
                          },
                          town: {
                            label: SHIPMENT_FIELDS.DESTINATION_CITY.LABEL,
                          },
                          county: {
                            label: SHIPMENT_FIELDS.DESTINATION_COUNTY.LABEL,
                          },
                        },
                        hasActionButtons: true,
                      }}
                      onChange={({ fullAddress, ...addressFormValues }) => {
                        setReferences(
                          REFERENCE_NAME.DELIVERY_ADDRESS,
                          fullAddress
                        );
                        form.change(DESTINATION_ADDRESS_KEY, addressFormValues);
                      }}
                      schema={ShipmentValidator.addressDetailsSchema}
                    />
                  </Paper>
                  <OnChange name={DESTINATION_ADDRESS_KEY}>
                    {() => {
                      form.change(
                        SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
                        undefined
                      );
                      setReferences(REFERENCE_NAME.DELIVERY_PICKUP, null);
                    }}
                  </OnChange>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      opacity: senderAddressDisabled ? 0.4 : 1,
                    }}
                  >
                    <Typography variant='h3' sx={{ mb: 2 }}>
                      {WHERE_YOUR_PARCEL_FROM}
                    </Typography>
                    <AddressDetailsForm
                      values={{
                        ...values?.outboundConsignment?.collectionDetails
                          ?.address,
                        // Only GB should be available in Send From countries
                        countryCode: 'GB',
                      }}
                      config={{
                        fields: {
                          country: {
                            label: SHIPMENT_FIELDS.COLLECTION_COUNTRY.LABEL,
                            // Disable change to make only GB set
                            disabled: true,
                          },
                          organisation: {
                            label:
                              SHIPMENT_FIELDS.COLLECTION_ORGANISATION.LABEL,
                          },
                          street: {
                            label: SHIPMENT_FIELDS.COLLECTION_STREET.LABEL,
                          },
                          locality: {
                            label: SHIPMENT_FIELDS.COLLECTION_LOCALITY.LABEL,
                          },
                          town: {
                            label: SHIPMENT_FIELDS.COLLECTION_CITY.LABEL,
                          },
                          county: {
                            label: SHIPMENT_FIELDS.COLLECTION_COUNTY.LABEL,
                          },
                        },
                        hasActionButtons: true,
                      }}
                      onChange={({
                        fullAddress,
                        addressKey,
                        coordinates,
                        ...addressFormValues
                      }) => {
                        setReferences(
                          REFERENCE_NAME.COLLECTION_ADDRESS,
                          fullAddress
                        );
                        setReferences(REFERENCE_NAME.COLLECTION_PICKUP, null);
                        form.change(COLLECTION_ADDRESS_KEY, {
                          addressId: addressKey,
                          ...addressFormValues,
                        });
                      }}
                      disabled={senderAddressDisabled}
                      schema={ShipmentValidator.addressDetailsSchema}
                    />
                  </Paper>
                  <OnChange name={COLLECTION_ADDRESS_KEY}>
                    {() => {
                      // check
                      form.change(
                        SHIPMENT_FIELDS.DROP_OFF_DETAILS.KEY,
                        undefined
                      );
                      setReferences(REFERENCE_NAME.COLLECTION_PICKUP, null);
                    }}
                  </OnChange>
                  {/* parcels section */}
                  {values?.outboundConsignment?.parcels.map((parcel, index) => (
                    <Paper
                      key={index}
                      sx={{
                        p: 2,
                        mb: 2,
                        opacity: parcelSizeDisabled ? 0.4 : 1,
                      }}
                    >
                      <Typography variant='h3'>
                        {HOW_BIG_IS_YOUR_PARCEL}
                      </Typography>
                      <Grid
                        container
                        sx={{
                          my: { xs: 1, md: 2 },
                          mt: { md: 3 },
                          alignItems: 'center',
                        }}
                      >
                        <Grid item xs={8}>
                          <Typography variant='subtitle2'>{WEIGHT}</Typography>
                          <Typography variant='caption'>
                            {MAX_PARCEL_WEIGHT_$(context.maxWeight || 30)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          md={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <TextFieldRFF
                            name={SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(index)}
                            label={SHIPMENT_FIELDS.PARCEL_WEIGHT.LABEL}
                            disabled={parcelSizeDisabled}
                            required
                            inputProps={{
                              inputmode: 'numeric',
                              pattern: '[0-9]*',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'center',
                        }}
                      >
                        <Grid item xs={8}>
                          <Typography variant='subtitle2'>{HEIGHT}</Typography>
                          <Typography variant='caption'>
                            {MAX_PARCEL_HEIGHT_$(100)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <TextFieldRFF
                            name={SHIPMENT_FIELDS.PARCEL_HEIGHT.KEY(index)}
                            label={SHIPMENT_FIELDS.PARCEL_HEIGHT.LABEL}
                            disabled={parcelSizeDisabled}
                            required
                            inputProps={{
                              inputmode: 'numeric',
                              pattern: '[0-9]*',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'center',
                        }}
                      >
                        <Grid item xs={8}>
                          <Typography variant='subtitle2'>{WIDTH}</Typography>
                          <Typography variant='caption'>
                            {MAX_PARCEL_WIDTH_$(100)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <TextFieldRFF
                            name={SHIPMENT_FIELDS.PARCEL_WIDTH.KEY(index)}
                            label={SHIPMENT_FIELDS.PARCEL_WIDTH.LABEL}
                            disabled={parcelSizeDisabled}
                            required
                            inputProps={{
                              inputmode: 'numeric',
                              pattern: '[0-9]*',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'center',
                        }}
                      >
                        <Grid item xs={8}>
                          <Typography variant='subtitle2'>{LENGTH}</Typography>
                          <Typography variant='caption'>
                            {MAX_PARCEL_LENGTH_$(100)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <TextFieldRFF
                            name={SHIPMENT_FIELDS.PARCEL_LENGTH.KEY(index)}
                            label={SHIPMENT_FIELDS.PARCEL_LENGTH.LABEL}
                            disabled={parcelSizeDisabled}
                            required
                            inputProps={{
                              inputmode: 'numeric',
                              pattern: '[0-9]*',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'center',
                        }}
                      >
                        <Grid item xs={8}>
                          <Typography variant='subtitle2'>
                            {QUANTITY}
                          </Typography>
                          <Typography variant='caption'>
                            {QUANTITY_DESCRIPTION}
                          </Typography>
                          <Typography variant='caption'>
                            {MAX_QUANTITY_$(context.maxParcelNumber || 99)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <TextFieldRFF
                            name={SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(index)}
                            label={SHIPMENT_FIELDS.PARCEL_QUANTITY.LABEL}
                            disabled={parcelSizeDisabled}
                            required
                            inputProps={{
                              inputmode: 'numeric',
                              pattern: '[0-9]*',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ my: 1 }}>
                        <Grid item>
                          <Button
                            sx={{ px: 0 }}
                            variant='text'
                            onClick={() => setMaxDimsModalOpen(true)}
                            disabled={parcelSizeDisabled}
                          >
                            {VIEW_MAX_PARCEL_SIZES}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid sx={{ my: 1.5 }}>
                        <Grid item>
                          <Typography variant='body1'>
                            {PARCEL_DESCRIPTION}
                          </Typography>
                        </Grid>
                      </Grid>
                      <TextFieldRFF
                        sx={{ mb: 2 }}
                        name={SHIPMENT_FIELDS.PARCEL_DESCRIPTION.KEY(index)}
                        size='small'
                        label={SHIPMENT_FIELDS.PARCEL_DESCRIPTION.LABEL}
                        fullWidth
                        disabled={parcelSizeDisabled}
                        fieldProps={{
                          formatOnBlur: true,
                          format: trim,
                        }}
                      />
                      <Grid
                        container
                        sx={{ mt: 1, justifyContent: 'flex-end' }}
                      >
                        {values?.outboundConsignment?.parcels?.length > 1 && (
                          <Button
                            variant='outlined'
                            onClick={() => {
                              const newParcels = cloneDeep(
                                values?.outboundConsignment?.parcels
                              );
                              remove(
                                newParcels,
                                item => item.parcelId === parcel.parcelId
                              );
                              form.change(
                                SHIPMENT_FIELDS.PARCELS.KEY,
                                newParcels
                              );
                            }}
                            disabled={parcelSizeDisabled}
                            startIcon={<Delete />}
                          >
                            {DELETE_PARCEL}
                          </Button>
                        )}
                        {index ===
                          values?.outboundConsignment?.parcels?.length - 1 && (
                          <Button
                            variant='outlined'
                            onClick={() => {
                              const newParcels = cloneDeep(
                                values?.outboundConsignment?.parcels
                              );
                              newParcels.push({
                                parcelId: crypto.randomUUID(),
                              });
                              form.change(
                                SHIPMENT_FIELDS.PARCELS.KEY,
                                newParcels
                              );
                            }}
                            disabled={parcelSizeDisabled}
                            startIcon={<Add />}
                            sx={{ ml: 1 }}
                          >
                            {ADD_ANOTHER_PARCEL}
                          </Button>
                        )}
                      </Grid>
                    </Paper>
                  ))}
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      opacity: parcelDestinationDisabled ? 0.4 : 1,
                    }}
                  >
                    <Typography variant='h3'>
                      {WHERE_DELIVER_PARCEL_TO}
                    </Typography>
                    <Field name={SHIPMENT_FIELDS.DELIVER_TO.KEY}>
                      {({ input }) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 2, alignItems: 'stretch' }}
                        >
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === POINT_TYPE.SHOP}
                              highlightChip={MOST_POPULAR}
                              title={DIRECT_TO_PICKUP}
                              bodyText={DIRECT_TO_PICKUP_DESCRIPTION}
                              price='Free'
                              cardOnclick={() => {
                                input.onChange(POINT_TYPE.SHOP);
                              }}
                              disabled={
                                parcelDestinationDisabled ||
                                !shipToShopAvailable
                              }
                            />
                            <PickupModal
                              label={VIEW_NEARBY_PICKUPS}
                              id='view-collection-pickups'
                              variant='text'
                              disabled={
                                input.value === POINT_TYPE.DOOR ||
                                parcelDestinationDisabled ||
                                !shipToShopAvailable
                              }
                              selectedPickupPoint={activeDestinationPickupPoint}
                              onChange={newPickupPoint => {
                                setReferences(
                                  REFERENCE_NAME.DELIVERY_PICKUP,
                                  newPickupPoint
                                );
                                form.change(
                                  SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
                                  PickupHelper.convertPickupToFormValues(
                                    newPickupPoint
                                  )
                                );
                              }}
                              initialPostcodeValue={get(
                                values,
                                SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
                              )}
                            />
                          </Grid>
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === POINT_TYPE.DOOR}
                              title={DELIVER_TO_RECIPIENT}
                              bodyText={DELIVER_TO_RECIPIENT_DESCRIPTION}
                              price='Free' // NOTE: Price is changing based on previous step, verify logic
                              cardOnclick={() => {
                                input.onChange(POINT_TYPE.DOOR);
                                form.change(
                                  SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
                                  undefined
                                );
                                setReferences(
                                  REFERENCE_NAME.DELIVERY_PICKUP,
                                  null
                                );
                              }}
                              disabled={parcelDestinationDisabled}
                            />
                          </Grid>
                          {activeDestinationPickupPoint && (
                            <Grid container item md={12} xs={12}>
                              <PickupPointWidget
                                pickupPoint={activeDestinationPickupPoint}
                                pickupPointLabel={CHOSEN_PICKUP_POINT}
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Field>
                    <OnChange name={SHIPMENT_FIELDS.DELIVER_TO.KEY}>
                      {value => {
                        const dropAtShop =
                          value === POINT_TYPE.SHOP &&
                          get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY) ===
                            POINT_TYPE.SHOP;
                        form.change(
                          SHIPMENT_FIELDS.DROP_AT_SHOP.KEY,
                          dropAtShop ? true : undefined
                        );
                      }}
                    </OnChange>
                  </Paper>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      opacity: parcelCollectionDisabled ? 0.4 : 1,
                    }}
                  >
                    <Typography variant='h3'>
                      {WOULD_YOU_LIKE_COLLECTION}
                    </Typography>
                    <Field name={SHIPMENT_FIELDS.COLLECT_FROM.KEY}>
                      {({ input }) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 2, alignItems: 'stretch' }}
                        >
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === POINT_TYPE.DOOR}
                              title={YES_COLLECT}
                              bodyText={YES_COLLECT_DESCRIPTION}
                              price={`+${(minDoorPrice - minShopPrice).toFixed(
                                2
                              )}`}
                              printerRequired
                              cardOnclick={() => {
                                input.onChange(POINT_TYPE.DOOR);
                                form.change(
                                  SHIPMENT_FIELDS.DROP_OFF_DETAILS.KEY,
                                  undefined
                                );
                                setReferences(
                                  REFERENCE_NAME.COLLECTION_PICKUP,
                                  null
                                );
                              }}
                              disabled={
                                parcelCollectionDisabled ||
                                !parcelCollectionAvailable
                              }
                            />
                          </Grid>
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === POINT_TYPE.SHOP}
                              highlightChip={MOST_POPULAR}
                              title={NO_DROP_OFF}
                              bodyText={NO_DROP_OFF_DESCRIPTION}
                              price='Free'
                              cardOnclick={() => {
                                input.onChange(POINT_TYPE.SHOP);
                              }}
                              disabled={parcelCollectionDisabled}
                            />
                            <PickupModal
                              label={VIEW_NEARBY_PICKUPS}
                              id='view-collection-pickups'
                              variant='text'
                              disabled={
                                parcelCollectionDisabled ||
                                input.value === POINT_TYPE.DOOR
                              }
                              selectedPickupPoint={activeCollectionPickupPoint}
                              onChange={newPickupPoint => {
                                setReferences(
                                  REFERENCE_NAME.COLLECTION_PICKUP,
                                  newPickupPoint
                                );

                                form.change(
                                  SHIPMENT_FIELDS.DROP_OFF_DETAILS.KEY,
                                  PickupHelper.convertPickupToFormValues(
                                    newPickupPoint
                                  )
                                );
                              }}
                              initialPostcodeValue={get(
                                values,
                                SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY
                              )}
                            />
                          </Grid>
                          {activeCollectionPickupPoint && (
                            <Grid container item md={12} xs={12}>
                              <PickupPointWidget
                                pickupPoint={activeCollectionPickupPoint}
                                pickupPointLabel={CHOSEN_PICKUP_POINT}
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Field>
                    <OnChange name={SHIPMENT_FIELDS.COLLECT_FROM.KEY}>
                      {value => {
                        const dropAtShop =
                          value === POINT_TYPE.SHOP &&
                          get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY) ===
                            POINT_TYPE.SHOP;
                        form.change(
                          SHIPMENT_FIELDS.DROP_AT_SHOP.KEY,
                          dropAtShop ? true : undefined
                        );

                        if (value === POINT_TYPE.DOOR) {
                          loadCollectionDates();
                        }
                      }}
                    </OnChange>
                  </Paper>
                  {collectionDateAvailable && !!collectionDates.length && (
                    <Paper
                      sx={{
                        p: 2,
                        mb: 2,
                        opacity: parcelCollectionDisabled ? 0.4 : 1,
                      }}
                    >
                      <Typography variant='h3'>
                        {COLLECTION_DATE_HEADER}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <TextFieldRFF
                          name={SHIPMENT_FIELDS.SHIPMENT_DATE.KEY}
                          label={SHIPMENT_FIELDS.SHIPMENT_DATE.LABEL}
                          select
                          sizw='small'
                          // TODO: Update the disabling logic
                          disabled={parcelCollectionDisabled}
                          required
                        >
                          {collectionDates.map((date, index) => (
                            <MenuItem key={index} value={date}>
                              {moment(date, DATE_TIME_FORMAT).format(
                                'dddd Do MMMM YYYY'
                              )}
                            </MenuItem>
                          ))}
                        </TextFieldRFF>
                      </Box>
                    </Paper>
                  )}
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      opacity: networkDisabled ? 0.4 : 1,
                    }}
                  >
                    <Typography variant='h3'>{WHEN_DELIVER}</Typography>
                    {networkDisabled ? (
                      <Box sx={{ mt: 2 }}>
                        <ActionButton
                          title={AVAILABLE_SERVICES}
                          bodyText={WE_ARE_CALCULATING_SERVICES}
                          price='--,--'
                          disabled
                        />
                      </Box>
                    ) : (
                      <Field name={SHIPMENT_FIELDS.NETWORK_CODE.KEY}>
                        {({ input }) => (
                          <Grid
                            container
                            spacing={2}
                            sx={{ my: 2, alignItems: 'stretch' }}
                          >
                            {filteredNetworks.map(network => (
                              <Grid
                                container
                                item
                                md={12 / filteredNetworks.length}
                                xs={12}
                                key={network.networkKey}
                              >
                                <ActionButton
                                  selected={input.value === network.networkKey}
                                  // highlightChip={MOST_POPULAR}
                                  title={network.networkDesc}
                                  // bodyText='Standard delivery within 1 working day'
                                  price={
                                    getAdditionalPriceForNetwork({
                                      network,
                                      dropAtShop,
                                      minDoorPrice,
                                      minShopPrice,
                                    })
                                      ? `+${getAdditionalPriceForNetwork({
                                          network,
                                          dropAtShop,
                                          minDoorPrice,
                                          minShopPrice,
                                        })}`
                                      : 'Free'
                                  }
                                  cardOnclick={() =>
                                    input.onChange(network.networkKey)
                                  }
                                  disabled={networkDisabled}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Field>
                    )}
                  </Paper>
                  {insuranceVisible && (
                    <Paper
                      sx={{
                        p: 2,
                        mb: { md: 2, sx: 0 },
                      }}
                    >
                      <Typography variant='h3'>
                        {WOULD_YOU_LIKE_INSURANCE}
                      </Typography>
                      <Field name={SHIPMENT_FIELDS.LIABILITY_VALUE.KEY}>
                        {({ input }) => (
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2, alignItems: 'stretch' }}
                          >
                            <Grid container item lg={3} md={6} xs={12}>
                              <ActionButton
                                selected={input.value === 50} // TODO: Add proper logic
                                title='Cover up to £50'
                                bodyText='Your delivery is automatically insured up to £50'
                                price='Free'
                                cardOnclick={() => input.onChange(50)}
                              />
                            </Grid>
                            <Grid container item lg={3} md={6} xs={12}>
                              <ActionButton
                                selected={input.value === 100}
                                title='Cover up to £100'
                                bodyText='Your delivery will be insured up to £100'
                                price='+1.00'
                                cardOnclick={() => input.onChange(100)}
                              />
                            </Grid>
                            <Grid container item lg={3} md={6} xs={12}>
                              <ActionButton
                                selected={input.value === 500}
                                title='Cover up to £500'
                                bodyText='Your delivery will be insured up to £500'
                                price='+2.50'
                                cardOnclick={() => input.onChange(500)}
                              />
                            </Grid>
                            <Grid container item lg={3} md={6} xs={12}>
                              <ActionButton
                                selected={input.value === 1000}
                                title='Cover up to £1000'
                                bodyText='Your delivery will be insured up to £1000'
                                price='+5.00'
                                cardOnclick={() => input.onChange(1000)}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Field>
                    </Paper>
                  )}
                </form>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ position: 'sticky', top: theme.spacing(2) }}>
                  <SummaryDetails basicPrice={minPrice} />
                  <Box
                    sx={{
                      gap: 1,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {!isSmallScreen && (
                      <Button
                        actionid={PARCEL_DETAILS.CLICK_BACK}
                        variant='outlined'
                        onClick={handleBack}
                        sx={{ flexGrow: 1 }}
                      >
                        {BACK}
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      form='parcelDetailsForm'
                      type='submit'
                      disabled={invalid}
                      sx={{ flexGrow: 1, marginX: { xs: 3, md: 0 } }}
                    >
                      {NEXT_STEP}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </WizardForm>
      </Grid>
      <MaxParcelDimsModal
        open={maxDimsModalOpen}
        onClose={() => setMaxDimsModalOpen(false)}
      />
    </Trackable>
  );
};

export default ParcelDetails;
