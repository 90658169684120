import { useState } from 'react';
import { get } from 'lodash';
import { Box, Button, Paper, Typography, TextField } from '@mui/material';
import {
  APPLY,
  YOUR_VOUCHER,
  ENTER_VOUCHER,
  VOUCHER_APPLIED,
  INVALID_VOUCHER,
} from '../../constants/strings';
import { voucherSchema, validateWithJoi } from '../../utils/validators';
import { removeSpaces } from '../../utils/formatters';
import { paymentApis } from '../../apis';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useCustomSnackbar } from '../../features/CustomSnackbar';

const formInitialValue = { voucherCode: '' };

const Voucher = ({ voucher, onChange }) => {
  const [formValues, setFormValues] = useState(formInitialValue);
  const [errors, setErrors] = useState({});
  const [responseErrors, setResponseErrors] = useState();
  const voucherCode = formValues?.voucherCode;
  const { showSuccess } = useCustomSnackbar();
  const { errorHandler } = useErrorHandler();

  const isApplyDisabled =
    voucher ||
    !!validateWithJoi(formValues, voucherSchema).voucherCode ||
    !!responseErrors;

  const onApplyVoucher = async () => {
    try {
      const { data } = await paymentApis.getVoucherByCode(voucherCode);
      showSuccess({
        message: VOUCHER_APPLIED,
        variant: 'success',
      });
      setFormValues(formInitialValue);
      onChange(data);
    } catch (error) {
      if (error.response.status === 404) {
        const errorMessage = get(
          error,
          'response.data.error.message',
          INVALID_VOUCHER
        );
        errorHandler(errorMessage, error);
        setResponseErrors(errorMessage);
      }
    }
  };

  const onBlur = () => {
    const newFormValues = {
      ...formValues,
      voucherCode: removeSpaces(voucherCode),
    };

    const validationErrors = validateWithJoi(newFormValues, voucherSchema);

    setFormValues(newFormValues);
    setErrors(validationErrors);
  };

  const onFieldChange = () => {
    const { name, value } = event.target;

    const newFormValues = {
      ...formValues,
      [name]: value,
    };

    setResponseErrors(null);
    setFormValues(newFormValues);
  };

  return (
    <Paper sx={{ p: 2, mb: 2, display: { xs: 'none', md: 'block' } }}>
      <Typography variant='h3' sx={{ width: '100%', marginBottom: '16px' }}>
        {YOUR_VOUCHER}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          height: '40px',
          marginBottom: 2,
        }}
      >
        <TextField
          name='voucherCode'
          placeholder={ENTER_VOUCHER}
          fullWidth={true}
          inputProps={{
            style: {
              padding: '9px 14px',
            },
          }}
          sx={{ marginRight: '16px' }}
          onChange={onFieldChange}
          onBlur={onBlur}
          onFocus={() => setErrors({})}
          error={voucherCode && !!(errors.voucherCode || responseErrors)}
          helperText={voucherCode && (errors.voucherCode || responseErrors)}
          value={voucherCode}
        />
        <Button
          type='submit'
          variant={isApplyDisabled ? 'outlined' : 'contained'}
          disabled={isApplyDisabled}
          onClick={onApplyVoucher}
        >
          {APPLY}
        </Button>
      </Box>
    </Paper>
  );
};

export default Voucher;
