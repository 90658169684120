import * as Sentry from '@sentry/react';

const { VITE_SENTRY_DSN, VITE_SENTRY_RELEASE, VITE_SENTRY_ENV, DEV, PROD } =
  import.meta.env;

Sentry.init({
  debug: DEV,
  enabled: PROD,
  release: VITE_SENTRY_RELEASE,
  dsn: VITE_SENTRY_DSN,
  environment: VITE_SENTRY_ENV,
  tracesSampleRate: 0.0,
  ignoreErrors: [
    // contextmenu error for electron (v.0.37.8)
    "Cannot read property 'nodeType' of undefined",
    'SecurityError',
    // Random plugins/extensions
    'top.GLOBALS',
    "Can't find variable: ga",
    /(ResizeObserver loop limit exceeded)/,
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

export const setSentryUser = user => Sentry.setUser(user);

export const getSentryUser = user => {
  if (user) {
    const { uid, email } = user;

    return {
      id: uid,
      email,
    };
  }

  return {};
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => action,
});

export default Sentry;
