export const PICKUP_POINT_TYPE = {
  SHOP: 'SHP',
  LOCKER: 'LKR',
  ALL: null,
};

export const PICKUP_POINT_TYPE_VALUE = {
  SHOP: 'Shop',
  LOCKER: 'Locker',
  ALL: 'All',
};

export const PICKUP_POINT_AMENITIES_TYPE = {
  LABEL_PRINTING: 'dropOffDpd',
  OPEN_LATE: 'openLate',
  CAR_PARKING: 'parkingAvailable',
  OPEN_SATURDAYS: 'openSaturday',
  OPEN_SUNDAYS: 'openSunday',
  WHEELCHAIR_ACCESS: 'disabledAccess',
};

export const PICKUP_POINT_AMENITIES = {
  LABEL_PRINTING: 'Label Printing',
  OPEN_LATE: 'Open Late',
  CAR_PARKING: 'Parking Available',
  OPEN_SATURDAYS: 'Open Saturdays',
  OPEN_SUNDAYS: 'Open Sundays',
  WHEELCHAIR_ACCESS: 'Wheelchair Access',
};

export const CUSTOM_MARKER =
  'https://consumer.cdn.dpduk.live/dpdonline/pickupMarkerx1.png';
