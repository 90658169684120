import {
  Card,
  Box,
  CardMedia,
  Typography,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { PRODUCT_AND_SERVICES as S } from '../../../constants/strings.js';
import {
  formatMessage,
  replaceLineBreakWithBr,
} from '../../../utils/strings.jsx';

export default function MediaCard({ cardMedia, cardContent, isMobile, sx }) {
  return (
    <Card
      sx={{
        width: isMobile ? 360 : 316,
        minHeight: 450,
        borderRadius: '12px',
        marginBottom: '32px',
        ...sx,
      }}
    >
      <CardMedia
        sx={{
          height: 180,
          position: 'relative',
        }}
        alt={'Card media image'}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${
              isMobile ? cardMedia.mobImg : cardMedia.img
            })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(50%)',
          }}
        />
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
            color: 'white',
            fontWeight: 400,
            fontStyle: 'normal',
          }}
        >
          <Typography
            variant={'body1'}
            sx={{
              textTransform: 'uppercase',
              fontSize: '12px',
              lineHeight: '20px',
              letterSpacing: '1px',
            }}
          >
            {cardMedia.overlineTitle}
          </Typography>
          <Typography
            variant={'body1'}
            sx={{
              fontSize: '24px',
              lineHeight: '32px',
            }}
          >
            {cardMedia.title}
          </Typography>
        </Box>
      </CardMedia>
      <CardContent
        sx={{
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0.17px',
          padding: '16px 20px',
        }}
      >
        <Typography
          variant='body1'
          color='primary.main'
          sx={{ paddingBottom: '8px' }}
        >
          {cardContent.title}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{ paddingBottom: '8px' }}
        >
          {replaceLineBreakWithBr(cardContent.description)}
        </Typography>
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{ lineHeight: 'normal' }}
        >
          <Typography
            component='span'
            sx={{ color: 'primary.main', fontSize: '28px' }}
          >
            {`£${cardContent.priceExcVat} `}
          </Typography>
          {S.MEDIA_CARD.EXC_VAT}
        </Typography>
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            fontSize: '12px',
          }}
        >
          {formatMessage(S.MEDIA_CARD.INC_VAT, cardContent.priceIncVat)}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          padding: '16px 24px',
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant='outlined'
          startIcon={<ShoppingCartIcon />}
          sx={{
            fontSize: '13px',
            lineHeight: '22px',
            letterSpacing: '0.46px',
            borderRadius: '4px',
            padding: '4px 10px',
          }}
        >
          {S.MEDIA_CARD.CHOOSE_THIS_SERVICE}
        </Button>
      </CardActions>
    </Card>
  );
}
