import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  MenuItem,
  Menu,
  IconButton,
  styled,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useAnalytics from '../../utils/useAnalytics';
import { BASKET } from '../../constants/analytics';
import {
  ADD_TO_WALLET,
  CONFIRMATION,
  OS,
  VIEW_NEARBY_PICKUPS,
} from '../../constants/strings';
import PickupModal from '../../components/PickupModal';
import ShareModal from '../../components/ShareModal/ShareModal';
import useBreakpoint from '../../hooks/useBreakpoint';
import GooglePayIcon from '../../assets/images/googlePayIcon.png';
import ApplePayIcon from '../../assets/images/applePayIcon.png';
import { getBarcode } from '../../apis/barcodes';
import Loader from '../../features/Loader/Loader';
import getMobileOS from '../../utils/getMobileOS';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const ActionMenuBtn = ({ actionMenuBtnHandleClick }) => (
  <IconButton
    sx={{ paddingY: 0 }}
    disableRipple
    aria-label='more'
    onClick={actionMenuBtnHandleClick}
  >
    <MoreVertIcon />
  </IconButton>
);

const DropOff = ({ pickupPoint }) => {
  const theme = useTheme();
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);

  const { setInterfaceId, Trackable } = useAnalytics();
  const isSmallScreen = useBreakpoint('sm');

  const os = getMobileOS();

  useEffect(() => {
    setInterfaceId(BASKET.INTERFACE_ID);
  }, []);

  const actionEditBtnHandleClick = (e, row) => {
    setActionMenuAnchorEl(e.currentTarget);
  };
  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
  };

  const fetchBarcode = async () => {
    const shipmentId = 'c05c9479-bc38-49f3-8a6b-d0c8816f7a5b'; // Mocked TEMP
    const data = await getBarcode(shipmentId);
    return data.barcodes;
  };

  return (
    <Trackable loadId={BASKET.LOAD}>
      <Grid sx={{ background: theme.palette.primary.pageBackground, pb: 4 }}>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid
            alignItems='center'
            justifyContent='center'
            spacing={2}
            sx={{ mt: 0 }}
          >
            <Grid item xs={12} md={12}>
              <Grid
                sx={{ mb: 4, mt: 4, px: isSmallScreen ? theme.spacing(1) : 0 }}
                item
              >
                <Typography variant='body1'>
                  {CONFIRMATION.THANK_YOU}
                </Typography>
                <Typography variant='h2' sx={{ mt: 1 }} color=''>
                  {CONFIRMATION.ALL_DONE}
                </Typography>
              </Grid>
              <Grid sx={{ mt: 1 }}>
                <Paper
                  sx={{ p: 0, maxWidth: isSmallScreen ? '100%' : '400px' }}
                >
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='h3'>
                      {CONFIRMATION.PARCEL_PASS}
                    </Typography>
                    <ActionMenuBtn
                      actionMenuBtnHandleClick={actionEditBtnHandleClick}
                    />
                  </Box>
                  <Menu
                    anchorEl={actionMenuAnchorEl}
                    open={!!actionMenuAnchorEl}
                    onClose={actionMenuBtnHandleClose}
                    MenuListProps={{
                      sx: { width: '189px' },
                    }}
                    anchorOrigin={{
                      horizontal: 'left',
                      vertical: 'bottom',
                    }}
                    transformOrigin={{
                      horizontal: 120,
                      vertical: 90,
                    }}
                  >
                    <StyledMenuItem disableRipple theme={theme}>
                      {CONFIRMATION.DOWNLOAD_LABEL_AS_PDF}
                    </StyledMenuItem>
                    <StyledMenuItem disableRipple theme={theme}>
                      {CONFIRMATION.DOWNLOAD_INVOICE}
                    </StyledMenuItem>
                    <StyledMenuItem
                      disableRipple
                      theme={theme}
                      onClick={() => setOpenShareModal(true)}
                    >
                      {CONFIRMATION.SHARE_PARCEL_INFO}
                    </StyledMenuItem>
                  </Menu>
                  <Divider />
                  <Box
                    sx={{
                      p: 2,
                      py: 2,
                      textAlign: 'center',
                      color: theme.palette.primary.iconGray,
                    }}
                  >
                    <Typography variant='body1'>
                      {CONFIRMATION.TITLE}
                    </Typography>
                  </Box>
                  <Loader promiseFn={fetchBarcode}>
                    {barcodes => (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mb: 2,
                        }}
                      >
                        {barcodes && (
                          <img
                            src={`data:image/png;base64, ${barcodes[0].barcodeImage.imageData}`}
                            alt='QR Code'
                            style={{ maxWidth: '100%', height: 'auto' }}
                          />
                        )}
                      </Box>
                    )}
                  </Loader>

                  <Divider />

                  <Box sx={{ pt: 0, display: 'flex', flexDirection: 'column' }}>
                    {pickupPoint?.pickupLocation && !isSmallScreen && (
                      <PickupModal
                        label={VIEW_NEARBY_PICKUPS}
                        id='pickup-modal'
                        variant='contained'
                        disabled={false}
                        selectedPickupPoint={selectedPickupPoint}
                        onChange={point => setSelectedPickupPoint(point)}
                      />
                    )}

                    {isSmallScreen && (
                      <>
                        {os === OS.IOS ? (
                          <Button
                            href='/order'
                            sx={{
                              mx: 1,
                              mb: 2,
                              width: '100%',
                              background: theme.palette.primary.black,
                              color: theme.palette.primary.contrastText,
                            }}
                            variant='outlined'
                          >
                            <img
                              style={{ marginRight: 8 }}
                              src={ApplePayIcon}
                              alt='ApplePayIcon'
                            />
                            {ADD_TO_WALLET.APPLE}
                          </Button>
                        ) : (
                          <Button
                            href='/order'
                            sx={{
                              mx: 1,
                              mb: 2,
                              background: theme.palette.primary.black,
                              color: theme.palette.primary.contrastText,
                            }}
                            variant='outlined'
                          >
                            <img
                              style={{ marginRight: 8 }}
                              src={GooglePayIcon}
                              alt='GooglePayIcon'
                            />{' '}
                            {ADD_TO_WALLET.GOOGLE}
                          </Button>
                        )}
                        <Button
                          href='/order'
                          sx={{ mx: 1, mb: 2 }}
                          variant='contained'
                        >
                          {CONFIRMATION.DOWNLOAD_LABEL_TO_PRINT}
                        </Button>
                      </>
                    )}
                    <Button
                      href='/order'
                      sx={{ mx: 1, mb: 2 }}
                      variant='outlined'
                    >
                      {CONFIRMATION.SEND_ANOTHER_PARCEL}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShareModal
          open={openShareModal}
          onClose={() => setOpenShareModal(false)}
        />
      </Grid>
    </Trackable>
  );
};

export default DropOff;
