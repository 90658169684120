export const TOP_COUNTRIES = [
  'GB',
  'XI',
  'IE',
  'AU',
  'CN',
  'FR',
  'DE',
  'HK',
  'IN',
  'IT',
  'NL',
  'PL',
  'ES',
  'US',
];
