export const SIGN_IN = '/signIn';
export const SIGN_IN_WITH_EMAIL = `${SIGN_IN}/email`;
export const SIGN_UP = '/signUp';
export const CREATE_PROFILE = '/createProfile';
export const PROFILE = '/profile';
export const ACCOUNT = 'account';
export const ADDRESSES = 'addresses';
export const ORDERS = 'orders';
export const ORDER_VIEW = 'orders/:parcelCode';

export const HOME = '/';
export const PICKUP_FINDER = '/pickup-finder';
export const PRODUCT_AND_SERVICES = '/product-and-services';
export const RETURN = '/return';
export const TRACK = '/track';
export const ORDER = '/order';
export const QUOTES = '/quotes';
