import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import * as M from '../../../constants/strings';

const PickupInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(1),
  gap: theme.spacing(0),
  justifyContent: 'space-between',
  width: '100%',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  width: '100%',
}));

const Type = styled(Typography)(({ theme, card }) => ({
  color: card ? theme.palette.primary.black : theme.palette.primary.gray,
}));

const IsOpen = styled(Typography)(({ theme, isOpen }) => ({
  color: isOpen ? theme.palette.primary.success : theme.palette.primary.main,
}));

const PickupInfo = ({
  type,
  distance,
  isOpen,
  showIsOpen = false,
  openStatus,
  card = false,
}) => (
  <PickupInfoBox id='PickupInfoBox'>
    <StyledBox>
      <Type card={card} variant='body2'>
        {type}
      </Type>
      {distance && (
        <Typography variant='body2' sx={{ mr: 2 }}>{`${+distance}${
          M.MILES
        }`}</Typography>
      )}
      {showIsOpen && (
        <IsOpen variant='body2' isOpen={isOpen} component='span'>
          {isOpen ? M.OPEN : M.CLOSED}
        </IsOpen>
      )}
      <Typography variant='body2'>{openStatus}</Typography>
    </StyledBox>
  </PickupInfoBox>
);

PickupInfo.propTypes = {
  type: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  openStatus: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  showIsOpen: PropTypes.bool,
  card: PropTypes.bool,
};

export default PickupInfo;
