import Async from 'react-async';
import { Box, Typography } from '@mui/material';
import Pending from './Pending';

const DefaultErrorComponent = error => (
  <Box>
    <Typography>{error.message}</Typography>
  </Box>
);

const Loader = ({
  children,
  promiseFn,
  errorComponent = DefaultErrorComponent,
  pendingProps,
  ...args
}) => (
  <Async promiseFn={promiseFn} {...args}>
    <Async.Pending>
      <Pending {...pendingProps} />
    </Async.Pending>
    <Async.Fulfilled>{children}</Async.Fulfilled>
    <Async.Rejected>{errorComponent}</Async.Rejected>
  </Async>
);

Loader.propTypes = Async.propTypes;

export default Loader;
