import { useState, useCallback, useEffect, useRef } from 'react';
import { Box, TextField, Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import _ from 'lodash';

import { countriesSelectors } from '../../redux/countriesSlice';
import AddressLookup from '../AddressLookup';
import { transformAddressValues } from '../../utils/address';
import { FIELDS } from '../../constants/forms';
import {
  ADDRESS_LOOKUP,
  CHOOSE_FROM_ADDRESS_BOOK,
} from '../../constants/strings';
import { validateWithJoi } from '../../utils/validators';
import CountriesSelect from '../CountriesSelect';
import AddressBookModal from '../AddressBookModal';
import addressBooksSlice from '../../redux/addressBooksSlice';
import { useAuth } from '../../features/Auth';
import { fastFind } from '../../apis/address';
import useErrorHandler from '../../hooks/useErrorHandler';

const AddressDetailsForm = ({
  values,
  onChange,
  sx,
  disabled,
  schema,
  config: { fields, inputSize = 'medium', hasActionButtons = false },
}) => {
  const initialValuesRef = useRef(values);
  const [countryISO, setCountryISO] = useState(values?.countryCode);
  const [addressLookupKey, setAddressLookupKey] = useState(
    _.get(values, FIELDS.ADDRESS_ID.KEY)
  );
  const [fullAddress, setFullAddress] = useState(null);
  const [formValues, setFormValues] = useState(values);
  const [addressBookModal, setAddressBookModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isAddressFromAddressBook, setIsAddressFromAddressBook] =
    useState(false);
  const countries = useSelector(countriesSelectors.getCountries);
  const handleOpenAddressBook = () => setAddressBookModal(true);
  const handleCloseAddressBook = () => setAddressBookModal(false);
  const { currentSession } = useAuth();
  const { errorHandler } = useErrorHandler();

  const addressBooksLength = useSelector(
    addressBooksSlice.selectors.getAddressBookLength
  );

  const isAddressBooksButtonAvailable = !!(
    hasActionButtons &&
    currentSession &&
    addressBooksLength
  );

  const debouncedOnChange = useCallback(
    _.debounce((newValues, isFormChanged) => {
      const validationErrors = validateWithJoi(newValues, schema);
      setErrors(validationErrors);
      onChange(newValues, isFormChanged);
    }, 300),
    [formValues]
  );

  const onFieldChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    const newFormValues = {
      ...formValues,
      [name]: value,
    };
    setFormValues(newFormValues);
    const isFormChanged = !_.isEqual(initialValuesRef.current, newFormValues);
    debouncedOnChange({ ...newFormValues, fullAddress }, isFormChanged);
  };

  const shouldAutoFill = fullAddress => {
    if (fullAddress.isOptionSelectedManually) {
      return true;
    }

    if (
      values?.postcode !== fullAddress.Postcode &&
      !isAddressFromAddressBook
    ) {
      return true;
    }

    return false;
  };

  const onAddressLookupChange = fullAddress => {
    if (!shouldAutoFill(fullAddress)) {
      return;
    }

    setIsAddressFromAddressBook(false);
    setFullAddress(fullAddress);
    setAddressLookupKey(fullAddress?.Key);

    const { addressLine1, addressLine2 } = transformAddressValues({
      street: fullAddress.Street,
      locality: fullAddress.Locality,
      property: fullAddress.Property,
    });

    const newFormValues = {
      ...formValues,
      countryCode: countryISO,
      [FIELDS.ADDRESS_KEY.KEY]: fullAddress?.Key,
      coordinates: {
        latitude: fullAddress.Latitude || '0',
        longitude: fullAddress.Longitude || '0',
      },
      street: addressLine1 || '',
      locality: addressLine2 || '',
      town: fullAddress.Town || '',
      county: fullAddress.PostalCounty || '',
      postcode: fullAddress.Postcode,
      organisation: fullAddress.Organisation,
    };

    setFormValues(newFormValues);
    debouncedOnChange({ ...newFormValues, fullAddress }, true);
  };

  const findAndUpdateAddressLookupKey = async (
    lookup,
    countryCode = countryISO
  ) => {
    try {
      const results = await fastFind({ lookup, countryISO: countryCode });
      if (results?.Item.length > 0) {
        // Update address lookup key
        setAddressLookupKey(results.Item[0].Key);
      }
    } catch (error) {
      errorHandler(ADDRESS_LOOKUP.LOOKUP_ERROR, error);
    }
  };

  const onSelectFromAddressBook = selectedAddress => {
    const {
      countryCode,
      addressLine1,
      addressLine2,
      addressLine3: town,
      addressLine4: county,
      postcode,
      contactName: organisation,
    } = selectedAddress;

    const newFormValues = {
      ...formValues,
      countryCode,
      street: addressLine1,
      locality: addressLine2,
      town,
      county,
      postcode,
      organisation,
      coordinates: {
        latitude: selectedAddress.latitude || '0',
        longitude: selectedAddress.longitude || '0',
      },
    };

    if (countryCode && countryCode !== countryISO) {
      setCountryISO(countryCode);
    }

    findAndUpdateAddressLookupKey(postcode, countryCode);
    setIsAddressFromAddressBook(true);
    setFormValues(newFormValues);
    debouncedOnChange(newFormValues, true);
    handleCloseAddressBook();
  };

  useEffect(() => {
    !addressLookupKey &&
      values?.postcode &&
      findAndUpdateAddressLookupKey(values?.postcode);
  }, []);

  // Remove country select field since it have a special logic
  const regularFields = Object.keys(fields).filter(
    field => field !== 'country'
  );

  return (
    <Box
      noValidate
      autoComplete='off'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        ...sx,
      }}
    >
      <CountriesSelect
        countries={countries}
        blurOnSelect
        select
        label={fields.country.label}
        variant='outlined'
        required
        disabled={!countries.length || disabled || fields.country.disabled}
        onChange={event => setCountryISO(event.target.value)}
        value={countryISO}
        size={inputSize}
      />

      <AddressLookup
        onChange={onAddressLookupChange}
        required
        variant='outlined'
        addressKey={addressLookupKey}
        countryISO={countryISO}
        disabled={disabled}
        inputSize={inputSize}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {regularFields.map(field => (
          <TextField
            key={field}
            label={fields[field].label}
            name={field}
            value={formValues[field] || ''}
            onChange={onFieldChange}
            error={!disabled && !!errors[field]}
            helperText={!disabled && errors[field]}
            disabled={disabled || fields[field].disabled}
            size={inputSize}
          />
        ))}
      </Box>
      {isAddressBooksButtonAvailable ? (
        <>
          <Grid container justifyContent='space-between' sx={{ gap: 2 }}>
            <Button
              id='choose-destination-address-button'
              variant='outlined'
              onClick={handleOpenAddressBook}
              sx={{ alignSelf: 'flex-end' }}
            >
              {CHOOSE_FROM_ADDRESS_BOOK}
            </Button>
          </Grid>
          <AddressBookModal
            open={addressBookModal}
            sx={{ width: '50%', height: '500px' }}
            onClose={handleCloseAddressBook}
            onSelect={onSelectFromAddressBook}
          />
        </>
      ) : null}
    </Box>
  );
};

const PropTypeFieldShape = PropTypes.shape({
  label: PropTypes.string,
  disabled: PropTypes.bool,
});

AddressDetailsForm.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  schema: PropTypes.object.isRequired,
  config: PropTypes.shape({
    fields: PropTypes.shape({
      country: PropTypeFieldShape,
      organisation: PropTypeFieldShape,
      street: PropTypeFieldShape,
      locality: PropTypeFieldShape,
      town: PropTypeFieldShape,
      county: PropTypeFieldShape,
    }),
    inputSize: PropTypes.string,
    hasActionButtons: PropTypes.bool,
  }),
};

export default AddressDetailsForm;
