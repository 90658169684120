import { AUTH_BASE_URL } from '../constants/env';
import apis from './apis';

const baseURL = AUTH_BASE_URL;

export const sendMagicLink = (email, recaptchaToken) =>
  apis.post(
    '/auth/sendMagicLink',
    {
      email,
    },
    {
      headers: {
        'X-Firebase-AppCheck': recaptchaToken,
      },
      baseURL,
    }
  );

export const verifyMagicLink = verificationId =>
  apis.get('/auth/verifyMagicLink', {
    params: {
      verificationId,
    },
    baseURL,
  });

export const getSession = async () => apis.get('/auth/session', { baseURL });

export const login = async token =>
  apis.post(
    '/auth/session/login',
    {
      idToken: token,
    },
    { baseURL }
  );

export const logout = () => apis.post('/auth/session/logout', {}, { baseURL });
