import { SvgIcon } from '@mui/material';

const LabelPack = ({ styles }) => (
  <SvgIcon
    sx={{ width: '35px', height: '35px', ...styles }}
    viewBox='0 0 36 35'
  >
    <g clipPath='url(#clip0_6609_4332)'>
      <path
        d='M28.8091 32.9412H7.19141V2.05882H28.8091V32.9412ZM8.22082 31.9118H27.7796V3.08823H8.22082V31.9118Z'
        fill='black'
        fillOpacity='0.56'
      />
      <path
        d='M25.6709 7.40301L23.9477 8.43654C23.9287 8.44786 23.904 8.45352 23.8787 8.45352C23.8509 8.45352 23.8247 8.44683 23.8046 8.43397L23.7511 8.40102L23.5756 8.29242C23.5364 8.26874 23.5061 8.21367 23.5061 8.16786V7.98669V7.8961C23.5061 7.85029 23.5375 7.78492 23.5776 7.76227L23.6126 7.74117L25.375 6.68448L22.7582 5.23404C22.697 5.20007 22.5966 5.20007 22.5359 5.23352L19.8373 6.69838L22.9662 8.48904C23.0068 8.51117 23.0377 8.56419 23.0377 8.61154V8.62338V8.79426V9.19058L23.0367 11.1948C23.0367 11.2417 23.0048 11.2952 22.9636 11.3173L22.7278 11.4481C22.7268 11.4486 22.7253 11.4486 22.7242 11.4491C22.7052 11.4584 22.682 11.4646 22.6573 11.4646H22.6527C22.6244 11.4651 22.5981 11.4594 22.5776 11.4486L22.4288 11.3662L22.3408 11.3173C22.3006 11.2957 22.2687 11.2422 22.2687 11.1954L22.2682 9.1911L22.2677 9.0197C22.2656 8.99551 22.2476 8.96566 22.2281 8.95485L19.5938 7.43132L19.5928 10.4104C19.5928 10.4804 19.6422 10.5664 19.7024 10.6019L22.5415 12.2649C22.6017 12.3004 22.7006 12.2999 22.7608 12.2644L25.5623 10.5998C25.6226 10.5643 25.6714 10.4779 25.6714 10.4079L25.6709 7.40301Z'
        fill='#DC0032'
      />
      <path
        d='M18.016 18.5294H9.77398C9.48987 18.5294 9.25928 18.2993 9.25928 18.0147C9.25928 17.7306 9.48987 17.5 9.77398 17.5H18.0165C18.3006 17.5 18.5312 17.7306 18.5312 18.0147C18.5307 18.2993 18.3001 18.5294 18.016 18.5294Z'
        fill='#414042'
      />
      <path
        d='M25.1715 16.4706H9.77252C9.4884 16.4706 9.25781 16.24 9.25781 15.9559C9.25781 15.6718 9.4884 15.4412 9.77252 15.4412H25.171C25.4556 15.4412 25.6857 15.6718 25.6857 15.9559C25.6857 16.24 25.4556 16.4706 25.1715 16.4706Z'
        fill='#414042'
      />
      <path
        d='M15.9412 6.17648H9.76471C9.48059 6.17648 9.25 5.94589 9.25 5.66177C9.25 5.37765 9.48059 5.14706 9.76471 5.14706H15.9412C16.2253 5.14706 16.4559 5.37765 16.4559 5.66177C16.4559 5.94589 16.2253 6.17648 15.9412 6.17648Z'
        fill='#414042'
      />
      <path
        d='M15.9412 10.2941H9.76471C9.48059 10.2941 9.25 10.0635 9.25 9.77942C9.25 9.4953 9.48059 9.26471 9.76471 9.26471H15.9412C16.2253 9.26471 16.4559 9.4953 16.4559 9.77942C16.4559 10.0635 16.2253 10.2941 15.9412 10.2941Z'
        fill='#414042'
      />
      <path
        d='M14.9118 8.2353H9.76471C9.48059 8.2353 9.25 8.00471 9.25 7.72059C9.25 7.43648 9.48059 7.20589 9.76471 7.20589H14.9118C15.1959 7.20589 15.4265 7.43648 15.4265 7.72059C15.4265 8.00471 15.1959 8.2353 14.9118 8.2353Z'
        fill='#414042'
      />
      <path
        d='M24.1412 28.8235C23.8565 28.8235 23.6265 28.5935 23.6265 28.3088V23.1618C23.6265 22.8771 23.8565 22.6471 24.1412 22.6471C24.4258 22.6471 24.6559 22.8771 24.6559 23.1618V28.3088C24.6559 28.5935 24.4253 28.8235 24.1412 28.8235Z'
        fill='#414042'
      />
      <path
        d='M22.1177 28.8235C21.8331 28.8235 21.603 28.5935 21.603 28.3088V23.1618C21.603 22.8771 21.8331 22.6471 22.1177 22.6471C22.4024 22.6471 22.6324 22.8771 22.6324 23.1618V28.3088C22.6324 28.5935 22.4024 28.8235 22.1177 28.8235Z'
        fill='#414042'
      />
      <path
        d='M20.0882 28.8235C19.8035 28.8235 19.5735 28.5935 19.5735 28.3088V23.1618C19.5735 22.8771 19.8035 22.6471 20.0882 22.6471C20.3729 22.6471 20.6029 22.8771 20.6029 23.1618V28.3088C20.6029 28.5935 20.3729 28.8235 20.0882 28.8235Z'
        fill='#414042'
      />
      <path
        d='M18.0588 28.8235C17.7741 28.8235 17.5441 28.5935 17.5441 28.3088V23.1618C17.5441 22.8771 17.7741 22.6471 18.0588 22.6471C18.3434 22.6471 18.5735 22.8771 18.5735 23.1618V28.3088C18.5735 28.5935 18.3434 28.8235 18.0588 28.8235Z'
        fill='#414042'
      />
      <path
        d='M16.0294 28.8235C15.7447 28.8235 15.5147 28.5935 15.5147 28.3088V23.1618C15.5147 22.8771 15.7447 22.6471 16.0294 22.6471C16.3141 22.6471 16.5441 22.8771 16.5441 23.1618V28.3088C16.5441 28.5935 16.3141 28.8235 16.0294 28.8235Z'
        fill='#414042'
      />
      <path
        d='M14 28.8235C13.7153 28.8235 13.4853 28.5935 13.4853 28.3088V23.1618C13.4853 22.8771 13.7153 22.6471 14 22.6471C14.2847 22.6471 14.5147 22.8771 14.5147 23.1618V28.3088C14.5147 28.5935 14.2847 28.8235 14 28.8235Z'
        fill='#414042'
      />
      <path
        d='M25.6714 23.5588H22.7816C22.4974 23.5588 22.2676 23.3287 22.2676 23.0441C22.2676 22.7594 22.4974 22.5294 22.7816 22.5294H25.6714C25.9556 22.5294 26.1853 22.7594 26.1853 23.0441C26.1853 23.3287 25.9556 23.5588 25.6714 23.5588Z'
        fill='#414042'
      />
      <path
        d='M25.6714 27.2059H22.7816C22.4974 27.2059 22.2676 26.9758 22.2676 26.6912C22.2676 26.4065 22.4974 26.1765 22.7816 26.1765H25.6714C25.9556 26.1765 26.1853 26.4065 26.1853 26.6912C26.1853 26.9758 25.9556 27.2059 25.6714 27.2059Z'
        fill='#414042'
      />
    </g>
    <defs>
      <clipPath id='clip0_6609_4332'>
        <rect
          width='34.8'
          height='34.8'
          fill='white'
          transform='translate(0.599609)'
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default LabelPack;
