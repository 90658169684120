import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Pagination,
  Divider,
} from '@mui/material';

const SectionLayout = ({
  title,
  actionButtons,
  content,
  children,
  currentPage,
  totalPages = 1,
  onPaginationChange,
}) => (
  <Box sx={{ height: '100%', padding: { md: 2 } }}>
    <Paper sx={{ height: 'inherit' }}>
      <Grid
        container
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'inherit',
        }}
      >
        <Grid item sx={{ maxWidth: '100%' }}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Grid
              container
              sx={{
                padding: '16px 16px 8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>{title}</Typography>
              {actionButtons && (
                <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
                  {actionButtons}
                </Box>
              )}
            </Grid>
            <Divider />
            <Box sx={{ padding: 2, overflowX: 'auto' }}>{content}</Box>
          </Box>
        </Grid>
        <Grid item>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: { md: '8px 16px 16px', xs: '8px 16px 56px' },
            }}
          >
            <Pagination
              page={currentPage}
              count={totalPages || 1}
              siblingCount={0}
              shape='rounded'
              showFirstButton
              showLastButton
              onChange={(_, page) => onPaginationChange(page)}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
    {children}
  </Box>
);

SectionLayout.propTypes = {
  title: PropTypes.string.isRequired,
  actionButtons: PropTypes.element,
  content: PropTypes.element.isRequired,
  children: PropTypes.node,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
};

export default SectionLayout;
