import PropTypes from 'prop-types';
import { Box, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { formatAddressWithPostcode } from '../../../utils/address';

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.black,
  lineHeight: '31px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '180px',
}));

const StyledAddressTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const AddressView = ({
  address = {},
  inline = false,
  titleWidth,
  boxWidth = '100%',
  pickupPoint = {},
}) => {
  const addressParts = [
    `${address.property} ${address.street}`,
    address.town,
    address.county,
    address.postcode,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <Box id='addressView' sx={{ width: boxWidth }}>
      {inline ? (
        <Typography variant='body2'>
          {formatAddressWithPostcode(address)}
        </Typography>
      ) : (
        <>
          <Tooltip
            title={
              pickupPoint.pickupLocation?.shortName ||
              address.organisation ||
              ''
            }
          >
            <StyledTypography sx={{ width: titleWidth }} variant='h6'>
              {pickupPoint.pickupLocation?.shortName || address.organisation}
            </StyledTypography>
          </Tooltip>
          <StyledAddressTypography variant='body2'>
            {addressParts}
          </StyledAddressTypography>
        </>
      )}
    </Box>
  );
};

AddressView.propTypes = {
  inline: PropTypes.bool,
  titleWidth: PropTypes.string,
  address: PropTypes.shape({
    organisation: PropTypes.string,
    property: PropTypes.string,
    street: PropTypes.string,
    town: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
};

export default AddressView;
