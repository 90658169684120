import { SvgIcon } from '@mui/material';

const HomeCollection = ({ styles }) => (
  <SvgIcon
    sx={{ width: '35px', height: '35px', ...styles }}
    viewBox='0 0 36 35'
  >
    <g clipPath='url(#clip0_6722_7008)'>
      <path
        d='M28.8098 17.8459C28.678 17.8459 28.5463 17.7954 28.4459 17.6951L18 7.2486L7.55451 17.6951C7.35326 17.8963 7.02797 17.8963 6.82672 17.6951C6.62547 17.4938 6.62547 17.1685 6.82672 16.9673L18 5.79301L29.1732 16.9673C29.3744 17.1685 29.3744 17.4938 29.1732 17.6951C29.0733 17.7954 28.9416 17.8459 28.8098 17.8459Z'
        fill='black'
        fillOpacity='0.56'
      />
      <path
        d='M27.2657 28.9939H8.73425V18.1835C8.73425 17.8994 8.96484 17.6688 9.24896 17.6688C9.53308 17.6688 9.76366 17.8994 9.76366 18.1835V27.9645H26.2363V18.1835C26.2363 17.8994 26.4664 17.6688 26.751 17.6688C27.0357 17.6688 27.2657 17.8994 27.2657 18.1835V28.9939Z'
        fill='#414042'
      />
      <path
        d='M20.5735 26.9335C20.2889 26.9335 20.0588 26.7035 20.0588 26.4188V20.7571H15.9412V26.4188C15.9412 26.7035 15.7106 26.9335 15.4264 26.9335C15.1423 26.9335 14.9117 26.7035 14.9117 26.4188V19.7276H21.0882V26.4188C21.0882 26.7035 20.8581 26.9335 20.5735 26.9335Z'
        fill='#DC0032'
      />
    </g>
    <defs>
      <clipPath id='clip0_6722_7008'>
        <rect
          width='34.8'
          height='34.8'
          fill='white'
          transform='translate(0.599609)'
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default HomeCollection;
