import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { parcelsApis } from '../apis';

const initialState = {
  status: 'idle',
  data: {},
};

const fetchParcel = createAsyncThunk('parcel/fetch', async parcelCode => {
  const response = await parcelsApis.getParcel(parcelCode);

  return response.data;
});

const slice = createSlice({
  name: 'parcel',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchParcel.fulfilled, (state, { payload }) => {
        state.data[payload.parcelCode] = payload;
        state.status = 'success';
      })
      .addCase(fetchParcel.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchParcel.rejected, state => {
        state.status = 'failure';
      });
  },
});

const getParcelState = state => state.parcel;

const getParcel = parcelCode =>
  createSelector(getParcelState, state => ({
    data: state.data[parcelCode],
    status: state.status,
  }));

export default {
  reducer: slice.reducer,
  actions: {
    fetchParcel,
  },
  selectors: {
    getParcel,
  },
};
