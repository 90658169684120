import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import AddressView from '../MapBox/components/AddressView';
import PickupPointImage from '../../assets/images/pickup_point.png';
import { getPartnerLogo, getPickupAddress } from '../../utils/pickupPoint';

const PickupPointWidget = ({ pickupPoint, pickupPointLabel }) => {
  const theme = useTheme();
  const partnerLogo = getPartnerLogo(pickupPoint);
  const address = getPickupAddress(pickupPoint);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.primary.borderLight}`,
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={partnerLogo || PickupPointImage}
          alt='Pickup Point'
          style={{
            maxWidth: '100%',
            height: 'auto',
            width: '150px',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography>{pickupPointLabel}</Typography>
        <AddressView
          address={address}
          inline={false}
          pickupPoint={pickupPoint}
          titleWidth='100%'
          boxWidth='100%'
        />
      </Box>
    </Box>
  );
};

PickupPointWidget.propTypes = {
  partnerLogo: PropTypes.string,
  pickupPoint: PropTypes.object.isRequired,
  pickupPointLabel: PropTypes.string,
  address: PropTypes.object.isRequired,
};

export default PickupPointWidget;
