import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Box, Grid, Typography } from '@mui/material';

const OpenWindowList = ({ openWindows, className }) => (
  <Box id='OpenWindows' className={className}>
    <Grid container>
      {map(openWindows, ({ day, openWindow }, index) => (
        <Grid container key={day}>
          <Grid item xs>
            <Typography id={`OpenWindow-${day}`}>{day}</Typography>
          </Grid>
          <Grid sx={{ width: '71%', textAlign: 'end' }} item>
            <Typography id={`OpenWindow-${index}`}>{openWindow}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Box>
);

OpenWindowList.propTypes = {
  openWindows: PropTypes.array,
};

export default OpenWindowList;
