import apis from './apis';

// Address Fast Find Lookup
export const fastFind = async args => {
  const response = await apis.get('addressLookup/fast-find', {
    params: { ...args },
  });
  const { data } = response;

  return data;
};

// Address Retreive Lookup with full address details
export const retrieve = async args => {
  const response = await apis.get('addressLookup/retrieve', {
    params: {
      ...args,
    },
  });
  const { data } = response;

  return data;
};
