import { SvgIcon } from '@mui/material';

const Linkedin = () => (
  <SvgIcon sx={{ width: '1rem', height: '1rem' }}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M0.262669 5.3128H3.57947V16H0.262669V5.3128ZM1.92247 3.85164C2.98149 3.85164 3.84492 2.9895 3.84492 1.92582C3.84492 1.67292 3.7952 1.42249 3.69859 1.18884C3.60197 0.95519 3.46037 0.742889 3.28185 0.56406C3.10333 0.385231 2.8914 0.243376 2.65816 0.146594C2.42491 0.0498124 2.17493 0 1.92247 0C1.67 0 1.42002 0.0498124 1.18677 0.146594C0.953529 0.243376 0.741599 0.385231 0.563082 0.56406C0.384565 0.742889 0.242958 0.95519 0.146346 1.18884C0.049733 1.42249 6.77969e-06 1.67292 6.78501e-06 1.92582C-0.00278749 2.9895 0.857848 3.85164 1.92247 3.85164ZM8.97241 10.7124C8.97241 9.3184 9.23787 7.96921 10.9619 7.96921C12.6608 7.96921 12.686 9.56193 12.686 10.802V16H16V10.1386C16 7.26102 15.3797 5.04688 12.0238 5.04688C10.4115 5.04688 9.33008 5.93422 8.88579 6.77397H8.84108V5.3128H5.65841V16H8.97241V10.7124Z'
        fill='#DC0032'
      />
    </svg>
  </SvgIcon>
);

export default Linkedin;
