import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { TrackerProvider } from '@dpdgroupuk/react-event-tracker';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import theme from './theme';
import './index.scss';
import { store, persistor } from './redux/store';
import tracker from './utils/analytics';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <TrackerProvider tracker={tracker}>
        <CssVarsProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <App />
        </CssVarsProvider>
      </TrackerProvider>
    </PersistGate>
  </Provider>
);
