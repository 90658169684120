import axios from 'axios';
import { APIS_BASE_URL } from '../constants/env';

const baseURL = APIS_BASE_URL;

const apis = axios.create({
  baseURL,
  withCredentials: true,
  transformResponse: axios.defaults.transformResponse.concat(
    params => params.data || params
  ),
});

export const createResponseInterceptor = (onFulfilled, onRejected) => {
  apis.interceptors.response.use(onFulfilled, onRejected);
};

export default apis;
