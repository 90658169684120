import { createAsyncThunk } from '@reduxjs/toolkit';

import { basketApis, paymentApis } from '../../apis';
import { getBasketId } from './selectors';

export const createBasket = createAsyncThunk('basket/create', async () => {
  const response = await basketApis.createBasket();
  return response.data;
});

export const getBasketItems = createAsyncThunk(
  'basket/getBasketItems',
  async (_, { getState }) => {
    const basketId = getBasketId(getState());
    const response = await basketApis.getBasketItems(basketId);
    return response.data;
  }
);

export const createBasketItem = createAsyncThunk(
  'basket/createBasketItem',
  async (shipment, { getState }) => {
    const basketId = getBasketId(getState());
    const response = await basketApis.createBasketItem(basketId, shipment);
    return { basketId, basketItemId: response.data, shipment };
  }
);

export const fetchBasketItem = createAsyncThunk(
  'basket/fetchBasketItem',
  async (basketItemId, { getState }) => {
    const basketId = getBasketId(getState());
    const response = await basketApis.fetchBasketItem(basketId, basketItemId);
    return response.data;
  }
);

export const updateBasketItem = createAsyncThunk(
  'basket/updateBasketItem',
  async ({ basketItemId, shipment }, { getState }) => {
    const basketId = getBasketId(getState());
    await basketApis.updateBasketItem(basketId, basketItemId, shipment);
    return { basketId, basketItemId, shipment };
  }
);

export const deleteBasketItem = createAsyncThunk(
  'basket/deleteBasketItem',
  async (basketItemId, { getState }) => {
    const basketId = getBasketId(getState());
    const response = await basketApis.deleteBasketItem(basketId, basketItemId);
    return response.data;
  }
);

export const checkout = createAsyncThunk(
  'basket/checkout',
  async ({ basketItemIds, purchaseData }, { getState }) => {
    const basketId = getBasketId(getState());
    return await paymentApis.verifyAndPay(
      basketId,
      basketItemIds,
      purchaseData
    );
  }
);
