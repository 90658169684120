import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button } from '@mui/material';
import { noop } from 'lodash';
import { styled } from '@mui/system';
import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import PickupPointImage from '../../../assets/images/pickup_point.png';
import {
  getPickupAddress,
  getPartnerLogo,
  getPickupOpenWindowList,
  getPickupAmenities,
  getPickupInfo,
  getPickupAddressPoint,
} from '../../../utils/pickupPoint';
import * as M from '../../../constants/strings';
import useIsMobile from '../../../hooks/useIsMobile';
import Direction from '../../icons/Direction';
import PickupPoint from '../../icons/PickupPoint';
import { handleRedirectToGoogleMaps } from '../../../utils/googleMaps';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import OpenWindowList from './OpenWindowList';
import AddressView from './AddressView';
import PickupAmenities from './PickupAmenities';
import PickupInfo from './PickupInfo';

const StyledBox = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.borderLight}`,
  display: 'flex',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  flexDirection: 'column',
}));

const BoxWithPaddings = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.directionBlue}`,
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.primary.directionBlue,
  border: 'none',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const StyledCloseIcon = styled(Close)(({ theme }) => ({
  color: theme.palette.primary.iconGray,
}));

const StyledPickupPointIconBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  right: theme.spacing(1),
}));

const StyledGridDetailsContainer = styled(Grid)(
  ({ theme, isModal, isMobile }) => {
    const styleOverrides =
      isModal || isMobile
        ? {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            margin: isMobile ? 0 : '10% auto',
            width: isMobile ? '100%' : 500,
            height: '100%',
            alignContent: 'flex-start',
            maxHeight: '70%',
          }
        : undefined;

    return {
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: 1,
      boxShadow: theme.palette.primary.shadow,
      zIndex: '10',
      justifyContent: 'flex-end',
      maxHeight: '-webkit-fill-available',
      overflowX: 'hidden',
      top: '15%',
      left: '32vw',
      height: 'auto',
      position: 'absolute',
      width: 345,
      [theme.breakpoints.down('md')]: {
        maxHeight: '100%',
      },
      ...styleOverrides,
    };
  }
);

const StyledGridDetailsContent = styled(Grid)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    paddingTop: theme.spacing(1),
    height: '100%',
    width: '100%',
  },
}));

const PickupDetails = ({
  pickupPoint,
  directionLink,
  onDirectionsClick,
  onClose,
  isModal = false,
  onChange,
}) => {
  const partnerLogo = getPartnerLogo(pickupPoint);
  const isMobile = useIsMobile();
  const { showError } = useCustomSnackbar();

  const handleClose = event => {
    event.stopPropagation();
    onClose();
  };

  const [location, setLocation] = useState(null);
  const [startLocation, setStartLocation] = useState('');
  const destination = getPickupAddressPoint(pickupPoint);

  const handleGetLocation = () => {
    onDirectionsClick();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        error => {
          if (error.code === error.PERMISSION_DENIED) {
            handleRedirectToGoogleMaps(startLocation, destination);
          } else {
            showError({ message: error });
          }
        }
      );
    } else {
      handleRedirectToGoogleMaps(startLocation, destination);
    }
  };

  return (
    <Box
      id='pickup-details'
      sx={{
        justifyContent: isModal ? 'center' : 'flex-start',
        display: 'flex',
      }}
    >
      <StyledGridDetailsContainer
        container
        isModal={isModal}
        isMobile={isMobile}
      >
        <IconButton sx={{ maxHeight: '40px' }} onClick={handleClose}>
          <StyledCloseIcon />
        </IconButton>
        <StyledGridDetailsContent item>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                position: 'relative',
              }}
            >
              <img
                src={partnerLogo || PickupPointImage}
                alt='Pickup Point'
                style={{ maxWidth: '100%', height: 'auto', width: '100%' }}
              />
              <StyledPickupPointIconBox>
                <PickupPoint />
              </StyledPickupPointIconBox>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <StyledBox>
                <BoxWithPaddings
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <AddressView
                    address={getPickupAddress(pickupPoint)}
                    inline={false}
                    pickupPoint={pickupPoint}
                    titleWidth={'180px'}
                    boxWidth={'70%'}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2 }}>
                    <StyledButton
                      href={directionLink}
                      target='_blank'
                      onClick={handleGetLocation}
                    >
                      <Direction />
                      {M.DIRECTIONS}
                    </StyledButton>
                  </Box>
                </BoxWithPaddings>
                {isModal && (
                  <Button
                    sx={{ mx: 4, my: 2 }}
                    variant='contained'
                    color='primary'
                    onClick={() => onChange(pickupPoint)}
                  >
                    {M.SELECTED_PICKUP_POINT}
                  </Button>
                )}
                <BoxWithPaddings sx={{ display: 'flex', mt: 1 }}>
                  <PickupInfo
                    showIsOpen={true}
                    {...getPickupInfo(pickupPoint)}
                  />{' '}
                </BoxWithPaddings>
              </StyledBox>
              <StyledBox>
                <BoxWithPaddings>
                  <OpenWindowList
                    openWindows={getPickupOpenWindowList(pickupPoint)}
                  />
                </BoxWithPaddings>
              </StyledBox>
              <StyledBox>
                <BoxWithPaddings>
                  <PickupAmenities {...getPickupAmenities(pickupPoint)} />
                </BoxWithPaddings>
              </StyledBox>
            </Box>
          </Box>
        </StyledGridDetailsContent>
      </StyledGridDetailsContainer>
    </Box>
  );
};

PickupDetails.propTypes = {
  pickupPoint: PropTypes.object.isRequired,
  directionLink: PropTypes.string,
  onDirectionsClick: PropTypes.func,
  onClose: PropTypes.func,
};

PickupDetails.defaultProps = {
  onDirectionsClick: noop,
};

export default PickupDetails;
