import { uniq } from 'lodash';
import { Formatters } from '../utils';

export const formatAddressWithPostcode = address =>
  Formatters.joinStringsWithComma(
    uniq([
      address?.organisation,
      Formatters.joinStringsWith(
        uniq([address?.property, address?.street]),
        ' '
      ),
      address?.town,
      address?.county,
      address?.postcode,
    ])
  );

export const transformAddressValues = ({ street, locality, property }) => {
  let addressLine1;
  let addressLine2;

  if (property) {
    addressLine1 = `${property} ${street}`;
  } else {
    addressLine1 = street;
  }

  if (addressLine1 && addressLine1.length > 35) {
    addressLine1 = property;
    addressLine2 = `${street}, ${locality}`;

    if (addressLine2 && addressLine2.length > 35) {
      addressLine2 = street;
    }
  } else {
    addressLine2 = locality;
  }

  return {
    addressLine1,
    addressLine2,
  };
};
