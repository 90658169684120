import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { CANCEL } from '../../constants/strings.js';
import useBreakpoint from '../../hooks/useBreakpoint.js';

const StyledButton = styled(Button)({
  width: '100%',
});

const ConfirmDialog = ({
  confirmButtonText,
  children,
  onConfirm,
  onClose,
  title,
  open,
  containerStyle,
  ...props
}) => {
  const isSmallScreen = useBreakpoint('sm');

  return (
    <Dialog
      PaperProps={{
        style: {
          maxWidth: isSmallScreen ? '100%' : '326px',
          borderRadius: '8px',
          margin: '5%',
          ...containerStyle,
        },
      }}
      onClose={onClose}
      open={open}
      {...props}
    >
      <DialogTitle
        sx={{
          padding: 2,
          paddingBottom: 1,
        }}
      >
        <Typography
          variant='h3'
          sx={{
            fontSize: '20px',
            lineHeight: '24px',
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          padding: 2,
        }}
      >
        <Typography
          variant='body1'
          sx={{
            fontFamily: 'Pluto Sans DPD',
            fontSize: '16px',
            fontWeight: 370,
            lineHeight: '24px',
            letterSpacing: '0.15px',
          }}
        >
          {children}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: `${isSmallScreen ? 'column' : 'row'}`,
          padding: 2,
          paddingTop: 1,
          gap: 1,
        }}
        disableSpacing
      >
        <StyledButton onClick={onClose} variant='outlined'>
          {CANCEL}
        </StyledButton>
        <StyledButton onClick={onConfirm} variant='contained'>
          {confirmButtonText}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
