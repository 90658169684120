import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import PlutoSansDPDRegular from '../assets/fonts/PlutoSansDPDRegular.ttf';
import themeConfig from './theme.json';

// Create a theme instance.
const theme = extendTheme({
  ...themeConfig,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Pluto Sans DPD';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Pluto Sans DPD'), local('Pluto Sans DPD Regular'), url(${PlutoSansDPDRegular}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          '&:hover:not(.Mui-disabled, .Mui-error)': {
            backgroundColor: '#FFFFFF',
          },
          '&.Mui-focused': {
            backgroundColor: '#FFFFFF',
          },
          '&.Mui-disabled': {
            backgroundColor: '#E0E0E0',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-colorSecondary.Mui-disabled': {
            backgroundColor: '#E0E0E0',
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          // Disable no options empty autocomplete
          '& .MuiAutocomplete-noOptions': {
            display: 'none',
          },
        },
      },
    },
  },
});

export default theme;
