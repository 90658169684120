import { ActionTracker } from '@dpdgroupuk/react-event-tracker';
import { IS_DEV_MODE } from '../constants/env';

export default new ActionTracker({
  projectRef: 'DPDO',
  debug: IS_DEV_MODE,
  // TODO: Clarify values below
  //   productId: 56,
  //   url: `${process.env.REACT_APP_CPP_API_URL}/analytics`,
});
