import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Form, Field } from 'react-final-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { OnBlur } from 'react-final-form-listeners';
import { useDispatch } from 'react-redux';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import useAnalytics from '../../utils/useAnalytics';
import {
  composeValidators,
  email,
  maxLength,
  required,
} from '../../utils/validators';
import { useAuth } from '../../features/Auth/Context';
import { useOverlay } from '../../features/Overlay';
import {
  SIGN_IN,
  SIGN_IN_WITH_EMAIL,
  SIGN_IN_WITH_EMAIL_DESCRIPTION,
  SIGN_IN_WITH_EMAIL_DESC_EXPIRED,
  SIGN_IN_WITH_EMAIL_EXPIRED,
  AUTH,
} from '../../constants/strings';
import loginImg1 from '../../assets/images/loginImg1.png';
import { SendEmail } from '../../components/icons';
import { ENTER_EMAIL } from '../../constants/analytics';
import { FIELDS } from '../../constants/forms';
import useErrorHandler from '../../hooks/useErrorHandler';
import { TOO_MANY_REQUESTS_CODE } from '../../constants/error';
import { VerifyEmailSlice } from './slice';

function EnterEmail({ nextStep, goToNamedStep, isExpired }) {
  const auth = useAuth();
  const overlay = useOverlay();
  const tracker = useTracker();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setInterfaceId, Trackable } = useAnalytics();

  useEffect(() => {
    setInterfaceId(ENTER_EMAIL.INTERFACE_ID);
  }, []);

  const { errorHandler } = useErrorHandler();

  const onSubmit = useCallback(
    async formValues => {
      try {
        tracker.logEvent(ENTER_EMAIL.ON_SEND);
        overlay.show();
        await auth.sendMagicLink(formValues.email).unwrap();
        nextStep({ email: formValues.email, attempts: 1 });
      } catch (error) {
        errorHandler(AUTH.SEND_MAGIC_LINK_ERROR, error, 'EnterEmail');

        if (error.code === TOO_MANY_REQUESTS_CODE) {
          dispatch(VerifyEmailSlice.actions.clearAll());
          goToNamedStep('error', { error });
        }
      } finally {
        overlay.hide();
      }
    },
    [auth]
  );

  const handleEmailBlur = () => {
    tracker.logEvent(ENTER_EMAIL.ON_EMAIL_INPUT);
  };

  useEffect(() => {
    if (auth.currentSession?.uid) {
      navigate('/');
    }
  }, []);

  return (
    <Trackable loadId={ENTER_EMAIL.LOAD}>
      <Box sx={{ flexGrow: 1, p: { xs: 3, md: 2 }, display: 'flex' }}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img src={loginImg1} style={{ width: '100%' }} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: { md: '78px' },
            justifyContent: 'center',
            maxWidth: { md: '500px' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SendEmail styles={{ alignSelf: 'center' }} />
          <Typography variant='h2' sx={{ mt: 2 }}>
            {isExpired ? SIGN_IN_WITH_EMAIL_EXPIRED : SIGN_IN_WITH_EMAIL}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {isExpired
              ? SIGN_IN_WITH_EMAIL_DESC_EXPIRED
              : SIGN_IN_WITH_EMAIL_DESCRIPTION}
          </Typography>
          <Box
            sx={{
              mt: 4,
            }}
          >
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, invalid }) => (
                <Box
                  component='form'
                  autoComplete='off'
                  onSubmit={handleSubmit}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <FormControl fullWidth>
                    <Field
                      name={FIELDS.EMAIL.KEY}
                      validate={composeValidators(
                        required,
                        email,
                        maxLength(255)
                      )}
                    >
                      {props => (
                        <>
                          <TextField
                            id='auth_email'
                            label={FIELDS.EMAIL.LABEL}
                            placeholder={FIELDS.EMAIL.PLACEHOLDER}
                            variant='standard'
                            error={
                              !!(
                                props.meta.dirty &&
                                !props.meta.active &&
                                props?.meta?.error
                              )
                            }
                            helperText={
                              props.meta.dirty &&
                              !props.meta.active &&
                              props?.meta?.error
                            }
                            required
                            {...props.input}
                          />
                        </>
                      )}
                    </Field>
                    <OnBlur name='email'>{handleEmailBlur}</OnBlur>
                  </FormControl>
                  <Button
                    sx={{
                      mt: 4,
                      alignSelf: 'flex-end',
                    }}
                    type='submit'
                    variant='contained'
                    disabled={submitting || invalid}
                  >
                    {SIGN_IN}
                  </Button>
                </Box>
              )}
            />
          </Box>
        </Box>
      </Box>
    </Trackable>
  );
}

export default EnterEmail;
