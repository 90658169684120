import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';
import { getStorage } from 'firebase/storage';
import { getRemoteConfig } from 'firebase/remote-config';

const {
  NODE_ENV,
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_DATABASE_URL,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_APP_ID,
  VITE_RECAPTCHA_SITE_KEY,
  VITE_FIREBASE_APPCHECK_DEBUG_TOKEN,
} = import.meta.env;

const config = {
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: VITE_FIREBASE_DATABASE_URL,
  projectId: VITE_FIREBASE_PROJECT_ID,
  storageBucket: VITE_FIREBASE_STORAGE_BUCKET,
  appId: VITE_FIREBASE_APP_ID,
};

export const defaultApp = initializeApp(config);

export const firebaseAuth = getAuth();
export const firebaseStorage = getStorage();
firebaseStorage.maxUploadRetryTime = 30000;

export const remoteConfig = getRemoteConfig(defaultApp);
if (NODE_ENV === 'development') {
  remoteConfig.settings = {
    /**
     * Defines the maximum age in milliseconds of an entry in the config cache before
     * it is considered stale. Defaults to 43200000 (Twelve hours).
     */
    minimumFetchIntervalMillis: 3000,
  };
}

window.FIREBASE_APPCHECK_DEBUG_TOKEN = VITE_FIREBASE_APPCHECK_DEBUG_TOKEN;

if (NODE_ENV !== 'production') {
  window.firebaseAuth = firebaseAuth;
}

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
export const appCheck = initializeAppCheck(defaultApp, {
  provider: new ReCaptchaEnterpriseProvider(VITE_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: false, // Set to true to allow auto-refresh.
});
