const Direction = props => (
  <svg
    width='1.8125rem' // 29px
    height='1.75rem' // 28px
    viewBox='0 0 29 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect x='0.5' width='28' height='28' rx='14' fill='#1E88E5' />
    <path
      d='M22.3427 12.8208L15.6844 6.15417C15.0344 5.50417 13.9761 5.50417 13.3261 6.15417L6.65107 12.8208C6.00107 13.4708 6.00107 14.5292 6.65107 15.1792L13.3261 21.8458C13.9761 22.4958 15.0344 22.4958 15.6844 21.8458L22.3427 15.1792C23.0011 14.5208 23.0011 13.4708 22.3427 12.8208ZM15.7511 16.0792V13.9958H12.8344V16.4958H11.1677V13.1625C11.1677 12.7042 11.5427 12.3292 12.0011 12.3292H15.7511V10.2458L18.6677 13.1625L15.7511 16.0792Z'
      fill='white'
    />
  </svg>
);

export default Direction;
