import { Box, CircularProgress } from '@mui/material';

const Pending = ({ sx, ...props }) => (
  <Box
    {...props}
    sx={{
      ...sx,
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress />
  </Box>
);

export default Pending;
