import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Wizard from './RouterWizard';

export default function ReduxifiedWizard({ slice = {}, children, ...props }) {
  const dispatch = useDispatch();
  const { actions, selectors } = slice;

  const { activeStep, values, references } = useSelector(
    selectors.getWizardState
  );

  useEffect(
    () => () => {
      dispatch(actions.clear());
    },
    []
  );

  const handleStepChange = payload => {
    dispatch(actions.setActiveStep(payload));
  };

  const handleValuesChange = values => {
    dispatch(actions.setValues(values));
  };

  const handleStepValuesChange = (stepName, values) => {
    dispatch(actions.setStepValues({ stepName, values }));
  };

  const handleReferencesChange = references => {
    dispatch(actions.setReferences(references));
  };

  return (
    <Wizard
      initialStep={activeStep}
      initialValues={values}
      initialReferences={references}
      onStepChange={handleStepChange}
      onValuesChange={handleValuesChange}
      onStepValuesChange={handleStepValuesChange}
      onReferencesChange={handleReferencesChange}
      {...props}
    >
      {children}
    </Wizard>
  );
}

ReduxifiedWizard.propTypes = {
  ...Wizard.propTypes,
  slice: PropTypes.shape({
    actions: PropTypes.shape({
      setActiveStep: PropTypes.func,
      setStepValues: PropTypes.func,
      setReferences: PropTypes.func,
      clear: PropTypes.func,
    }).isRequired,
    selectors: PropTypes.shape({
      getWizardState: PropTypes.func,
    }).isRequired,
  }),
};
