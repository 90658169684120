import { chain, get, cloneDeep } from 'lodash';
import { createSelector } from 'reselect';

import { OrderSliceSelectors } from '../../slice';
import { ShipmentHelper } from '../../helpers';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';

export const getInitialFormValues = createSelector(
  OrderSliceSelectors.getValues,
  values => {
    const clonedValues = cloneDeep(values);
    return chain(clonedValues)
      .set(
        SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY,
        get(values, SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY) ||
          ShipmentHelper.getSenderUserType(values)
      )
      .set(
        SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY,
        get(values, SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY) ||
          ShipmentHelper.getReceiverUserType(values)
      )
      .value();
  }
);
