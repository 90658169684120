import { useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { useAuth } from '../../features/Auth/Context';
import Loader from '../../features/Loader/Loader';
import ReduxifiedWizard from '../../features/Wizard/ReduxifiedWizard';
import { VERIFICATION } from '../../constants/analytics';
import { EXPIRED_AUTH_CODES } from '../../constants/error';
import { SIGN_IN } from '../../constants/routes';
import EnterEmail from './EnterEmail';
import EmailSent from './EmailSent';
import Step from './Step';
import { VerifyEmailSlice } from './slice';
import Error from './Error';

export const STEPS = {
  ENTER: 'enter',
  SENT: 'sent',
  ERROR: 'error',
};

function VerifyEmail() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateAuthToken = useCallback(async ({ verificationId }) => {
    if (verificationId) {
      try {
        const { customToken, isOnboarding } = await auth
          .verifyMagicLink(verificationId)
          .unwrap();

        if (isOnboarding) {
          navigate(`${SIGN_IN}/terms`, {
            replace: true,
            state: {
              verificationId,
              customToken,
            },
          });
        } else {
          navigate('/', { replace: true });
        }
      } catch (error) {
        if (EXPIRED_AUTH_CODES.indexOf(error.code) >= 0) {
          dispatch(VerifyEmailSlice.actions.clearAll());
          return true;
        }

        throw error;
      }
    }
  }, []);

  return (
    <Trackable
      interfaceId={VERIFICATION.INTERFACE_ID}
      loadId={VERIFICATION.LOAD}
    >
      <Loader
        promiseFn={validateAuthToken}
        verificationId={searchParams.get('verificationId')}
        errorComponent={error => <Error values={{ error }} />}
      >
        {isExpired => (
          <ReduxifiedWizard
            isLazyMount={true}
            slice={VerifyEmailSlice}
            sx={{ flexGrow: 1, display: 'flex', width: '100%' }}
          >
            <Step
              stepName={STEPS.ENTER}
              component={EnterEmail}
              isExpired={isExpired}
            />
            <Step stepName={STEPS.SENT} component={EmailSent} />
            <Step stepName={STEPS.ERROR} component={Error} />
          </ReduxifiedWizard>
        )}
      </Loader>
    </Trackable>
  );
}

export default VerifyEmail;
