const InstagramIcon = ({ ...props }) => (
  <svg
    width='33'
    height='32'
    viewBox='0 0 33 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.0151 0H16.9423C8.12843 0 0.983398 7.14713 0.983398 15.9636V16.0364C0.983398 24.8529 8.12843 32 16.9423 32H17.0151C25.8289 32 32.974 24.8529 32.974 16.0364V15.9636C32.974 7.14713 25.8289 0 17.0151 0Z'
      fill='#383D38'
    />
    <path
      d='M21.9286 6.51025H12.0298C9.29507 6.51025 7.07031 8.73567 7.07031 11.4712V20.5298C7.07031 23.2653 9.29507 25.4907 12.0298 25.4907H21.9286C24.6633 25.4907 26.888 23.2653 26.888 20.5298V11.4712C26.888 8.73567 24.6633 6.51025 21.9286 6.51025ZM8.81986 11.4712C8.81986 9.70095 10.2601 8.26031 12.0298 8.26031H21.9286C23.6983 8.26031 25.1385 9.70095 25.1385 11.4712V20.5298C25.1385 22.3 23.6983 23.7407 21.9286 23.7407H12.0298C10.2601 23.7407 8.81986 22.3 8.81986 20.5298V11.4712Z'
      fill='white'
    />
    <path
      d='M16.9794 20.614C19.5224 20.614 21.5925 18.5445 21.5925 15.9995C21.5925 13.4546 19.5236 11.385 16.9794 11.385C14.4352 11.385 12.3662 13.4546 12.3662 15.9995C12.3662 18.5445 14.4352 20.614 16.9794 20.614ZM16.9794 13.1362C18.5586 13.1362 19.843 14.421 19.843 16.0006C19.843 17.5803 18.5586 18.8651 16.9794 18.8651C15.4002 18.8651 14.1158 17.5803 14.1158 16.0006C14.1158 14.421 15.4002 13.1362 16.9794 13.1362Z'
      fill='white'
    />
    <path
      d='M22.0196 12.1304C22.7044 12.1304 23.2625 11.5732 23.2625 10.8871C23.2625 10.201 22.7055 9.6438 22.0196 9.6438C21.3336 9.6438 20.7766 10.201 20.7766 10.8871C20.7766 11.5732 21.3336 12.1304 22.0196 12.1304Z'
      fill='white'
    />
  </svg>
);

export default InstagramIcon;
