import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import loginImg3 from '../../assets/images/loginImg3.png';
import { BACK_TO_HOME, ERROR_MESSAGES_MAP } from '../../constants/strings';
import { getAuthRetryAfter } from '../../features/Auth/selectors';
import {
  REACH_MAX_LOGIN_ATTEMPTS_CODE,
  TOO_MANY_REQUESTS_CODE,
} from '../../constants/error';
import { formatTime } from '../../utils/formatters';
import { VerifyEmailSlice } from './slice';

const Error = ({ values }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(ERROR_MESSAGES_MAP.default);
  const retryAfter = useSelector(getAuthRetryAfter);

  useEffect(() => {
    if (!values.error) {
      dispatch(VerifyEmailSlice.actions.clearAll());
      navigate('/', { replace: true });
    }
  }, []);

  const { error = {} } = values;

  useEffect(() => {
    const mappedError = ERROR_MESSAGES_MAP[error.code];

    // Retry after case handling
    if (
      mappedError &&
      [REACH_MAX_LOGIN_ATTEMPTS_CODE, TOO_MANY_REQUESTS_CODE].includes(
        error.code
      )
    ) {
      const minutes = retryAfter ? formatTime(retryAfter) : null;
      const title = mappedError.title;
      const description = mappedError.description(minutes);
      setErrorMessage({ title, description });
    } else if (mappedError) {
      setErrorMessage(mappedError);
    } else {
      setErrorMessage(({ title }) => ({
        title,
        description: `${error.code}: ${error.message}`,
      }));
    }
  }, [error.code]);

  const handleToHome = useCallback(() => {
    dispatch(VerifyEmailSlice.actions.clearAll());
    navigate('/', { replace: true });
  }, [dispatch, navigate]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: { xs: 3, md: 2 },
        display: 'flex',
        justifyContent: { xs: 'center', md: 'start' },
      }}
    >
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <img src={loginImg3} style={{ width: '100%' }} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          ml: { sx: 0, md: '78px' },
          justifyContent: 'center',
          maxWidth: '500px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant='h2' sx={{ mt: 2 }}>
          {errorMessage.title}
        </Typography>

        <Typography sx={{ mt: 2 }}>{errorMessage.description}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-end',
            gap: 2,
            mt: { xs: 5, md: 0 },
            alignItems: { xs: 'center', md: 'flex-end' },
          }}
        >
          <Button
            sx={{
              mt: { md: 4 },
              alignSelf: { md: 'flex-end' },
            }}
            onClick={handleToHome}
            variant='contained'
          >
            {BACK_TO_HOME}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Error;
