import Joi from 'joi';
import {
  HEIGHT,
  LENGTH,
  QUANTITY,
  WEIGHT,
  WIDTH,
} from '../../../constants/forms.js';

export const parcelSchema = ({ maxQuantity = 99, maxWeight = 30 } = {}) =>
  Joi.object({
    parcelId: Joi.string(),
    _weight: Joi.number().greater(0).max(maxWeight).label(WEIGHT),
    _quantity: Joi.number().min(1).max(maxQuantity).label(QUANTITY),
    _width: Joi.number().greater(0).max(100).label(WIDTH),
    _height: Joi.number().greater(0).max(100).label(HEIGHT),
    _length: Joi.number().greater(0).max(100).label(LENGTH),
    _description: Joi.string().allow('', null),
    products: Joi.array().items(Joi.object()).allow(null),
  });
