import apis from './apis';

export const getParcels = ({ searchPage, searchPageSize }) =>
  apis.get('parcels/filter', {
    params: {
      searchPage,
      searchPageSize,
    },
  });

export const getParcel = parcelCode => apis.get(`parcels/${parcelCode}`);
