import { createAsyncThunk } from '@reduxjs/toolkit';
import { collectionsApis } from '../../../../apis';

export const fetchCollectionDates = createAsyncThunk(
  'collectionDates/fetch',
  async ({ countryCode, postcode }) => {
    const { data } = await collectionsApis.getCollectionDates({
      countryCode,
      postcode,
    });

    return data;
  }
);
