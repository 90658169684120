import { isNil } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';

import addressBooksSlice from '../../redux/addressBooksSlice';
import accountDetailsSlice from '../../redux/accountDetailsSlice';
import { DateUtil } from '../../utils';
import { DATE_FORMAT } from '../../constants';

const defaultEmptyAddress = {
  organisation: null,
  // property: deliveryAddress.property, there is no mapping on server side
  street: null,
  locality: null,
  town: null,
  county: null,
  postcode: null,
  countryCode: null,
  addressId: null,
};

export const getDeliveryAddress = (
  state,
  { deliveryCountryCode, deliveryAddress }
) =>
  deliveryAddress
    ? {
        organisation: deliveryAddress.organisation,
        // property: deliveryAddress.property, there is no mapping on server side
        street: deliveryAddress.street,
        locality: deliveryAddress.locality,
        town: deliveryAddress.town,
        county: deliveryAddress.county,
        postcode: deliveryAddress.postcode,
        countryCode: deliveryCountryCode,
        addressId: deliveryAddress.key,
      }
    : defaultEmptyAddress;

export const getDefaultCollectionAddress = createSelector(
  addressBooksSlice.selectors.getDefaultAddressBook,
  defaultAddress =>
    isNil(defaultAddress)
      ? null
      : {
          countryCode: defaultAddress.countryCode,
          postcode: defaultAddress.postcode,
          county: defaultAddress.addressLine4,
          town: defaultAddress.addressLine3,
          // organisation: deliveryAddress.organisation,
          street: defaultAddress.addressLine1,
          locality: defaultAddress.addressLine2,
        }
);

export const getDefaultCollectionContactDetails = createSelector(
  addressBooksSlice.selectors.getDefaultAddressBook,
  accountDetailsSlice.selectors.getAccountDetailsData,
  (defaultAddress, accountDetails) => {
    if (!isNil(defaultAddress)) {
      return {
        contactName: defaultAddress.contactName,
        telephone: defaultAddress.phoneNumber,
        email: defaultAddress.email,
      };
    }

    if (!isNil(accountDetails)) {
      return {
        contactName: `${accountDetails.firstName} ${accountDetails.lastName}`,
        telephone: accountDetails.phone,
        email: accountDetails.email,
      };
    }

    return null;
  }
);

export const getShipmentDto = createStructuredSelector({
  shipmentDate: () =>
    DateUtil.getUKCurrentMomentTime()
      .add(1, 'days')
      .format(DATE_FORMAT.DATE_TIME_FORMAT),
  'outboundConsignment.deliveryDetails.address': getDeliveryAddress,
  'outboundConsignment.collectionDetails.address': getDefaultCollectionAddress,
  'outboundConsignment.collectionDetails.contactDetails':
    getDefaultCollectionContactDetails,
  'outboundConsignment.parcels': () => [
    {
      parcelId: crypto.randomUUID(),
    },
  ],
  'outboundConsignment.totalWeight': () => 1,
  'outboundConsignment.numberOfParcels': () => 1,
});
