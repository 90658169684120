import Close from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Divider,
  Modal,
  Typography,
  Grid,
  Pagination,
} from '@mui/material';
import { ADDRESS_BOOK_MODAL, ADDRESSES_SECTION } from '../../constants/strings';
import { DEFAULT_PAGINATION_SEARCH_MODAL_SIZE } from '../../constants/pagination';
import addressBooksSlice from '../../redux/addressBooksSlice';
import { AddressBookTable } from '../../pages/UserPreferences/AddressesSection/components';
import { ADDRESS_BOOK_MODAL_TABLE_CONFIG } from '../../constants/table';
import { useAuth } from '../../features/Auth';
import useErrorHandler from '../../hooks/useErrorHandler';

const AddressBookModal = ({ open, onClose, onSelect }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { currentSession } = useAuth();

  const { errorHandler } = useErrorHandler();
  const dispatch = useDispatch();

  const searchPageSize = DEFAULT_PAGINATION_SEARCH_MODAL_SIZE;

  const addressBooks = useSelector(state =>
    addressBooksSlice.selectors.getAddressBooksByPage(state, {
      page: currentPage,
      pageSize: searchPageSize,
    })
  );

  useEffect(() => {
    const fetchAddressBooks = async () => {
      if (currentSession) {
        try {
          const data = await dispatch(
            addressBooksSlice.actions.fetchAddressBooks({
              searchPage: currentPage,
              searchPageSize,
            })
          ).unwrap();
          setTotalPages(data.totalPages);
        } catch (error) {
          errorHandler(
            ADDRESSES_SECTION.NOTIFICATIONS.ERROR.FETCHING_ERROR,
            error
          );
        }
      }
    };

    fetchAddressBooks();
  }, [currentPage, currentSession, dispatch, searchPageSize]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setCurrentPage(1);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'primary.contrastText',
          position: 'relative',
          m: { sm: '10% auto' },
          borderRadius: 1,
          maxWidth: { sm: 600 },
          height: {
            xs: '100%',
            sm: 'auto',
          },
          maxHeight: {
            md: '55%',
          },
          minHeight: {
            // md: '50%',
          },
          overflowY: {
            xs: 'scroll',
            sm: 'auto',
            md: 'hidden',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h3' sx={{ p: 2 }}>
            {ADDRESS_BOOK_MODAL.TITLE}
          </Typography>
          <IconButton
            aria-label='close'
            sx={{ mr: 0.5 }}
            onClick={() => {
              onClose();
              setCurrentPage(1);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, overflowY: 'auto' }}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Grid item sx={{ maxWidth: '100%' }}>
              <Box
                sx={{
                  width: '100%',
                  overflowY: 'hidden',
                }}
              >
                <Divider />
                <Box sx={{ padding: 2, overflowX: 'auto' }}>
                  <AddressBookTable
                    rows={addressBooks}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onSelect={selectedAddresses => {
                      onSelect(selectedAddresses);
                      setCurrentPage(1);
                    }}
                    searchPageSize={searchPageSize}
                    config={ADDRESS_BOOK_MODAL_TABLE_CONFIG}
                    styles={{
                      head: { position: 'sticky' },
                      container: { maxHeight: 270, overflowY: 'auto' },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '8px 16px 16px',
                  bottom: '0',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <Divider
                  sx={{ position: 'absolute', width: '100%', top: '0' }}
                />
                <Pagination
                  page={currentPage}
                  count={totalPages}
                  siblingCount={0}
                  shape='rounded'
                  showFirstButton
                  showLastButton
                  onChange={(_, page) => setCurrentPage(page)}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

AddressBookModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default AddressBookModal;
