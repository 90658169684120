import { useMemo } from 'react';

const useNormalizeParcels = parcels =>
  useMemo(
    () =>
      parcels.map(parcel => {
        const {
          parcelCode,
          parcelNumber,
          deliveryDetails,
          product,
          service,
          invoiceDate,
          collectionDate,
          parcelStatus,
        } = parcel;

        return {
          parcelCode,
          parcelNumber,
          recipient: deliveryDetails.notificationDetails.contactName,
          postcode: deliveryDetails.address.postcode,
          description: `${service.serviceDescription} - ${product.productDescription}`,
          ordered: invoiceDate,
          collected: collectionDate,
          //TODO: remove "??" operator after parcelStatus is implemented in mocks
          status: parcelStatus ?? 'Out For Delivery',
        };
      }),
    [parcels]
  );

export default useNormalizeParcels;
