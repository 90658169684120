import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Button,
  styled,
  Drawer,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import {
  ADDRESSES_SECTION,
  SELECT_ADDRESS,
} from '../../../../constants/strings';
import addressBooksSlice from '../../../../redux/addressBooksSlice';
import { normalizeAddressBookDataForDefaultMarkChangePayload } from '../../../../utils/addressBook';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar/index';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import { AddressBookDeleteModal, AddressBookForm } from '../components';
import { useOverlay } from '../../../../features/Overlay';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.17px',
  color: theme.palette.text.secondary,
  '&:first-child': {
    color: theme.palette.text.primary,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme, isSmallScreen }) => ({
  paddingTop: '6px',
  paddingBottom: '6px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '120px',
  alignContent: isSmallScreen ? 'start' : 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  '&:last-child': {
    minWidth: '40px',
    width: '40px',
    paddingLeft: '0px',
    paddingRight: '14px',
  },
}));

const ActionMenuBtn = ({ row, actionMenuBtnHandleClick }) => (
  <IconButton
    sx={{ padding: 0 }}
    disableRipple
    aria-label='more'
    onClick={e => actionMenuBtnHandleClick(e, row)}
  >
    <MoreVertIcon />
  </IconButton>
);
const ActionSelectBtn = ({ row, actionSelectBtnHandleClick }) => (
  <Button
    sx={{ mx: 0, my: 1 }}
    variant='outlined'
    color='primary'
    onClick={() => actionSelectBtnHandleClick(row)}
  >
    {SELECT_ADDRESS}
  </Button>
);
const ActionDefaultBtn = ({ row, actionCheckboxChange }) => (
  <Checkbox
    checked={row.isDefault}
    sx={{ paddingY: 0 }}
    disableRipple
    onChange={(_, value) => actionCheckboxChange(row, value)}
  />
);

const actionTableButtons = (
  column,
  row,
  handleIsDefaultChange,
  actionEditBtnHandleClick,
  onSelect
) => {
  switch (column.path) {
    case 'actionDefaultButton':
      return (
        <ActionDefaultBtn
          row={row}
          actionCheckboxChange={handleIsDefaultChange}
        />
      );
    case 'actionMenuBtn':
      return (
        <ActionMenuBtn
          row={row}
          actionMenuBtnHandleClick={actionEditBtnHandleClick}
        />
      );
    case 'actionSelectedButton':
      return (
        <ActionSelectBtn row={row} actionSelectBtnHandleClick={onSelect} />
      );
    default:
      if (column.content) {
        return column.content.map(contentColumn => (
          <StyledTypography variant='body2' key={contentColumn.path}>
            {row[contentColumn.path]}
          </StyledTypography>
        ));
      }
      return row[column.path];
  }
};

const AddressBookTable = ({
  rows = [],
  onEditRowHandler,
  currentPage,
  setCurrentPage,
  onSelect,
  searchPageSize,
  config,
  isSidebarOpen,
  isEditMode,
  styles = {},
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useBreakpoint('md');

  const { showSuccess } = useCustomSnackbar();
  const overlay = useOverlay();
  const { errorHandler } = useErrorHandler();

  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const actionEditBtnHandleClick = (e, row) => {
    setActionMenuAnchorEl(e.currentTarget);
    setCurrentRow(row);
  };

  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
  };

  const handleDelete = useCallback(async () => {
    if (currentRow) {
      try {
        overlay.show();

        await dispatch(
          addressBooksSlice.actions.deleteAddressBook(currentRow.addressBookId)
        ).unwrap();

        // if the last addressBook item on the page is being deleted,
        // fetch addressBooks for the prev page
        if (rows.length === 1) {
          const prevPageNumber = currentPage - 1;
          const searchPage = prevPageNumber > 1 ? prevPageNumber : 1;
          await dispatch(
            addressBooksSlice.actions.fetchAddressBooks({
              searchPage,
              searchPageSize,
            })
          );
          setCurrentPage && setCurrentPage(searchPage);
        } else {
          await dispatch(
            addressBooksSlice.actions.fetchAddressBooks({
              searchPage: currentPage,
              searchPageSize,
            })
          );
        }

        showSuccess({
          message: ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.DELETE,
          variant: 'default',
        });
      } catch (error) {
        errorHandler(
          ADDRESSES_SECTION.NOTIFICATIONS.ERROR.DELETE,
          error,
          'AddressBookTable'
        );
      } finally {
        overlay.hide();
      }
    }
  }, [
    currentRow,
    dispatch,
    overlay,
    rows.length,
    showSuccess,
    currentPage,
    setCurrentPage,
    searchPageSize,
    errorHandler,
  ]);

  const handleEdit = useCallback(async () => {
    if (currentRow) {
      try {
        overlay.show();
        actionMenuBtnHandleClose();
        await dispatch(
          addressBooksSlice.actions.getAddressBook(currentRow.addressBookId)
        ).unwrap();
        onEditRowHandler(true);
      } finally {
        overlay.hide();
      }
    }
  }, [overlay, currentRow, dispatch, onEditRowHandler]);

  const handleIsDefaultChange = useCallback(
    async (row, value) => {
      try {
        overlay.show();
        await dispatch(
          addressBooksSlice.actions.updateAddressBook({
            addressBookId: row.addressBookId,
            addressBookData: {
              ...normalizeAddressBookDataForDefaultMarkChangePayload(row),
              isDefault: value,
            },
          })
        ).unwrap();

        await dispatch(
          addressBooksSlice.actions.fetchAddressBooks({
            searchPage: 1,
            searchPageSize,
          })
        );

        setCurrentPage(1);

        showSuccess({
          message: ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.UPDATE,
          variant: 'default',
        });
      } catch (error) {
        errorHandler(
          ADDRESSES_SECTION.NOTIFICATIONS.ERROR.UPDATE,
          error,
          'AddressBookTable'
        );
      } finally {
        overlay.hide();
      }
    },
    [overlay, currentPage, dispatch, errorHandler, searchPageSize, showSuccess]
  );

  return (
    <>
      {!rows.length ? (
        <Box
          sx={{
            textAlign: 'center',
            mt: 1,
            color: theme.palette.text.secondary,
            fontSize: '12px',
          }}
        >
          {ADDRESSES_SECTION.NO_ADDRESSES_ADDED}
        </Box>
      ) : (
        <Box sx={{ ...styles.container }}>
          <Table aria-label='addresses table' size='small'>
            <TableHead sx={{ ...styles.head }}>
              <TableRow>
                {(isSmallScreen
                  ? config.columns.mobile
                  : config.columns.desktop
                ).map(({ heading }, index) => (
                  <StyledTableCell
                    key={index}
                    isSmallScreen={isSmallScreen}
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                    }}
                  >
                    {heading}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow
                  key={row.addressBookId}
                  sx={{
                    '&:last-child td, &:last-child th': !isSmallScreen && {
                      border: 0,
                    },
                    verticalAlign: 'top',
                  }}
                >
                  {(isSmallScreen
                    ? config.columns.mobile
                    : config.columns.desktop
                  ).map((column, columnIndex) => (
                    <StyledTableCell
                      key={`${column.path}_${columnIndex}`}
                      isSmallScreen={isSmallScreen}
                      data-testid={`${rowIndex}_addressBook.${column.path}_${columnIndex}`}
                    >
                      {actionTableButtons(
                        column,
                        row,
                        handleIsDefaultChange,
                        actionEditBtnHandleClick,
                        onSelect
                      )}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Menu
            anchorEl={actionMenuAnchorEl}
            open={!!actionMenuAnchorEl}
            onClose={actionMenuBtnHandleClose}
            MenuListProps={{ sx: { width: '124px' } }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 120, vertical: 90 }}
          >
            <StyledMenuItem disableRipple theme={theme} onClick={handleEdit}>
              {ADDRESSES_SECTION.TABLE_ACTIONS.EDIT}
            </StyledMenuItem>
            <StyledMenuItem
              disableRipple
              theme={theme}
              onClick={() => {
                setDeleteModalOpen(true);
                actionMenuBtnHandleClose();
              }}
            >
              {ADDRESSES_SECTION.TABLE_ACTIONS.DELETE}
            </StyledMenuItem>
          </Menu>
          <AddressBookDeleteModal
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onDelete={handleDelete}
            contactName={currentRow?.contactName}
          />
        </Box>
      )}
      <Drawer
        anchor='right'
        open={isSidebarOpen}
        onClose={() => {
          onEditRowHandler(false);
          dispatch(addressBooksSlice.actions.resetSelectedAddressBook());
        }}
        PaperProps={{ sx: { width: { xs: '100%', sm: 450 } } }}
      >
        <AddressBookForm
          onSidebarClose={() => {
            onEditRowHandler(false);
            dispatch(addressBooksSlice.actions.resetSelectedAddressBook());
          }}
          isEditMode={isEditMode}
          currentPage={currentPage}
          searchPageSize={searchPageSize}
        />
      </Drawer>
    </>
  );
};

AddressBookTable.propTypes = {
  rows: PropTypes.array.isRequired,
  onEditRowHandler: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  isEditMode: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
  style: PropTypes.object,
};

export default AddressBookTable;
