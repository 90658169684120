import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { referenceApis } from '../apis';
import { COUNTRIES } from '../constants';

const initialState = {
  loading: false,
  data: [],
};

const fetchCountries = createAsyncThunk('countries/fetch', async () => {
  const response = await referenceApis.fetchCountries();
  return response.data;
});

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCountries.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchCountries.rejected, state => {
      state.loading = false;
    });
  },
});

// Basic selector to get the countries data from the state
const getCountriesState = state => state.countries || [];

// Memoized selector for getting and sorting countries
export const getCountries = createSelector([getCountriesState], ({ data }) => {
  if (data.length === 0) {
    return [];
  }

  return [...data].sort((a, b) => {
    const indexA = COUNTRIES.TOP_COUNTRIES.indexOf(a.countryKey);
    const indexB = COUNTRIES.TOP_COUNTRIES.indexOf(b.countryKey);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB; // Both are top countries, sort by their order in the topCountries array
    }

    if (indexA !== -1) {
      return -1; // a is a top country, so it should come first
    }

    if (indexB !== -1) {
      return 1; // b is a top country, so it should come first
    }

    return a.countryName.localeCompare(b.countryName); // Sort the rest alphabetically by country name
  });
});

export const getCountryName = createSelector(
  [getCountriesState, (state, countryKey) => countryKey],
  ({ data }, countryCode) => {
    return data.find(c => c.countryKey === countryCode).countryName;
  }
);

export const getCountriesLoading = createSelector(
  [getCountriesState],
  ({ loading }) => loading
);

export const countriesReducer = countriesSlice.reducer;

export const countriesActions = {
  fetchCountries,
};

export const countriesSelectors = {
  getCountries,
  getCountryName,
  getCountriesLoading,
};
