import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { IS_DEV_MODE } from '../../constants/env';

// Render content only on DEV environment
// for testing and debugging
const Debugger = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const debugButton = (
    <Typography
      sx={{
        p: '0 8px',
        background: 'white',
        border: '1px solid lightGray',
        borderRadius: 2,
        cursor: 'pointer',
        width: 70,
        ml: 1.6,
        zIndex: 1,
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      Debug
    </Typography>
  );

  return IS_DEV_MODE ? (
    <>
      {debugButton}
      {isOpen ? (
        <Box
          sx={{
            border: '1px solid lightGray',
            borderRadius: 2,
            padding: 2,
            mt: -1.6,
            background: 'lightYellow',
          }}
        >
          {children}
        </Box>
      ) : null}
    </>
  ) : null;
};

export default Debugger;
