import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Error from './pages/Auth/Error.jsx';
import Loader from './features/Loader';
import { loadAppData } from './redux/appActions.js';

export default function DataProvider(props) {
  const dispatch = useDispatch();

  const load = useCallback(
    async () => await dispatch(loadAppData()).unwrap(),
    []
  );

  return (
    <Loader
      promiseFn={load}
      errorComponent={error => <Error values={{ error }} />}
    >
      {props.children}
    </Loader>
  );
}

DataProvider.propTypes = {
  children: PropTypes.node,
};
