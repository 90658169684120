import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PICKUP_MODAL } from '../../constants/strings';
import MapBox from '../MapBox';

const PickupModal = ({
  label,
  id,
  variant,
  disabled,
  selectedPickupPoint,
  initialPostcodeValue,
  onChange,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (selectedPickupPoint) {
      setModalOpen(false);
    }
  }, [selectedPickupPoint]);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'primary.contrastText',
            position: 'relative',
            m: { sm: '10% auto' },
            borderRadius: 1,
            maxWidth: { sm: 500 },
            height: {
              xs: '100%',
              sm: 'auto',
              md: '70%',
            },
            maxHeight: {
              md: '75%',
            },
            overflowY: {
              xs: 'scroll',
              sm: 'auto',
              md: 'hidden',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='h3' sx={{ p: 2 }}>
              {PICKUP_MODAL.TITLE}
            </Typography>
            <IconButton aria-label='close' sx={{ mr: 0.5 }}>
              <Close onClick={() => setModalOpen(false)} />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <MapBox
              onChange={onChange}
              isModal={true}
              initialPostcodeValue={initialPostcodeValue}
            />
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          width: '100%',
          p: 1,
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          gap: 2,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Button
          id={id}
          variant={variant}
          disabled={disabled}
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => setModalOpen(true)}
        >
          {label}
        </Button>
      </Box>
    </>
  );
};

PickupModal.propTypes = {
  addressData: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    address2: PropTypes.string,
    postCode: PropTypes.string,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selectedPickupPoint: PropTypes.object,
};

export default PickupModal;
