import { SvgIcon } from '@mui/material';

const CheckEmail = ({ styles }) => (
  <SvgIcon sx={{ width: '268px', height: '148px', ...styles }}>
    <svg
      width='268'
      height='148'
      viewBox='0 0 268 148'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2413_7429)'>
        <path
          d='M14.6608 60.6881C14.6608 60.6881 11.4831 60.4602 11.776 67.5609C12.0689 74.6617 12.7951 78.4479 12.7951 78.4479C12.7951 78.4479 12.7028 80.0232 11.4791 79.7153C10.2553 79.4075 7.95233 77.8722 6.81688 79.0196C5.63728 80.2071 6.05857 82.0303 7.61932 84.1773C9.18408 86.3243 12.438 91.1501 12.7309 91.5899C13.0198 92.0177 13.1321 92.8253 12.2655 92.7493C11.3988 92.6734 5.54099 90.6943 4.76663 93.521C3.99228 96.3477 9.33655 101.613 9.91029 102.201C10.484 102.789 10.5161 103.157 10.2834 103.512C10.0507 103.868 8.14492 103.017 6.45578 102.525C4.76663 102.029 2.7204 101.753 2.41146 103.42C2.10252 105.088 4.45368 109.969 13.2685 115.095L17.5094 117.206L21.5216 114.655C29.7788 108.674 31.6284 103.584 31.155 101.957C30.6775 100.33 28.6714 100.81 27.0425 101.469C25.4135 102.129 23.6 103.165 23.3352 102.837C23.0664 102.505 23.0623 102.133 23.5719 101.493C24.0814 100.854 28.872 95.0803 27.8168 92.3455C26.7616 89.6108 21.1325 92.1616 20.2779 92.3255C19.4233 92.4895 19.4554 91.6778 19.7001 91.218C19.9489 90.7543 22.7012 85.6246 24.0413 83.3337C25.3814 81.0427 25.6181 79.1876 24.3222 78.1241C23.0744 77.0965 20.9399 78.8517 19.7523 79.2795C18.5646 79.7073 18.3159 78.152 18.3159 78.152C18.3159 78.152 18.6609 74.3138 18.2397 67.2211C17.8184 60.1243 14.6046 60.6961 14.6046 60.6961'
          fill='#E6E6E6'
        />
        <path
          d='M14.9136 67.0491C14.9136 67.0491 14.9216 67.1011 14.9296 67.197C14.9376 67.301 14.9497 67.4449 14.9617 67.6328C14.9898 68.0246 15.0259 68.5884 15.0741 69.308C15.1704 70.7634 15.2947 72.8744 15.4392 75.4812C15.7281 80.6948 16.0691 87.8995 16.4182 95.8558C16.7672 103.816 17.0882 111.021 17.349 116.234C17.4814 118.837 17.5897 120.944 17.666 122.408C17.7021 123.131 17.7302 123.695 17.7502 124.087C17.7582 124.275 17.7622 124.419 17.7663 124.523C17.7663 124.623 17.7663 124.671 17.7663 124.671C17.7663 124.671 17.7582 124.619 17.7502 124.523C17.7422 124.419 17.7302 124.275 17.7181 124.087C17.69 123.695 17.6539 123.131 17.6058 122.412C17.5095 120.956 17.3851 118.845 17.2407 116.238C16.9518 111.025 16.6107 103.82 16.2617 95.8598C15.9126 87.9035 15.5916 80.6988 15.3308 75.4852C15.1984 72.8824 15.0901 70.7754 15.0139 69.312C14.9778 68.5884 14.9497 68.0246 14.9296 67.6328C14.9216 67.4449 14.9176 67.301 14.9136 67.197C14.9136 67.0971 14.9136 67.0491 14.9136 67.0491Z'
          fill='#E6E7E8'
        />
        <path
          d='M9.95038 81.2905C9.95038 81.2905 10.0507 81.3625 10.2152 81.5104C10.3797 81.6584 10.6084 81.8783 10.8892 82.1541C11.4469 82.7059 12.2012 83.4895 13.0157 84.3691C13.8141 85.2367 14.5323 86.0243 15.09 86.6321C15.3388 86.8999 15.5474 87.1278 15.7199 87.3158C15.8684 87.4757 15.9486 87.5676 15.9406 87.5756C15.9326 87.5836 15.8403 87.5077 15.6758 87.3597C15.5113 87.2118 15.2826 86.9919 15.0058 86.712C14.4521 86.1523 13.7098 85.3607 12.8953 84.4811C12.0929 83.6175 11.3667 82.8378 10.805 82.2341C10.5562 81.9662 10.3436 81.7423 10.1711 81.5544C10.0226 81.3945 9.94235 81.2985 9.95038 81.2905Z'
          fill='#E6E7E8'
        />
        <path
          d='M16.3579 87.4357C16.3258 87.4037 17.8785 85.7845 19.8205 83.8214C21.7664 81.8543 23.3673 80.291 23.3994 80.319C23.4314 80.351 21.8827 81.9702 19.9368 83.9333C17.9909 85.8964 16.39 87.4637 16.3579 87.4357Z'
          fill='#E6E7E8'
        />
        <path
          d='M16.3419 99.6821C16.3419 99.6821 16.4422 99.6062 16.6388 99.4942C16.8635 99.3663 17.1404 99.2144 17.4694 99.0304C18.1675 98.6386 19.1344 98.0949 20.1776 97.4552C21.2208 96.8195 22.1476 96.2117 22.8136 95.7639C23.1266 95.556 23.3874 95.3801 23.604 95.2362C23.7926 95.1122 23.8969 95.0483 23.9049 95.0603C23.913 95.0723 23.8167 95.1522 23.6401 95.2882C23.4636 95.4281 23.2028 95.624 22.8778 95.8559C22.2278 96.3277 21.309 96.9514 20.2578 97.5911C19.2107 98.2308 18.2357 98.7626 17.5215 99.1304C17.1644 99.3143 16.8715 99.4543 16.6669 99.5502C16.4623 99.6462 16.3459 99.6901 16.3419 99.6821Z'
          fill='#E6E7E8'
        />
        <path
          d='M9.31653 95.3962C9.31653 95.3962 9.42486 95.4362 9.60942 95.5322C9.79399 95.6281 10.0548 95.7761 10.3798 95.956C11.0257 96.3198 11.9124 96.8356 12.8834 97.4113C13.8584 97.987 14.733 98.5188 15.3629 98.9106C15.6799 99.1065 15.9327 99.2665 16.1052 99.3824C16.2777 99.4984 16.37 99.5623 16.366 99.5743C16.362 99.5823 16.2577 99.5343 16.0731 99.4384C15.8885 99.3424 15.6277 99.1945 15.3028 99.0146C14.6568 98.6507 13.7701 98.135 12.7991 97.5592C11.8242 96.9835 10.9495 96.4518 10.3196 96.0599C10.0026 95.864 9.74985 95.7041 9.57733 95.5882C9.4048 95.4722 9.31252 95.4082 9.31653 95.3962Z'
          fill='#E6E7E8'
        />
        <path
          d='M7.92429 106.203C7.92429 106.203 8.06071 106.259 8.29743 106.383C8.55822 106.527 8.89124 106.707 9.28443 106.927C10.115 107.391 11.2544 108.042 12.5103 108.77C13.7661 109.498 14.8975 110.165 15.712 110.653C16.0972 110.889 16.4182 111.085 16.6749 111.241C16.8996 111.381 17.02 111.465 17.016 111.477C17.012 111.485 16.8796 111.421 16.6428 111.297C16.382 111.153 16.049 110.973 15.6558 110.753C14.8253 110.289 13.6858 109.638 12.43 108.91C11.1742 108.182 10.0427 107.515 9.22826 107.027C8.84309 106.791 8.52211 106.595 8.26533 106.439C8.04065 106.299 7.92028 106.215 7.92429 106.203Z'
          fill='#E6E7E8'
        />
        <path
          d='M16.8314 111.289C16.8314 111.289 16.9558 111.193 17.2005 111.041C17.4693 110.877 17.8184 110.669 18.2317 110.417C19.1023 109.89 20.302 109.154 21.6019 108.31C22.9059 107.467 24.0654 106.671 24.9 106.091C25.2972 105.815 25.6302 105.584 25.891 105.404C26.1277 105.244 26.2601 105.16 26.2641 105.168C26.2721 105.176 26.1518 105.28 25.9271 105.456C25.7024 105.632 25.3774 105.883 24.9642 106.183C24.1457 106.787 22.9942 107.599 21.6862 108.446C20.3822 109.294 19.1705 110.014 18.2838 110.517C17.8385 110.769 17.4774 110.965 17.2246 111.097C16.9718 111.229 16.8314 111.297 16.8234 111.289H16.8314Z'
          fill='#E6E7E8'
        />
        <path
          d='M23.2028 118.582H11.4711L15.3027 147.464H19.3751L23.2028 118.582Z'
          fill='#E6E7E8'
        />
        <path
          d='M12.0809 122.132C12.0809 122.132 12.093 122.172 12.1451 122.236C12.1933 122.296 12.2856 122.376 12.43 122.436C12.7068 122.556 13.1963 122.568 13.6698 122.284C14.1432 122.024 14.5565 121.512 15.1904 121.284C15.8203 121.044 16.5987 121.088 17.349 121.328C18.1073 121.56 18.7613 121.944 19.3591 122.3C19.9569 122.652 20.5026 122.967 20.9921 123.171C21.4775 123.383 21.9028 123.479 22.1957 123.519C22.3442 123.539 22.4565 123.543 22.5368 123.543C22.613 123.543 22.6571 123.543 22.6571 123.535C22.6652 123.495 21.9951 123.503 21.0482 123.051C20.5748 122.836 20.0412 122.512 19.4514 122.152C18.8616 121.788 18.1875 121.392 17.4092 121.152C16.6388 120.904 15.8083 120.86 15.1302 121.128C14.7932 121.256 14.5083 121.456 14.2716 121.656C14.0309 121.852 13.8182 122.04 13.6016 122.176C13.1723 122.452 12.7229 122.464 12.4581 122.372C12.1893 122.28 12.101 122.12 12.0849 122.136L12.0809 122.132Z'
          fill='white'
        />
        <path
          d='M12.5103 127.449C12.5103 127.449 12.6025 127.329 12.7871 127.138C12.9757 126.954 13.2806 126.71 13.726 126.546C14.1673 126.378 14.733 126.278 15.3589 126.302C15.9888 126.322 16.6709 126.462 17.4011 126.578C18.1274 126.698 18.8496 126.754 19.4915 126.666C20.1335 126.582 20.6912 126.398 21.1365 126.198C21.5819 125.998 21.9109 125.774 22.1235 125.61C22.3402 125.446 22.4445 125.338 22.4365 125.33C22.4164 125.298 21.963 125.702 21.0804 126.074C20.639 126.254 20.0933 126.422 19.4674 126.49C18.8415 126.566 18.1514 126.51 17.4292 126.39C16.707 126.274 16.0129 126.138 15.3629 126.126C14.713 126.11 14.1272 126.23 13.6738 126.422C13.2164 126.61 12.9075 126.882 12.7309 127.09C12.6467 127.198 12.5865 127.285 12.5544 127.349C12.5183 127.413 12.5062 127.445 12.5103 127.449Z'
          fill='white'
        />
        <path
          d='M21.8747 130.296C21.8747 130.296 21.7704 130.216 21.5497 130.128C21.333 130.04 21 129.952 20.5787 129.92C20.1575 129.888 19.6559 129.912 19.1103 130.004C18.5646 130.092 17.9748 130.244 17.3609 130.372C16.7471 130.5 16.1533 130.588 15.6156 130.568C15.078 130.548 14.6005 130.432 14.2274 130.268C13.4731 129.936 13.2043 129.457 13.1762 129.489C13.1682 129.493 13.2203 129.613 13.3728 129.792C13.5253 129.972 13.7861 130.2 14.1712 130.388C14.5524 130.576 15.0459 130.712 15.6076 130.74C16.1693 130.772 16.7792 130.684 17.4011 130.556C18.023 130.424 18.6047 130.268 19.1424 130.172C19.676 130.076 20.1655 130.036 20.5747 130.048C21.3972 130.076 21.8667 130.324 21.8787 130.288L21.8747 130.296Z'
          fill='white'
        />
        <path
          d='M13.5454 134.302C13.5494 134.334 13.9627 134.043 14.6849 134.186C15.038 134.25 15.4312 134.426 15.8765 134.63C16.3179 134.834 16.8515 135.01 17.4252 135.054C18.5767 135.166 19.6039 134.794 20.2298 134.406C20.5508 134.214 20.7754 134.011 20.9279 133.871C21.0723 133.719 21.1446 133.631 21.1325 133.623C21.1085 133.595 20.7995 133.939 20.1616 134.29C19.5317 134.638 18.5446 134.97 17.4413 134.866C16.3299 134.802 15.4793 134.142 14.709 134.055C14.3318 133.999 14.0189 134.055 13.8223 134.13C13.6257 134.206 13.5414 134.294 13.5454 134.302Z'
          fill='white'
        />
        <path
          d='M14.1993 139.848C14.1993 139.868 14.5925 139.868 15.1783 139.656C15.7681 139.46 16.4983 139.008 17.3489 138.672C17.7782 138.508 18.2035 138.408 18.5967 138.412C18.9859 138.412 19.335 138.508 19.5998 138.64C20.1374 138.908 20.2939 139.288 20.326 139.264C20.334 139.264 20.3099 139.164 20.2137 139.016C20.1174 138.868 19.9408 138.676 19.664 138.52C19.3911 138.365 19.022 138.249 18.6007 138.237C18.1795 138.225 17.7261 138.325 17.2847 138.492C16.402 138.844 15.6959 139.308 15.1302 139.528C14.5725 139.768 14.1953 139.816 14.2034 139.848H14.1993Z'
          fill='white'
        />
        <path
          d='M14.7732 142.426C14.7652 142.45 15.046 142.55 15.5154 142.666C15.9849 142.782 16.6389 142.906 17.3731 142.978C18.1073 143.05 18.7734 143.054 19.2548 143.034C19.7363 143.01 20.0332 142.966 20.0292 142.942C20.0292 142.886 18.8416 142.934 17.3891 142.79C15.9367 142.65 14.7852 142.371 14.7692 142.426H14.7732Z'
          fill='white'
        />
        <path
          d='M233.408 69.777L197.731 77.8997L203.226 101.863L238.902 93.7401L233.408 69.777Z'
          fill='white'
        />
        <path
          d='M233.591 70.0279C233.591 70.0279 233.511 70.1719 233.331 70.4237C233.13 70.6996 232.865 71.0635 232.536 71.5192C231.814 72.4908 230.803 73.8462 229.563 75.5094C227.007 78.8839 223.509 83.5057 219.629 88.6234L219.549 88.7313L219.424 88.6714C219.332 88.6274 219.228 88.5794 219.127 88.5274C213.27 85.6927 207.969 83.1299 204.094 81.2548C202.2 80.3232 200.659 79.5596 199.556 79.0158C199.046 78.7559 198.637 78.548 198.328 78.3921C198.051 78.2482 197.907 78.1642 197.911 78.1562C197.915 78.1442 198.071 78.2082 198.356 78.3321C198.673 78.476 199.09 78.664 199.608 78.8999C200.723 79.4196 202.28 80.1513 204.194 81.0429C208.082 82.894 213.394 85.4289 219.268 88.2276C219.368 88.2755 219.472 88.3235 219.565 88.3715L219.36 88.4195C223.268 83.3258 226.795 78.7239 229.371 75.3655C230.646 73.7302 231.686 72.3948 232.428 71.4393C232.777 70.9995 233.058 70.6476 233.27 70.3838C233.467 70.1439 233.571 70.0239 233.583 70.0279H233.591Z'
          fill='#E6E7E8'
        />
        <path
          d='M203.407 102.113C203.327 102.073 205.257 98.175 207.717 93.4132C210.18 88.6474 212.238 84.8211 212.323 84.8651C212.403 84.9051 210.473 88.8033 208.013 93.5651C205.554 98.3269 203.492 102.157 203.407 102.113Z'
          fill='#E6E7E8'
        />
        <path
          d='M224.114 81.8625C224.171 81.7905 227.573 84.4493 231.706 87.7958C235.842 91.1462 239.144 93.917 239.088 93.9889C239.032 94.0609 235.634 91.4021 231.497 88.0557C227.364 84.7092 224.058 81.9345 224.114 81.8665V81.8625Z'
          fill='#E6E7E8'
        />
        <path
          d='M74.4795 4.61982L71.2078 18.7417L92.2324 23.5786L95.5042 9.45675L74.4795 4.61982Z'
          fill='white'
        />
        <path
          d='M95.539 9.64766C95.539 9.64766 95.4587 9.70363 95.2983 9.79159C95.1217 9.88755 94.885 10.0115 94.5961 10.1674C93.9622 10.4953 93.0755 10.9551 91.9882 11.5228C89.7614 12.6583 86.7121 14.2136 83.3338 15.9328L83.2616 15.9688L83.2135 15.9048C83.1773 15.8568 83.1332 15.8048 83.0931 15.7529C80.7179 12.7462 78.5673 10.0275 76.9945 8.0404C76.2322 7.06085 75.6103 6.26121 75.1649 5.68948C74.9603 5.4216 74.7998 5.2057 74.6755 5.04577C74.5671 4.89784 74.511 4.81788 74.515 4.81388C74.519 4.80988 74.5872 4.88185 74.7076 5.02178C74.84 5.17771 75.0125 5.38562 75.2251 5.6455C75.6825 6.20924 76.3205 6.99288 77.1028 7.95643C78.6877 9.93552 80.8503 12.6423 83.2456 15.6329C83.2857 15.6849 83.3298 15.7369 83.3659 15.7848L83.2456 15.7569C86.6319 14.0576 89.6932 12.5183 91.928 11.3989C93.0273 10.8591 93.9221 10.4153 94.564 10.0994C94.8649 9.95552 95.1017 9.84357 95.2862 9.75561C95.4507 9.67964 95.539 9.63966 95.543 9.64766H95.539Z'
          fill='#E6E7E8'
        />
        <path
          d='M71.2409 18.9273C71.2088 18.8833 73.2389 17.3121 75.7747 15.4169C78.3104 13.5218 80.3927 12.0185 80.4248 12.0625C80.4569 12.1064 78.4267 13.6777 75.891 15.5729C73.3553 17.468 71.273 18.9713 71.2409 18.9273Z'
          fill='#E6E7E8'
        />
        <path
          d='M87.4543 13.4938C87.5025 13.4699 88.6219 15.7528 89.9499 18.5875C91.278 21.4262 92.3172 23.7411 92.269 23.7651C92.2209 23.7891 91.1014 21.5062 89.7734 18.6715C88.4454 15.8368 87.4062 13.5178 87.4584 13.4938H87.4543Z'
          fill='#E6E7E8'
        />
        <path
          d='M207.761 8.16196L192.4 14.7385L196.849 25.0573L212.21 18.4808L207.761 8.16196Z'
          fill='white'
        />
        <path
          d='M207.857 8.24829C207.857 8.24829 207.833 8.32026 207.773 8.4482C207.705 8.58813 207.616 8.77205 207.508 9.00394C207.263 9.49972 206.922 10.1914 206.501 11.039C205.63 12.7662 204.439 15.1291 203.123 17.7479L203.095 17.8039L203.034 17.7879C202.99 17.7759 202.938 17.7599 202.89 17.7479C200.025 16.9563 197.429 16.2366 195.532 15.7129C194.605 15.449 193.846 15.2331 193.309 15.0812C193.06 15.0052 192.859 14.9452 192.707 14.9012C192.571 14.8573 192.498 14.8333 192.498 14.8293C192.498 14.8253 192.575 14.8413 192.715 14.8732C192.867 14.9132 193.072 14.9612 193.325 15.0252C193.87 15.1691 194.629 15.369 195.564 15.6129C197.461 16.1247 200.061 16.8283 202.93 17.604C202.978 17.616 203.03 17.632 203.075 17.644L202.986 17.684C204.322 15.0732 205.526 12.7182 206.405 10.995C206.842 10.1554 207.199 9.47173 207.456 8.97995C207.576 8.75206 207.672 8.57214 207.749 8.43621C207.817 8.31226 207.853 8.24829 207.861 8.25229L207.857 8.24829Z'
          fill='#E6E7E8'
        />
        <path
          d='M196.944 25.1445C196.904 25.1325 197.449 23.2214 198.16 20.8825C198.874 18.5395 199.484 16.6484 199.524 16.6644C199.564 16.6764 199.018 18.5835 198.304 20.9264C197.59 23.2694 196.98 25.1565 196.94 25.1445H196.944Z'
          fill='#E6E7E8'
        />
        <path
          d='M204.575 14.3455C204.595 14.3095 206.34 15.2251 208.475 16.3886C210.609 17.556 212.323 18.5276 212.303 18.5636C212.282 18.5996 210.537 17.684 208.403 16.5205C206.268 15.357 204.555 14.3815 204.575 14.3455Z'
          fill='#E6E7E8'
        />
        <path
          d='M228.095 112.524C226.458 111.501 224.584 110.857 222.658 110.661C221.771 110.569 220.8 110.597 220.086 111.133C219.934 111.245 219.789 111.417 219.821 111.601C219.845 111.736 219.962 111.836 220.074 111.92C222.285 113.604 225.631 113.816 228.095 112.524Z'
          fill='#E5444B'
        />
        <path
          d='M227.954 112.372C226.722 111.417 225.487 110.453 224.443 109.294C223.4 108.134 222.554 106.763 222.241 105.235C222.144 104.772 222.116 104.244 222.413 103.88C225.21 106.059 227.408 108.874 227.95 112.372'
          fill='#E5444B'
        />
        <path
          d='M228.066 112.504C227.854 110.977 227.782 109.41 228.131 107.91C228.484 106.411 229.302 104.976 230.586 104.12C231.072 105.248 230.863 106.543 230.522 107.722C229.996 109.542 229.17 111.273 228.087 112.832'
          fill='#E5444B'
        />
        <path
          d='M230.811 117.402C229.146 117.602 227.693 118.477 226.454 119.601C225.21 120.724 224.327 122.236 223.962 123.867C225.595 123.767 226.979 122.619 227.986 121.332C228.989 120.045 229.687 118.633 230.791 117.43'
          fill='#E5444B'
        />
        <path
          d='M231.405 118.186C230.065 114.895 230.55 110.925 232.644 108.05C233.591 111.413 233.246 115.211 231.405 118.186Z'
          fill='#E5444B'
        />
        <path
          d='M235.028 122.488C233.515 122.396 231.978 122.751 230.666 123.507C229.354 124.259 228.271 125.402 227.593 126.754C227.501 126.938 227.42 127.169 227.545 127.333C227.653 127.477 227.858 127.489 228.038 127.477C229.503 127.381 230.803 126.506 231.874 125.506C232.945 124.507 233.88 123.351 235.064 122.488'
          fill='#E5444B'
        />
        <path
          d='M234.915 122.252C234.137 119.765 234.089 117.058 234.779 114.547C235.052 113.556 235.509 112.512 236.428 112.044C236.508 112.004 236.597 111.968 236.681 112C236.757 112.028 236.805 112.108 236.841 112.184C237.17 112.868 237.263 113.64 237.23 114.395C237.198 115.151 237.038 115.895 236.881 116.634C236.592 118.002 236.436 119.473 235.686 120.652C235.505 120.932 235.349 121.236 235.208 121.536C235.064 121.836 235.064 121.94 234.935 122.18'
          fill='#E5444B'
        />
        <path
          d='M244.509 123.679C243.798 120.984 244.553 117.718 246.258 115.511C246.382 115.347 246.551 115.175 246.755 115.191C247.068 115.219 247.149 115.635 247.137 115.951C247.012 118.705 246.093 121.416 244.513 123.679'
          fill='#E5444B'
        />
        <path
          d='M245.375 124.267C248.661 125.118 252.429 124.291 255.161 122.284C253.54 121.464 251.634 121.232 249.865 121.636C248.096 122.04 246.479 122.828 245.375 124.267Z'
          fill='#E5444B'
        />
        <path
          d='M246.101 122.472C247.943 119.481 251.47 117.53 254.984 117.354C254.579 118.777 253.404 119.885 252.08 120.553C250.756 121.22 249.275 121.512 247.815 121.796C247.249 121.904 246.471 122.028 246.101 122.472Z'
          fill='#E5444B'
        />
        <path
          d='M244.693 113.767C245.191 112.068 245.692 110.349 245.829 108.586C245.965 106.823 245.712 104.976 244.793 103.46C243.076 106.775 242.9 110.645 244.641 113.947'
          fill='#E5444B'
        />
        <path
          d='M250.479 110.853C252.489 107.214 256.589 104.304 260.738 103.896C259.137 107.866 254.772 110.937 250.479 110.853Z'
          fill='#E5444B'
        />
        <path
          d='M249.211 111.565C252.208 112.02 254.764 113.928 257.564 115.083C258.162 115.331 258.808 115.527 259.454 115.443C260.096 115.359 260.73 114.931 260.874 114.299C260.995 113.776 260.754 113.22 260.377 112.836C260 112.452 259.502 112.212 259.005 112.008C255.955 110.741 252.681 110.713 249.468 111.481'
          fill='#E5444B'
        />
        <path
          d='M247.478 113.66C248.156 113.852 248.388 114.203 248.926 114.659C250.747 116.206 252.934 117.326 255.261 117.902C255.502 117.962 255.819 117.986 255.939 117.766C256.044 117.574 255.911 117.346 255.775 117.174C254.8 115.959 253.412 115.131 251.951 114.587C250.491 114.043 249.223 113.392 247.478 113.532'
          fill='#E5444B'
        />
        <path
          d='M245.52 101.009C247.955 103.276 251.39 104.44 254.708 104.124C254.86 104.108 255.025 104.084 255.125 103.976C255.358 103.724 255.077 103.336 254.808 103.128C252.108 101.029 248.834 100.158 245.516 101.009'
          fill='#E5444B'
        />
        <path
          d='M247.843 100.162C251.064 97.7549 255.482 97.0272 259.31 98.2746C257.865 99.694 255.863 100.458 253.845 100.666C251.827 100.873 249.809 100.654 247.843 100.162Z'
          fill='#E5444B'
        />
        <path
          d='M246.92 94.3484C246.655 91.6617 246.828 89.5386 246.639 86.8399C246.623 86.624 246.579 86.3641 246.374 86.2881C246.093 86.1802 245.857 86.524 245.764 86.8119C244.962 89.2987 245.399 92.1534 246.916 94.2885'
          fill='#E5444B'
        />
        <path
          d='M246.727 94.6443C248.557 93.5168 249.925 92.0455 251.193 90.3183C252.076 89.1148 252.818 87.6755 252.617 86.1962C251.377 86.1722 250.202 86.8519 249.395 87.7914C248.589 88.731 248.112 89.9025 247.754 91.0859C247.397 92.2694 247.145 93.4848 246.727 94.6443Z'
          fill='#E5444B'
        />
        <path
          d='M251.113 93.429C255.125 91.9497 258.868 89.7507 262.106 86.968C260.794 86.4402 259.29 86.7561 257.978 87.2838C254.976 88.4913 252.529 90.5264 251.057 93.397'
          fill='#E5444B'
        />
        <path
          d='M247.959 96.1157C249.989 96.3356 252.052 96.4795 254.062 96.1437C256.04 95.8158 257.909 95.0322 259.759 94.2566C259.911 94.1926 260.08 94.1166 260.144 93.9647C260.216 93.8008 260.14 93.6009 260.004 93.4769C259.871 93.357 259.691 93.297 259.518 93.249C257.508 92.6893 255.338 92.9492 253.36 93.6168C251.381 94.2805 249.761 95.0722 247.951 96.1117'
          fill='#E5444B'
        />
        <path
          d='M236.372 101.101C234.988 98.3147 235.076 95.0682 236.271 92.1975C237.114 93.4769 237.214 95.1042 237.062 96.6235C236.909 98.1468 236.444 99.7101 236.412 101.241'
          fill='#E5444B'
        />
        <path
          d='M230.839 98.3187C227.38 98.2547 223.926 99.722 221.579 102.249C223.304 102.521 225.081 102.077 226.65 101.313C228.219 100.55 229.615 99.4781 230.995 98.4146'
          fill='#E5444B'
        />
        <path
          d='M227.124 97.0794C225.434 96.9434 223.806 96.7355 222.181 97.2313C220.556 97.7271 219.075 98.8545 218.413 100.414C219.994 100.59 221.599 100.198 223.047 99.5422C224.496 98.8865 225.816 97.9789 227.124 97.0794Z'
          fill='#E5444B'
        />
        <path
          d='M232.628 97.1393C231.108 96.1477 229.543 95.1362 227.774 94.7244C226.004 94.3126 223.95 94.6164 222.714 95.9478C224.147 96.7874 225.888 96.8834 227.549 96.8834C229.21 96.8834 231.248 96.8634 232.817 97.4151'
          fill='#E5444B'
        />
        <path
          d='M232.636 97.2192C230.036 94.6723 229.049 90.1024 230.097 86.624C232.143 89.7186 233.058 93.5368 232.636 97.2192Z'
          fill='#E5444B'
        />
        <path
          d='M239.79 90.0065C240.28 88.871 240.625 87.6756 241.066 86.5201C241.507 85.3646 242.053 84.2251 242.884 83.3056C243.714 82.386 244.866 81.7023 246.105 81.6423C245.331 85.1447 242.996 88.2713 239.854 90.0225'
          fill='#E5444B'
        />
        <path
          d='M238.494 91.5097C237.684 90.3982 236.745 88.8869 235.646 88.0553C234.546 87.2237 233.11 86.7199 231.77 87.0398C233.138 89.4747 235.718 91.1899 238.498 91.5097'
          fill='#E5444B'
        />
        <path
          d='M238.755 90.0985C238.221 88.7511 237.688 87.3917 237.471 85.9564C237.254 84.5251 237.387 82.9938 238.121 81.7463C239.549 84.4371 240.007 87.3238 238.827 90.1265'
          fill='#E5444B'
        />
        <path
          d='M239.802 81.7502C240.613 79.0834 243.06 77.1203 245.704 76.2207C246.037 76.1088 246.39 76.0128 246.739 76.0648C247.088 76.1168 247.429 76.3567 247.502 76.7005C247.598 77.1523 247.221 77.5601 246.868 77.86C244.809 79.6152 242.386 80.9466 239.798 81.7502'
          fill='#E5444B'
        />
        <path
          d='M239.83 79.5673C239.935 77.8481 240.039 76.1049 240.601 74.4776C241.162 72.8504 242.242 71.3231 243.814 70.6074C244.083 72.3426 243.313 74.0698 242.386 75.5651C241.459 77.0605 240.34 78.4678 239.786 80.135'
          fill='#E5444B'
        />
        <path
          d='M239.79 80.7508C239.12 78.8077 239.052 76.3408 237.884 74.6456C237.07 73.4621 235.762 72.6545 235.06 71.4031C234.594 72.5745 234.735 73.9219 235.224 75.0854C235.714 76.2489 236.52 77.2484 237.383 78.168C238.245 79.0875 238.988 79.7792 239.79 80.7508Z'
          fill='#E5444B'
        />
        <path
          d='M239.802 137.968C239.75 137.968 239.706 125.006 239.702 109.022C239.702 93.033 239.738 80.075 239.79 80.075C239.842 80.075 239.886 93.033 239.89 109.022C239.89 125.006 239.854 137.968 239.802 137.968Z'
          fill='#414042'
        />
        <path
          d='M251.169 99.1462C251.169 99.1462 250.996 99.2222 250.679 99.3302C250.326 99.4501 249.877 99.602 249.335 99.782C248.2 100.166 246.643 100.726 244.95 101.417C243.273 102.133 241.696 102.717 240.793 103.492C240.344 103.876 240.087 104.292 239.959 104.592C239.83 104.896 239.806 105.084 239.79 105.08C239.782 105.08 239.786 105.032 239.794 104.944C239.81 104.856 239.83 104.728 239.89 104.568C239.999 104.248 240.252 103.804 240.705 103.396C241.158 102.984 241.788 102.617 242.506 102.281C243.229 101.945 244.027 101.597 244.874 101.249C246.571 100.558 248.14 100.018 249.291 99.662C249.865 99.4821 250.334 99.3541 250.655 99.2662C250.98 99.1822 251.161 99.1422 251.165 99.1542L251.169 99.1462Z'
          fill='#414042'
        />
        <path
          d='M254.892 90.0264C254.892 90.0264 254.848 90.0824 254.756 90.1703C254.643 90.2743 254.507 90.4022 254.339 90.5622C253.949 90.914 253.424 91.3898 252.782 91.9735C251.438 93.169 249.612 94.7842 247.594 96.5754C245.568 98.3706 243.819 100.086 242.607 101.377C242.001 102.021 241.524 102.557 241.195 102.928C241.042 103.1 240.918 103.244 240.817 103.356C240.729 103.452 240.681 103.504 240.677 103.5C240.673 103.496 240.709 103.44 240.789 103.332C240.87 103.228 240.982 103.072 241.138 102.881C241.447 102.493 241.913 101.945 242.507 101.285C243.698 99.9658 245.435 98.2306 247.466 96.4315C249.496 94.6403 251.345 93.029 252.69 91.8696C253.348 91.3058 253.889 90.842 254.286 90.5022C254.463 90.3543 254.611 90.2343 254.728 90.1384C254.828 90.0584 254.884 90.0144 254.888 90.0224L254.892 90.0264Z'
          fill='#414042'
        />
        <path
          d='M245.411 98.3986C245.359 98.3746 245.78 97.403 246.202 96.1716C246.627 94.9402 246.828 93.9046 246.88 93.9126C246.928 93.9166 246.808 94.9882 246.378 96.2316C246.166 96.8553 245.933 97.399 245.748 97.7909C245.564 98.1787 245.431 98.4106 245.407 98.3986H245.411Z'
          fill='#414042'
        />
        <path
          d='M239.814 106.243C239.814 106.243 239.694 106.123 239.501 105.883C239.289 105.611 239.02 105.271 238.703 104.864C238.033 104 237.102 102.805 236.063 101.501C235.024 100.194 234.065 99.0223 233.375 98.1747C233.05 97.7709 232.773 97.435 232.556 97.1672C232.364 96.9273 232.263 96.7913 232.275 96.7833C232.283 96.7753 232.404 96.8953 232.612 97.1192C232.821 97.3431 233.114 97.6749 233.475 98.0867C234.193 98.9144 235.168 100.074 236.211 101.381C237.254 102.689 238.165 103.896 238.811 104.784C239.132 105.227 239.389 105.587 239.561 105.839C239.734 106.091 239.826 106.235 239.814 106.243Z'
          fill='#414042'
        />
        <path
          d='M237.323 103.057C237.291 103.089 236.629 102.305 236.279 101.109C236.023 100.266 236.011 99.4982 236.043 99.1463L236.127 99.1543C236.091 99.3063 236.063 99.3822 236.047 99.3822C236.031 99.3822 236.031 99.2943 236.047 99.1423L236.131 99.1503C236.163 99.4982 236.215 100.242 236.464 101.061C236.801 102.217 237.379 103.033 237.327 103.065L237.323 103.057Z'
          fill='#414042'
        />
        <path
          d='M234.105 99.0223C234.081 99.0503 233.868 98.7784 233.375 98.5985C232.889 98.4106 232.155 98.3706 231.352 98.4386C230.546 98.4986 229.828 98.6385 229.314 98.7664C228.801 98.8944 228.484 98.9943 228.476 98.9663C228.468 98.9424 228.765 98.7984 229.278 98.6385C229.788 98.4786 230.518 98.3106 231.336 98.2507C232.151 98.1827 232.921 98.2427 233.423 98.4746C233.932 98.6985 234.117 99.0143 234.101 99.0183L234.105 99.0223Z'
          fill='#414042'
        />
        <path
          d='M233.343 98.0469C233.326 98.0748 232.917 97.8789 232.227 97.643C231.537 97.4072 230.55 97.1593 229.443 97.0713C228.331 96.9873 227.32 97.0873 226.602 97.2152C225.884 97.3432 225.447 97.4791 225.438 97.4471C225.438 97.4351 225.539 97.3912 225.735 97.3232C225.928 97.2512 226.217 97.1673 226.578 97.0873C227.3 96.9234 228.327 96.8034 229.459 96.8874C230.59 96.9754 231.589 97.2512 232.275 97.5231C232.62 97.659 232.893 97.783 233.074 97.8829C233.254 97.9829 233.355 98.0389 233.347 98.0509L233.343 98.0469Z'
          fill='#414042'
        />
        <path
          d='M239.79 93.9128C239.738 93.9248 239.377 92.6613 238.976 91.0941C238.575 89.5228 238.294 88.2394 238.342 88.2274C238.394 88.2154 238.755 89.4748 239.156 91.0461C239.558 92.6174 239.838 93.8968 239.79 93.9128Z'
          fill='#414042'
        />
        <path
          d='M239.842 93.9127C239.786 93.9327 239.553 93.089 238.94 92.2254C238.342 91.3458 237.583 90.902 237.611 90.8581C237.619 90.8381 237.832 90.914 238.125 91.1219C238.418 91.3259 238.783 91.6697 239.096 92.1175C239.73 93.0131 239.89 93.9087 239.846 93.9127H239.842Z'
          fill='#414042'
        />
        <path
          d='M239.802 131.06C239.782 131.06 239.742 130.748 239.598 130.196C239.453 129.644 239.18 128.865 238.727 127.953C237.828 126.122 236.191 123.803 234.117 121.48C233.086 120.309 232.087 119.201 231.24 118.142C230.39 117.086 229.691 116.079 229.182 115.187C228.672 114.295 228.339 113.532 228.143 112.992C228.046 112.72 227.978 112.512 227.938 112.364C227.898 112.22 227.878 112.14 227.886 112.14C227.902 112.136 227.998 112.436 228.211 112.964C228.424 113.492 228.773 114.243 229.294 115.115C229.816 115.991 230.518 116.982 231.373 118.03C232.223 119.077 233.222 120.177 234.257 121.352C236.34 123.683 237.973 126.03 238.847 127.893C239.289 128.821 239.545 129.616 239.666 130.176C239.726 130.456 239.766 130.676 239.782 130.828C239.802 130.976 239.806 131.056 239.802 131.056V131.06Z'
          fill='#414042'
        />
        <path
          d='M250.074 119.501C250.074 119.501 249.885 119.613 249.536 119.801C249.191 119.993 248.701 120.292 248.14 120.72C247.578 121.148 246.952 121.712 246.306 122.388C245.664 123.063 244.998 123.851 244.324 124.694C242.972 126.386 241.704 127.881 240.95 129.076C240.565 129.668 240.284 130.168 240.103 130.52C239.919 130.868 239.822 131.068 239.806 131.06C239.802 131.06 239.818 131.004 239.854 130.908C239.895 130.812 239.951 130.668 240.035 130.488C240.199 130.124 240.464 129.612 240.837 129.008C241.572 127.785 242.827 126.27 244.18 124.578C244.858 123.735 245.528 122.947 246.186 122.268C246.844 121.592 247.486 121.032 248.063 120.616C248.641 120.197 249.147 119.913 249.504 119.737C249.68 119.649 249.829 119.593 249.925 119.553C250.021 119.513 250.074 119.497 250.078 119.501H250.074Z'
          fill='#414042'
        />
        <path
          d='M250.274 123.251C250.274 123.279 249.841 123.331 249.135 123.415C248.432 123.507 247.457 123.623 246.434 123.943C245.415 124.275 244.569 124.778 244.019 125.206C243.461 125.634 243.201 125.99 243.172 125.97C243.164 125.962 243.217 125.866 243.341 125.71C243.461 125.55 243.662 125.338 243.935 125.106C244.476 124.639 245.331 124.107 246.378 123.767C247.425 123.435 248.412 123.339 249.123 123.283C249.833 123.231 250.27 123.227 250.274 123.251Z'
          fill='#414042'
        />
        <path
          d='M243.509 125.558C243.449 125.526 243.963 124.742 244.36 123.695C244.733 122.635 244.821 121.708 244.886 121.716C244.91 121.716 244.91 121.952 244.866 122.324C244.821 122.695 244.733 123.211 244.533 123.755C244.332 124.299 244.071 124.754 243.875 125.07C243.67 125.386 243.53 125.57 243.505 125.554L243.509 125.558Z'
          fill='#414042'
        />
        <path
          d='M253.4 109.43C253.4 109.43 253.339 109.47 253.219 109.526C253.079 109.59 252.902 109.67 252.685 109.77C252.453 109.869 252.168 109.997 251.847 110.165C251.526 110.329 251.149 110.501 250.76 110.733C250.563 110.845 250.354 110.961 250.138 111.085C249.921 111.209 249.708 111.353 249.484 111.497C249.026 111.773 248.569 112.112 248.079 112.46C247.12 113.184 246.113 114.04 245.146 115.031C244.188 116.031 243.361 117.062 242.667 118.042C242.334 118.541 242.009 119.009 241.748 119.473C241.616 119.705 241.475 119.921 241.359 120.141C241.243 120.361 241.134 120.569 241.026 120.772C240.805 121.168 240.649 121.548 240.492 121.876C240.336 122.2 240.215 122.488 240.123 122.724C240.031 122.943 239.959 123.123 239.898 123.263C239.846 123.387 239.814 123.451 239.806 123.447C239.798 123.447 239.818 123.379 239.862 123.251C239.915 123.103 239.975 122.923 240.055 122.696C240.135 122.456 240.243 122.16 240.396 121.832C240.54 121.5 240.693 121.116 240.906 120.713C241.01 120.509 241.118 120.293 241.226 120.073C241.339 119.853 241.479 119.629 241.608 119.397C241.864 118.925 242.189 118.45 242.518 117.946C243.209 116.954 244.039 115.911 245.006 114.903C245.981 113.908 247 113.044 247.971 112.324C248.469 111.977 248.93 111.641 249.395 111.369C249.624 111.229 249.841 111.085 250.061 110.965C250.282 110.845 250.491 110.733 250.691 110.621C251.089 110.393 251.47 110.229 251.799 110.073C252.124 109.913 252.417 109.798 252.653 109.706C252.874 109.622 253.059 109.55 253.203 109.494C253.327 109.446 253.396 109.426 253.4 109.434V109.43Z'
          fill='#414042'
        />
        <path
          d='M245.271 114.859C245.271 114.859 245.343 114.543 245.716 114.195C246.081 113.851 246.743 113.532 247.518 113.508C248.288 113.488 248.958 113.704 249.411 113.875C249.869 114.055 250.138 114.199 250.13 114.223C250.118 114.251 249.833 114.147 249.371 114.003C248.91 113.863 248.256 113.676 247.526 113.696C246.8 113.72 246.17 113.995 245.805 114.295C245.431 114.595 245.299 114.875 245.271 114.859Z'
          fill='#414042'
        />
        <path
          d='M244.055 116.107C244.003 116.079 244.46 115.407 244.565 114.447C244.685 113.488 244.392 112.732 244.448 112.716C244.468 112.708 244.573 112.884 244.661 113.2C244.749 113.512 244.809 113.971 244.749 114.471C244.689 114.971 244.529 115.403 244.368 115.687C244.208 115.975 244.067 116.123 244.051 116.107H244.055Z'
          fill='#414042'
        />
        <path
          d='M230.819 132.915C231.1 137.869 232.596 142.906 234.899 148H244.918C247.116 142.958 248.509 137.928 248.757 132.915H230.819Z'
          fill='#808080'
        />
        <path
          d='M247.137 140.931C247.137 140.983 243.838 141.023 239.77 141.023C235.702 141.023 232.404 140.983 232.404 140.931C232.404 140.879 235.702 140.839 239.77 140.839C243.838 140.839 247.137 140.879 247.137 140.931Z'
          fill='white'
        />
        <path
          d='M246.912 142.726C246.912 142.778 243.818 142.818 240.007 142.818C236.195 142.818 233.102 142.778 233.102 142.726C233.102 142.674 236.195 142.634 240.007 142.634C243.818 142.634 246.912 142.674 246.912 142.726Z'
          fill='white'
        />
        <path
          d='M202.545 43.9758H59.9587C56.7302 43.9758 54.1129 46.5839 54.1129 49.8012V139.816C54.1129 143.033 56.7302 145.641 59.9587 145.641H202.545C205.773 145.641 208.391 143.033 208.391 139.816V49.8012C208.391 46.5839 205.773 43.9758 202.545 43.9758Z'
          fill='#808080'
        />
        <path
          d='M48.0825 139.072H215.227V140.851C215.227 144.777 212.03 147.968 208.086 147.968H55.2243C51.2843 147.968 48.0825 144.781 48.0825 140.851V139.072Z'
          fill='#808080'
        />
        <path
          d='M203.817 48.0139H58.2535V133.866H203.817V48.0139Z'
          fill='#414042'
        />
        <path
          d='M116.041 138.121L118.116 141.407H147.305L149.23 138.121H116.041Z'
          fill='#414042'
        />
        <path
          d='M62.5666 50.9207H199.804C200.531 50.9207 201.117 51.5084 201.117 52.2281V132.283C201.117 133.007 200.527 133.591 199.804 133.591H62.5666C61.8404 133.591 61.2546 133.003 61.2546 132.283V52.2281C61.2546 51.5044 61.8444 50.9207 62.5666 50.9207Z'
          fill='#E6E6E6'
        />
        <path
          d='M200.968 133.715H61.1022L61.2546 57.0979H200.968V133.715Z'
          fill='white'
        />
        <path
          d='M72.7255 54.1152C72.7255 54.7309 72.224 55.2266 71.6101 55.2266C70.9963 55.2266 70.4948 54.7269 70.4948 54.1152C70.4948 53.5034 70.9963 53.0037 71.6101 53.0037C72.224 53.0037 72.7255 53.5034 72.7255 54.1152Z'
          fill='#414042'
        />
        <path
          d='M65.953 54.1152C65.953 54.7309 65.4514 55.2266 64.8376 55.2266C64.2237 55.2266 63.7222 54.7269 63.7222 54.1152C63.7222 53.5034 64.2237 53.0037 64.8376 53.0037C65.4514 53.0037 65.953 53.5034 65.953 54.1152Z'
          fill='#414042'
        />
        <path
          d='M69.2028 54.1152C69.2028 54.7309 68.7013 55.2266 68.0874 55.2266C67.4736 55.2266 66.972 54.7269 66.972 54.1152C66.972 53.5034 67.4736 53.0037 68.0874 53.0037C68.7013 53.0037 69.2028 53.5034 69.2028 54.1152Z'
          fill='#414042'
        />
        <path
          d='M207.063 138.12C207.063 138.176 172.822 138.22 130.59 138.22C88.3572 138.22 54.1089 138.176 54.1089 138.12C54.1089 138.064 88.3451 138.021 130.59 138.021C172.834 138.021 207.063 138.064 207.063 138.12Z'
          fill='#414042'
        />
        <path
          d='M268 147.628C268 147.684 208.001 147.732 134.008 147.732C60.0147 147.732 0 147.684 0 147.628C0 147.572 59.9866 147.524 134.008 147.524C208.029 147.524 268 147.572 268 147.628Z'
          fill='#414042'
        />
        <path
          d='M144.736 70.5713C144.792 70.5793 144.816 70.8032 144.792 71.0791C144.764 71.355 144.696 71.5709 144.64 71.5669C144.584 71.5629 144.56 71.335 144.584 71.0591C144.612 70.7832 144.68 70.5673 144.736 70.5713Z'
          fill='#414042'
        />
        <path
          d='M145.27 66.6771C145.326 66.6851 145.306 67.1289 145.226 67.6647C145.146 68.2004 145.029 68.6282 144.973 68.6202C144.917 68.6122 144.937 68.1724 145.017 67.6327C145.098 67.0969 145.214 66.6691 145.27 66.6771Z'
          fill='#414042'
        />
        <path
          d='M146.057 62.8269C146.113 62.8389 146.065 63.2787 145.944 63.8104C145.828 64.3382 145.683 64.758 145.627 64.746C145.571 64.734 145.619 64.2942 145.74 63.7624C145.856 63.2307 146 62.8109 146.057 62.8269Z'
          fill='#414042'
        />
        <path
          d='M147.128 59.0446C147.184 59.0606 147.1 59.4964 146.943 60.0162C146.787 60.536 146.614 60.9438 146.558 60.9278C146.502 60.9118 146.586 60.476 146.743 59.9562C146.899 59.4365 147.072 59.0287 147.128 59.0446Z'
          fill='#414042'
        />
        <path
          d='M148.504 55.3623C148.556 55.3863 148.436 55.8101 148.235 56.3139C148.034 56.8176 147.826 57.2095 147.774 57.1895C147.721 57.1695 147.838 56.7417 148.042 56.2379C148.243 55.7301 148.452 55.3423 148.504 55.3623Z'
          fill='#414042'
        />
        <path
          d='M150.213 51.824C150.265 51.852 150.105 52.2638 149.856 52.7436C149.607 53.2233 149.367 53.5952 149.314 53.5712C149.262 53.5472 149.423 53.1354 149.671 52.6516C149.92 52.1678 150.161 51.796 150.213 51.824Z'
          fill='#414042'
        />
        <path
          d='M152.275 48.4735C152.324 48.5055 152.115 48.8973 151.814 49.3451C151.525 49.8049 151.248 50.1488 151.2 50.1208C151.152 50.0928 151.349 49.693 151.641 49.2332C151.946 48.7814 152.231 48.4415 152.279 48.4735H152.275Z'
          fill='#414042'
        />
        <path
          d='M154.707 45.379C154.751 45.419 154.502 45.7868 154.157 46.2026C153.812 46.6184 153.491 46.9263 153.447 46.8903C153.403 46.8543 153.648 46.4865 153.997 46.0667C154.346 45.6469 154.663 45.339 154.707 45.379Z'
          fill='#414042'
        />
        <path
          d='M157.483 42.5921C157.519 42.6361 157.235 42.972 156.837 43.3438C156.444 43.7156 156.091 43.9835 156.051 43.9435C156.011 43.9035 156.3 43.5677 156.697 43.1919C157.094 42.816 157.447 42.5481 157.483 42.5921Z'
          fill='#414042'
        />
        <path
          d='M160.589 40.1694C160.621 40.2174 160.292 40.5132 159.854 40.8331C159.417 41.153 159.032 41.3769 158.996 41.3289C158.96 41.2849 159.289 40.985 159.73 40.6652C160.171 40.3413 160.557 40.1214 160.589 40.1694Z'
          fill='#414042'
        />
        <path
          d='M163.991 38.1781C164.015 38.2301 163.65 38.482 163.173 38.7379C162.695 38.9938 162.286 39.1657 162.254 39.1137C162.226 39.0657 162.591 38.8138 163.072 38.554C163.554 38.2941 163.963 38.1262 163.991 38.1781Z'
          fill='#414042'
        />
        <path
          d='M167.642 36.699C167.658 36.7549 167.261 36.9469 166.752 37.1308C166.242 37.3147 165.813 37.4226 165.789 37.3667C165.765 37.3147 166.166 37.1188 166.679 36.9349C167.193 36.7469 167.626 36.643 167.642 36.699Z'
          fill='#414042'
        />
        <path
          d='M171.478 35.8114C171.486 35.8674 171.061 35.9833 170.527 36.0753C169.997 36.1872 169.556 36.2312 169.544 36.1753C169.532 36.1193 169.953 35.9833 170.491 35.8714C171.028 35.7794 171.474 35.7554 171.482 35.8114H171.478Z'
          fill='#414042'
        />
        <path
          d='M175.414 35.5914C175.414 35.6474 174.972 35.6994 174.431 35.7074C173.889 35.7154 173.448 35.6714 173.444 35.6154C173.444 35.5594 173.881 35.5075 174.427 35.4995C174.972 35.4915 175.418 35.5355 175.414 35.5914Z'
          fill='#414042'
        />
        <path
          d='M179.33 36.0511C179.318 36.1071 178.872 36.0831 178.339 35.9992C177.805 35.9112 177.376 35.7953 177.38 35.7393C177.388 35.6833 177.829 35.7073 178.371 35.7913C178.913 35.8792 179.338 35.9952 179.326 36.0511H179.33Z'
          fill='#414042'
        />
        <path
          d='M183.105 37.1746C183.085 37.2266 182.652 37.1266 182.138 36.9507C181.625 36.7748 181.224 36.5869 181.24 36.5309C181.256 36.4749 181.689 36.5749 182.207 36.7548C182.724 36.9347 183.125 37.1227 183.105 37.1746Z'
          fill='#414042'
        />
        <path
          d='M186.616 38.958C186.584 39.0059 186.179 38.83 185.709 38.5621C185.24 38.2943 184.879 38.0344 184.903 37.9864C184.927 37.9384 185.336 38.1143 185.81 38.3822C186.287 38.6541 186.644 38.91 186.616 38.958Z'
          fill='#414042'
        />
        <path
          d='M189.705 41.3928C189.665 41.4327 189.3 41.1769 188.895 40.821C188.49 40.4652 188.189 40.1413 188.221 40.0934C188.257 40.0494 188.618 40.3013 189.031 40.6651C189.445 41.0249 189.745 41.3488 189.705 41.3928Z'
          fill='#414042'
        />
        <path
          d='M191.655 43.6317C191.607 43.6637 191.435 43.5158 191.266 43.2999C191.098 43.084 190.997 42.8761 191.041 42.8401C191.086 42.8041 191.262 42.9521 191.431 43.172C191.603 43.3919 191.703 43.5958 191.655 43.6317Z'
          fill='#414042'
        />
        <path
          d='M210.208 67.7207C210.184 67.7727 209.758 67.6288 209.253 67.3929C208.747 67.161 208.362 66.9291 208.386 66.8771C208.41 66.8251 208.84 66.9731 209.341 67.205C209.843 67.4368 210.228 67.6687 210.208 67.7207Z'
          fill='#414042'
        />
        <path
          d='M213.995 69.032C213.983 69.088 213.538 69 213.004 68.8401C212.471 68.6762 212.058 68.5043 212.078 68.4483C212.098 68.3923 212.539 68.4803 213.069 68.6402C213.594 68.8001 214.011 68.9761 213.995 69.032Z'
          fill='#414042'
        />
        <path
          d='M217.955 69.6597C217.955 69.7157 217.502 69.7157 216.948 69.6557C216.395 69.5958 215.953 69.4998 215.961 69.4438C215.969 69.3879 216.423 69.3879 216.968 69.4478C217.514 69.5078 217.955 69.6038 217.955 69.6597Z'
          fill='#414042'
        />
        <path
          d='M221.96 69.5357C221.968 69.5916 221.522 69.6716 220.969 69.7156C220.415 69.7556 219.962 69.7436 219.962 69.6876C219.962 69.6316 220.403 69.5517 220.953 69.5077C221.502 69.4677 221.952 69.4797 221.96 69.5357Z'
          fill='#414042'
        />
        <path
          d='M225.9 68.8002C225.916 68.8562 225.49 69.0081 224.949 69.1401C224.403 69.256 223.954 69.292 223.942 69.236C223.93 69.1761 224.363 69.0521 224.901 68.9362C225.434 68.8042 225.88 68.7443 225.896 68.8002H225.9Z'
          fill='#414042'
        />
        <path
          d='M229.623 67.3288C229.651 67.3808 229.262 67.6087 228.756 67.8406C228.251 68.0725 227.821 68.2164 227.801 68.1644C227.781 68.1124 228.171 67.8806 228.668 67.6527C229.17 67.4248 229.595 67.2808 229.623 67.3288Z'
          fill='#414042'
        />
        <path
          d='M232.949 65.1019C232.985 65.1459 232.672 65.4738 232.227 65.8096C231.782 66.1454 231.376 66.3533 231.348 66.3054C231.316 66.2574 231.661 65.9735 232.103 65.6417C232.544 65.3098 232.909 65.0579 232.949 65.1019Z'
          fill='#414042'
        />
        <path
          d='M235.677 62.1831C235.726 62.2151 235.501 62.6109 235.144 63.0387C234.787 63.4665 234.442 63.7623 234.401 63.7224C234.357 63.6824 234.634 63.3265 234.983 62.9067C235.332 62.4829 235.629 62.1471 235.677 62.1831Z'
          fill='#414042'
        />
        <path
          d='M237.555 58.6648C237.607 58.6848 237.503 59.1246 237.274 59.6323C237.046 60.1401 236.789 60.5159 236.737 60.4879C236.684 60.4599 236.861 60.0481 237.082 59.5484C237.306 59.0486 237.495 58.6448 237.551 58.6648H237.555Z'
          fill='#414042'
        />
        <path
          d='M238.297 54.7546C238.354 54.7546 238.402 55.2064 238.342 55.7622C238.285 56.3179 238.145 56.7537 238.093 56.7417C238.037 56.7297 238.081 56.2859 238.133 55.7422C238.189 55.1984 238.237 54.7546 238.293 54.7546H238.297Z'
          fill='#414042'
        />
        <path
          d='M237.72 50.8125C237.772 50.7965 237.956 51.2123 238.081 51.756C238.205 52.2998 238.221 52.7556 238.165 52.7596C238.109 52.7676 238 52.3318 237.876 51.8C237.756 51.2682 237.663 50.8285 237.72 50.8125Z'
          fill='#414042'
        />
        <path
          d='M235.974 47.226C236.018 47.194 236.323 47.5338 236.604 48.0176C236.885 48.4974 237.038 48.9292 236.985 48.9532C236.933 48.9772 236.701 48.5973 236.424 48.1215C236.147 47.6498 235.93 47.2579 235.974 47.226Z'
          fill='#414042'
        />
        <path
          d='M233.19 44.3753C233.222 44.3274 233.623 44.5513 234.044 44.9151C234.47 45.2789 234.747 45.6428 234.702 45.6827C234.658 45.7227 234.321 45.4309 233.908 45.075C233.495 44.7152 233.158 44.4273 233.19 44.3753Z'
          fill='#414042'
        />
        <path
          d='M229.551 42.7521C229.563 42.6961 230.02 42.7401 230.554 42.916C231.087 43.0919 231.481 43.3278 231.457 43.3758C231.433 43.4278 231.011 43.2839 230.49 43.1119C229.968 42.94 229.543 42.8081 229.555 42.7521H229.551Z'
          fill='#414042'
        />
        <path
          d='M225.575 42.8161C225.559 42.7601 225.988 42.5962 226.546 42.5283C227.103 42.4563 227.561 42.5003 227.561 42.5562C227.561 42.6162 227.115 42.6602 226.574 42.7322C226.032 42.8001 225.591 42.8721 225.575 42.8161Z'
          fill='#414042'
        />
        <path
          d='M222.116 44.7472C222.076 44.7113 222.349 44.3394 222.802 43.9996C223.252 43.6597 223.681 43.4918 223.709 43.5398C223.737 43.5918 223.364 43.8357 222.931 44.1635C222.493 44.4914 222.164 44.7832 222.12 44.7432L222.116 44.7472Z'
          fill='#414042'
        />
        <path
          d='M220.527 48.3336C220.471 48.3336 220.451 47.8698 220.583 47.3221C220.716 46.7743 220.953 46.3745 221.005 46.3985C221.061 46.4225 220.912 46.8423 220.788 47.37C220.66 47.8978 220.587 48.3336 220.527 48.3296V48.3336Z'
          fill='#414042'
        />
        <path
          d='M221.31 52.2118C221.262 52.2398 221.005 51.856 220.82 51.3242C220.636 50.7925 220.599 50.3367 220.652 50.3247C220.712 50.3127 220.836 50.7405 221.017 51.2563C221.193 51.772 221.362 52.1839 221.306 52.2118H221.31Z'
          fill='#414042'
        />
        <path
          d='M223.934 55.1863C223.902 55.2342 223.496 55.0183 223.079 54.6465C222.658 54.2747 222.393 53.8989 222.437 53.8629C222.481 53.8229 222.806 54.1308 223.22 54.4906C223.629 54.8504 223.97 55.1383 223.938 55.1863H223.934Z'
          fill='#414042'
        />
        <path
          d='M227.577 56.8096C227.565 56.8655 227.111 56.8056 226.582 56.6256C226.052 56.4457 225.659 56.2138 225.683 56.1619C225.707 56.1099 226.132 56.2498 226.65 56.4257C227.167 56.6017 227.593 56.7496 227.577 56.8056V56.8096Z'
          fill='#414042'
        />
        <path
          d='M231.557 57.1895C231.557 57.2455 231.112 57.3215 230.554 57.3055C229.996 57.2935 229.547 57.2015 229.555 57.1456C229.559 57.0856 230.008 57.0856 230.558 57.0976C231.108 57.1096 231.557 57.1296 231.557 57.1895Z'
          fill='#414042'
        />
        <path
          d='M235.489 56.4617C235.509 56.5137 235.092 56.7016 234.55 56.8415C234.008 56.9815 233.555 57.0135 233.547 56.9575C233.535 56.9015 233.968 56.7776 234.502 56.6416C235.036 56.5057 235.473 56.4097 235.489 56.4657V56.4617Z'
          fill='#414042'
        />
        <path
          d='M239.128 54.8026C239.16 54.8506 238.783 55.1025 238.289 55.3624C237.796 55.6222 237.379 55.7902 237.354 55.7382C237.33 55.6862 237.708 55.4343 238.193 55.1784C238.679 54.9226 239.1 54.7546 239.128 54.8026Z'
          fill='#414042'
        />
        <path
          d='M242.342 52.4196C242.382 52.4636 242.065 52.7834 241.632 53.1353C241.202 53.4871 240.825 53.735 240.789 53.687C240.757 53.639 241.074 53.3192 241.499 52.9714C241.924 52.6235 242.302 52.3756 242.342 52.4196Z'
          fill='#414042'
        />
        <path
          d='M245.078 49.497C245.122 49.533 244.869 49.9008 244.504 50.3246C244.143 50.7484 243.814 51.0562 243.774 51.0163C243.734 50.9763 243.987 50.6045 244.348 50.1886C244.705 49.7728 245.034 49.461 245.078 49.497Z'
          fill='#414042'
        />
        <path
          d='M247.281 46.1624C247.333 46.1904 247.14 46.5982 246.855 47.074C246.567 47.5498 246.298 47.9096 246.25 47.8776C246.201 47.8456 246.394 47.4378 246.675 46.966C246.96 46.4943 247.229 46.1344 247.281 46.1624Z'
          fill='#414042'
        />
        <path
          d='M248.91 42.5083C248.966 42.5283 248.846 42.9601 248.641 43.4758C248.44 43.9956 248.224 44.3914 248.167 44.3634C248.119 44.3434 248.248 43.9116 248.444 43.3999C248.645 42.8881 248.854 42.4883 248.906 42.5083H248.91Z'
          fill='#414042'
        />
        <path
          d='M250.029 38.674C250.085 38.686 250.017 39.1298 249.877 39.6696C249.736 40.2053 249.58 40.6291 249.524 40.6091C249.467 40.5931 249.536 40.1493 249.676 39.6136C249.816 39.0818 249.973 38.658 250.029 38.674Z'
          fill='#414042'
        />
        <path
          d='M250.731 34.7357C250.787 34.7437 250.767 35.1915 250.683 35.7392C250.599 36.287 250.487 36.7228 250.426 36.7108C250.37 36.6988 250.39 36.251 250.474 35.7073C250.559 35.1635 250.671 34.7277 250.731 34.7357Z'
          fill='#414042'
        />
        <path
          d='M251.1 30.7576C251.156 30.7576 251.171 31.2094 251.128 31.7611C251.088 32.3129 251.008 32.7567 250.948 32.7487C250.892 32.7447 250.876 32.2929 250.92 31.7451C250.96 31.1974 251.04 30.7536 251.1 30.7576Z'
          fill='#414042'
        />
        <path
          d='M251.228 26.7635C251.284 26.7635 251.324 27.2112 251.312 27.763C251.3 28.3147 251.248 28.7625 251.188 28.7585C251.132 28.7585 251.092 28.3067 251.104 27.759C251.116 27.2072 251.168 26.7595 251.228 26.7635Z'
          fill='#414042'
        />
        <path
          d='M251.2 22.7654C251.256 22.7654 251.312 23.2092 251.32 23.7649C251.328 24.3167 251.288 24.7645 251.232 24.7645C251.176 24.7645 251.12 24.3167 251.112 23.7649C251.104 23.2132 251.144 22.7654 251.2 22.7654Z'
          fill='#414042'
        />
        <path
          d='M251.14 20.3064C251.196 20.3064 251.248 20.4064 251.252 20.5343C251.252 20.6622 251.212 20.7662 251.156 20.7662C251.1 20.7662 251.048 20.6662 251.044 20.5383C251.044 20.4103 251.084 20.3064 251.14 20.3064Z'
          fill='#414042'
        />
        <path
          d='M259.931 8.435L232.66 11.3872L234.657 29.707L261.929 26.7547L259.931 8.435Z'
          fill='#E5444B'
        />
        <path
          d='M234.646 29.7265V29.7185L232.648 11.3909H232.656L259.939 8.43628V8.44428L261.937 26.7719H261.929L234.646 29.7265ZM232.668 11.4069L234.662 29.7065L261.913 26.7559L259.919 8.45627L232.668 11.4069Z'
          fill='#414042'
        />
        <path
          d='M260.044 8.65576C260.044 8.65576 259.971 8.75572 259.815 8.92764C259.642 9.11555 259.414 9.36344 259.129 9.6713C258.507 10.331 257.636 11.2506 256.565 12.382C254.366 14.673 251.357 17.8116 248.023 21.286L247.951 21.3579L247.867 21.302C247.802 21.262 247.73 21.214 247.658 21.17C243.545 18.5512 239.822 16.1803 237.102 14.4491C235.774 13.5895 234.694 12.8898 233.92 12.39C233.563 12.1542 233.278 11.9622 233.061 11.8183C232.865 11.6864 232.765 11.6104 232.773 11.6064C232.777 11.5984 232.885 11.6584 233.09 11.7783C233.31 11.9103 233.607 12.0902 233.972 12.3101C234.759 12.7939 235.854 13.4735 237.198 14.3052C239.93 16.0204 243.666 18.3713 247.794 20.9661C247.866 21.0101 247.939 21.0581 248.003 21.098L247.846 21.118C251.201 17.6636 254.23 14.5451 256.441 12.2661C257.532 11.1586 258.423 10.255 259.061 9.60732C259.362 9.31146 259.598 9.07557 259.783 8.89565C259.951 8.73573 260.04 8.65176 260.048 8.65976L260.044 8.65576Z'
          fill='#414042'
        />
        <path
          d='M234.767 29.9261C234.711 29.8861 236.488 27.1554 238.735 23.8209C240.986 20.4865 242.855 17.8157 242.911 17.8557C242.968 17.8956 241.19 20.6264 238.943 23.9609C236.693 27.2913 234.823 29.9661 234.767 29.9261Z'
          fill='#414042'
        />
        <path
          d='M251.951 16.6482C251.999 16.6002 254.298 18.8752 257.083 21.7259C259.867 24.5766 262.086 26.9275 262.038 26.9755C261.99 27.0234 259.691 24.7485 256.906 21.8978C254.122 19.0471 251.903 16.6962 251.951 16.6482Z'
          fill='#414042'
        />
        <path
          d='M135.392 63.9826C135.449 63.9746 135.533 64.1865 135.581 64.4584C135.629 64.7303 135.617 64.9582 135.561 64.9702C135.505 64.9822 135.42 64.7663 135.372 64.4944C135.324 64.2225 135.336 63.9946 135.392 63.9826Z'
          fill='#414042'
        />
        <path
          d='M134.75 60.0843C134.807 60.0763 134.923 60.5041 135.011 61.0439C135.099 61.5836 135.124 62.0274 135.067 62.0354C135.011 62.0434 134.895 61.6156 134.807 61.0759C134.718 60.5361 134.694 60.0923 134.75 60.0843Z'
          fill='#414042'
        />
        <path
          d='M134.177 56.1742C134.233 56.1662 134.341 56.598 134.417 57.1377C134.494 57.6775 134.51 58.1213 134.45 58.1293C134.389 58.1373 134.285 57.7055 134.209 57.1657C134.133 56.626 134.117 56.1782 134.177 56.1742Z'
          fill='#414042'
        />
        <path
          d='M133.727 52.2439C133.783 52.2399 133.876 52.6757 133.936 53.2155C133.992 53.7592 133.992 54.203 133.936 54.211C133.88 54.219 133.787 53.7832 133.727 53.2395C133.671 52.6957 133.671 52.2519 133.727 52.2439Z'
          fill='#414042'
        />
        <path
          d='M133.45 48.3018C133.506 48.3018 133.578 48.7376 133.61 49.2813C133.642 49.8251 133.618 50.2689 133.562 50.2729C133.506 50.2769 133.434 49.8371 133.402 49.2933C133.37 48.7496 133.394 48.3018 133.45 48.3018Z'
          fill='#414042'
        />
        <path
          d='M133.41 44.3477C133.466 44.3477 133.51 44.7915 133.51 45.3392C133.51 45.883 133.458 46.3268 133.402 46.3268C133.346 46.3268 133.302 45.883 133.302 45.3352C133.302 44.7875 133.354 44.3437 133.41 44.3477Z'
          fill='#414042'
        />
        <path
          d='M133.658 40.4054C133.714 40.4134 133.726 40.8572 133.678 41.3969C133.634 41.9407 133.55 42.3765 133.494 42.3725C133.438 42.3725 133.427 41.9247 133.474 41.3809C133.518 40.8372 133.602 40.3974 133.658 40.4054Z'
          fill='#414042'
        />
        <path
          d='M134.253 36.4993C134.309 36.5113 134.277 36.9551 134.185 37.4908C134.088 38.0266 133.968 38.4544 133.908 38.4464C133.852 38.4384 133.884 37.9946 133.976 37.4548C134.072 36.9151 134.197 36.4873 134.253 36.4993Z'
          fill='#414042'
        />
        <path
          d='M135.268 32.677C135.324 32.697 135.24 33.1328 135.087 33.6525C134.931 34.1723 134.758 34.5841 134.706 34.5681C134.65 34.5521 134.73 34.1163 134.891 33.5925C135.047 33.0688 135.22 32.657 135.272 32.677H135.268Z'
          fill='#414042'
        />
        <path
          d='M136.749 29.0147C136.801 29.0387 136.664 29.4625 136.444 29.9623C136.223 30.4581 136.002 30.8459 135.95 30.8259C135.898 30.8059 136.034 30.3821 136.255 29.8783C136.476 29.3786 136.696 28.9907 136.749 29.0147Z'
          fill='#414042'
        />
        <path
          d='M138.682 25.5643C138.731 25.5962 138.542 26.0001 138.261 26.4638C137.98 26.9276 137.711 27.2835 137.659 27.2555C137.607 27.2275 137.796 26.8237 138.081 26.3559C138.365 25.8881 138.634 25.5323 138.682 25.5643Z'
          fill='#414042'
        />
        <path
          d='M141.062 22.4058C141.106 22.4457 140.861 22.8176 140.52 23.2414C140.179 23.6652 139.862 23.981 139.818 23.945C139.77 23.9091 140.014 23.5372 140.359 23.1094C140.705 22.6816 141.021 22.3658 141.062 22.4058Z'
          fill='#414042'
        />
        <path
          d='M143.882 19.6269C143.918 19.6708 143.621 20.0027 143.22 20.3705C142.819 20.7383 142.458 21.0022 142.418 20.9622C142.378 20.9223 142.674 20.5904 143.08 20.2186C143.485 19.8468 143.846 19.5829 143.882 19.6269Z'
          fill='#414042'
        />
        <path
          d='M147.124 17.3559C147.152 17.4079 146.803 17.6837 146.346 17.9756C145.888 18.2675 145.487 18.4674 145.455 18.4194C145.423 18.3714 145.772 18.0955 146.233 17.7997C146.699 17.5038 147.096 17.3039 147.124 17.3559Z'
          fill='#414042'
        />
        <path
          d='M150.735 15.7368C150.751 15.7927 150.358 15.9966 149.848 16.1926C149.343 16.3885 148.91 16.5084 148.885 16.4564C148.861 16.4045 149.259 16.2005 149.772 16.0006C150.286 15.8007 150.715 15.6848 150.735 15.7368Z'
          fill='#414042'
        />
        <path
          d='M153.632 15.0251C153.64 15.081 153.431 15.169 153.158 15.221C152.89 15.273 152.661 15.269 152.649 15.213C152.637 15.157 152.85 15.0691 153.122 15.0171C153.395 14.9651 153.624 14.9691 153.632 15.0251Z'
          fill='#414042'
        />
        <path
          d='M132.29 0.0124753L125.039 18.699L152.859 29.4183L160.11 10.7318L132.29 0.0124753Z'
          fill='#E5444B'
        />
        <path
          d='M152.866 29.4345H152.858L125.029 18.7074V18.6994L132.287 0H132.295L160.124 10.7271V10.7351L152.866 29.4305V29.4345ZM125.053 18.6954L152.854 29.4065L160.096 10.7391L132.295 0.023989L125.053 18.6914V18.6954Z'
          fill='#414042'
        />
        <path
          d='M160.112 10.9908C160.112 10.9908 159.991 11.0508 159.755 11.1388C159.494 11.2347 159.149 11.3587 158.719 11.5146C157.789 11.8425 156.485 12.2982 154.888 12.858C151.618 13.9775 147.136 15.5088 142.177 17.204L142.073 17.24L142.016 17.144C141.972 17.0721 141.928 16.9921 141.88 16.9121C139.22 12.3382 136.813 8.19613 135.051 5.16952C134.201 3.67821 133.507 2.46276 133.009 1.59516C132.784 1.18735 132.604 0.8635 132.467 0.615614C132.347 0.391717 132.287 0.271772 132.295 0.263775C132.303 0.259777 132.379 0.371726 132.516 0.587627C132.664 0.827516 132.861 1.14337 133.101 1.53919C133.615 2.39879 134.337 3.59824 135.22 5.06957C136.997 8.08818 139.429 12.2143 142.117 16.7722C142.165 16.8522 142.209 16.9321 142.253 17.0041L142.093 16.9441C147.064 15.2769 151.55 13.7736 154.832 12.6741C156.44 12.1463 157.752 11.7185 158.691 11.4107C159.129 11.2747 159.478 11.1628 159.746 11.0788C159.987 11.0068 160.116 10.9709 160.12 10.9829L160.112 10.9908Z'
          fill='#414042'
        />
        <path
          d='M125.041 18.9593C125.005 18.8953 128.09 17.1402 131.934 15.0371C135.778 12.9381 138.919 11.2868 138.955 11.3548C138.991 11.4188 135.906 13.174 132.062 15.277C128.219 17.376 125.077 19.0273 125.041 18.9593Z'
          fill='#414042'
        />
        <path
          d='M148.296 14.6851C148.368 14.6652 149.447 18.0036 150.707 22.1417C151.967 26.2838 152.934 29.6583 152.862 29.6783C152.789 29.6983 151.71 26.3598 150.45 22.2217C149.19 18.0836 148.223 14.7091 148.296 14.6851Z'
          fill='#414042'
        />
        <path
          d='M123.708 68.3606C123.764 68.3606 123.792 68.5925 123.772 68.8684C123.748 69.1443 123.684 69.3642 123.628 69.3602C123.572 69.3562 123.544 69.1283 123.564 68.8524C123.588 68.5766 123.652 68.3567 123.708 68.3606Z'
          fill='#414042'
        />
        <path
          d='M123.809 64.2944C123.865 64.2944 123.913 64.7502 123.909 65.31C123.909 65.8737 123.861 66.3295 123.801 66.3255C123.745 66.3255 123.697 65.8697 123.701 65.31C123.701 64.7502 123.749 64.2944 123.809 64.2944Z'
          fill='#414042'
        />
        <path
          d='M123.532 60.2362C123.589 60.2282 123.677 60.68 123.729 61.2397C123.781 61.7995 123.777 62.2593 123.717 62.2633C123.661 62.2633 123.572 61.8195 123.52 61.2597C123.468 60.704 123.472 60.2442 123.532 60.2362Z'
          fill='#414042'
        />
        <path
          d='M122.854 56.2261C122.91 56.2141 123.043 56.6499 123.151 57.2056C123.26 57.7574 123.304 58.2132 123.243 58.2252C123.187 58.2331 123.051 57.7973 122.947 57.2456C122.838 56.6979 122.798 56.2421 122.854 56.2261Z'
          fill='#414042'
        />
        <path
          d='M121.743 52.312C121.799 52.292 121.98 52.7118 122.148 53.2515C122.317 53.7913 122.409 54.2391 122.353 54.2551C122.297 54.2711 122.116 53.8513 121.948 53.3155C121.779 52.7798 121.687 52.332 121.743 52.312Z'
          fill='#414042'
        />
        <path
          d='M120.182 48.5577C120.234 48.5337 120.463 48.9295 120.696 49.4413C120.928 49.957 121.073 50.3888 121.021 50.4128C120.969 50.4328 120.736 50.037 120.507 49.5292C120.278 49.0175 120.13 48.5857 120.182 48.5577Z'
          fill='#414042'
        />
        <path
          d='M118.156 45.0274C118.204 44.9954 118.493 45.3552 118.794 45.831C119.083 46.3148 119.275 46.7306 119.227 46.7626C119.175 46.7906 118.902 46.4227 118.617 45.9429C118.316 45.4712 118.108 45.0633 118.156 45.0314V45.0274Z'
          fill='#414042'
        />
        <path
          d='M115.656 41.8127C115.701 41.7727 116.03 42.0926 116.391 42.5284C116.752 42.9602 117.009 43.34 116.964 43.376C116.92 43.412 116.591 43.0921 116.23 42.6603C115.869 42.2325 115.612 41.8527 115.656 41.8127Z'
          fill='#414042'
        />
        <path
          d='M112.728 38.9861C112.764 38.9421 113.137 39.214 113.554 39.5898C113.975 39.9697 114.284 40.3055 114.24 40.3495C114.2 40.3895 113.831 40.1216 113.414 39.7458C112.996 39.3699 112.687 39.0301 112.728 38.9861Z'
          fill='#414042'
        />
        <path
          d='M109.417 36.6071C109.445 36.5591 109.855 36.771 110.324 37.0829C110.794 37.3948 111.151 37.6866 111.115 37.7346C111.082 37.7826 110.673 37.5667 110.208 37.2588C109.742 36.947 109.385 36.6591 109.417 36.6071Z'
          fill='#414042'
        />
        <path
          d='M105.782 34.7641C105.802 34.7121 106.24 34.86 106.753 35.0959C107.267 35.3318 107.664 35.5637 107.636 35.6157C107.608 35.6676 107.175 35.5197 106.665 35.2838C106.155 35.0479 105.758 34.816 105.782 34.7641Z'
          fill='#414042'
        />
        <path
          d='M101.891 33.5365C101.903 33.4806 102.356 33.5525 102.902 33.7005C103.447 33.8484 103.877 34.0123 103.86 34.0643C103.844 34.1203 103.391 34.0443 102.849 33.9004C102.308 33.7564 101.878 33.5925 101.891 33.5365Z'
          fill='#414042'
        />
        <path
          d='M97.8462 33.0208C97.8462 32.9648 98.3076 32.9408 98.8733 32.9728C99.439 33.0328 99.8884 33.1248 99.8804 33.1807C99.8724 33.2367 99.415 33.2367 98.8573 33.1807C98.2996 33.1487 97.8462 33.0768 97.8462 33.0208Z'
          fill='#414042'
        />
        <path
          d='M93.7778 33.3006C93.7698 33.2447 94.2151 33.1447 94.7809 33.0768C95.3426 33.0088 95.804 33.0048 95.808 33.0608C95.808 33.1167 95.3626 33.2167 94.8049 33.2847C94.2472 33.3486 93.7899 33.3566 93.7778 33.3006Z'
          fill='#414042'
        />
        <path
          d='M89.8377 34.3443C89.8177 34.2923 90.2389 34.1084 90.7806 33.9364C91.3222 33.7645 91.7716 33.6726 91.7836 33.7285C91.7997 33.7845 91.3784 33.9644 90.8408 34.1363C90.3072 34.3043 89.8578 34.4002 89.8377 34.3443Z'
          fill='#414042'
        />
        <path
          d='M86.1625 36.1073C86.1304 36.0593 86.5116 35.7994 87.0091 35.5315C87.5066 35.2637 87.9319 35.0877 87.956 35.1397C87.9801 35.1917 87.6029 35.4476 87.1094 35.7155C86.6159 35.9793 86.1906 36.1553 86.1625 36.1073Z'
          fill='#414042'
        />
        <path
          d='M82.9046 38.5501C82.8645 38.5102 83.1855 38.1823 83.6228 37.8185C84.0601 37.4547 84.4413 37.1988 84.4734 37.2467C84.5095 37.2947 84.1885 37.6186 83.7552 37.9784C83.3259 38.3342 82.9448 38.5901 82.9046 38.5501Z'
          fill='#414042'
        />
        <path
          d='M80.2366 41.6168C80.1884 41.5848 80.4091 41.177 80.7541 40.7292C81.1032 40.2814 81.4402 39.9695 81.4844 40.0055C81.5285 40.0455 81.2637 40.4133 80.9186 40.8571C80.5776 41.2969 80.2847 41.6488 80.2366 41.6168Z'
          fill='#414042'
        />
        <path
          d='M78.748 44.2596C78.6958 44.2356 78.748 44.0157 78.8724 43.7639C78.9927 43.512 79.1332 43.3321 79.1853 43.36C79.2375 43.388 79.1813 43.6079 79.061 43.8558C78.9406 44.1037 78.8002 44.2836 78.748 44.2596Z'
          fill='#414042'
        />
        <path
          d='M53.2544 67.8528C53.2544 67.7968 53.467 67.7208 53.7399 67.6888C54.0127 67.6569 54.2414 67.6769 54.2494 67.7328C54.2615 67.7888 54.0408 67.8608 53.7639 67.8968C53.4871 67.9287 53.2584 67.9127 53.2544 67.8528Z'
          fill='#414042'
        />
        <path
          d='M49.2823 67.4051C49.2984 67.3491 49.7397 67.441 50.2773 67.553C50.815 67.6649 51.2563 67.7569 51.2483 67.8169C51.2443 67.8728 50.7829 67.8728 50.2332 67.7569C49.6835 67.641 49.2622 67.457 49.2823 67.4051Z'
          fill='#414042'
        />
        <path
          d='M45.6913 65.6338C45.7234 65.5858 46.1166 65.8057 46.59 66.0896C47.0635 66.3735 47.4446 66.6093 47.4166 66.6613C47.3925 66.7133 46.9632 66.5574 46.4817 66.2695C46.0002 65.9856 45.6592 65.6818 45.6913 65.6378V65.6338Z'
          fill='#414042'
        />
        <path
          d='M42.7022 62.9632C42.7463 62.9232 43.0753 63.2311 43.4645 63.6229C43.8537 64.0147 44.1546 64.3466 44.1185 64.3866C44.0824 64.4305 43.7093 64.1627 43.3161 63.7668C42.9229 63.371 42.658 62.9992 42.7022 62.9632Z'
          fill='#414042'
        />
        <path
          d='M40.3431 59.7247C40.3912 59.6967 40.6801 60.0446 40.981 60.5044C41.2819 60.9642 41.4906 61.364 41.4465 61.4C41.3983 61.4359 41.1134 61.0841 40.8085 60.6203C40.5036 60.1565 40.2949 59.7527 40.3431 59.7247Z'
          fill='#414042'
        />
        <path
          d='M38.6098 56.1143C38.662 56.0943 38.8827 56.4901 39.0993 56.9938C39.316 57.5016 39.4484 57.9294 39.3962 57.9574C39.3441 57.9814 39.1234 57.5896 38.9067 57.0778C38.6861 56.566 38.5537 56.1342 38.6098 56.1143Z'
          fill='#414042'
        />
        <path
          d='M37.4583 52.2761C37.5144 52.2641 37.6749 52.6839 37.8154 53.2197C37.9558 53.7515 38.024 54.1992 37.9678 54.2152C37.9117 54.2312 37.7512 53.8114 37.6107 53.2717C37.4703 52.7359 37.4021 52.2881 37.4583 52.2761Z'
          fill='#414042'
        />
        <path
          d='M36.7602 48.3299C36.8164 48.3219 36.9327 48.7577 37.013 49.3055C37.0972 49.8532 37.1173 50.301 37.0611 50.313C37.0049 50.325 36.8926 49.8852 36.8083 49.3375C36.7241 48.7897 36.704 48.3379 36.7602 48.3299Z'
          fill='#414042'
        />
        <path
          d='M36.3748 44.3396C36.4307 44.3396 36.5147 44.7794 36.5586 45.3311C36.6026 45.8829 36.5946 46.3347 36.5347 46.3387C36.4787 46.3427 36.3947 45.9029 36.3508 45.3471C36.3068 44.7954 36.3148 44.3436 36.3748 44.3396Z'
          fill='#414042'
        />
        <path
          d='M36.2062 41.3411C36.2621 41.3411 36.3221 41.561 36.3341 41.8368C36.3461 42.1127 36.3101 42.3366 36.2541 42.3406C36.1982 42.3406 36.1382 42.1207 36.1262 41.8448C36.1142 41.569 36.1502 41.3451 36.2062 41.3411Z'
          fill='#414042'
        />
        <path
          d='M51.313 17.0949L17.9526 18.6685L19.0092 41.0774L52.3695 39.5038L51.313 17.0949Z'
          fill='#E5444B'
        />
        <path
          d='M19.0021 41.0851V41.0731L17.9429 18.6554H17.9549L51.3285 17.0881V17.1001L52.3878 39.5178H52.3757L19.0021 41.0851ZM17.9629 18.6754L19.0222 41.0651L52.3637 39.4978L51.3045 17.1081L17.9629 18.6754Z'
          fill='#414042'
        />
        <path
          d='M51.4329 17.364C51.4329 17.364 51.3366 17.48 51.136 17.6759C50.9113 17.8918 50.6144 18.1756 50.2453 18.5315C49.4388 19.2871 48.3114 20.3426 46.9312 21.638C44.0865 24.2608 40.1987 27.8472 35.8856 31.8254L35.7933 31.9093L35.693 31.8374C35.6167 31.7814 35.5325 31.7214 35.4482 31.6614C30.6456 28.175 26.3004 25.0205 23.1227 22.7136C21.574 21.5701 20.3101 20.6385 19.4074 19.9748C18.9901 19.659 18.6571 19.4071 18.4083 19.2192C18.1796 19.0432 18.0633 18.9473 18.0713 18.9393C18.0793 18.9313 18.2037 19.0113 18.4444 19.1712C18.7052 19.3511 19.0503 19.587 19.4796 19.8829C20.3984 20.5306 21.6783 21.4341 23.2511 22.5456C26.4408 24.8326 30.8061 27.9631 35.6288 31.4216C35.713 31.4815 35.7973 31.5415 35.8735 31.5975L35.6809 31.6095C40.0141 27.6593 43.926 24.0969 46.7867 21.4901C48.195 20.2227 49.3465 19.1952 50.169 18.4555C50.5542 18.1157 50.8632 17.8478 51.0959 17.6439C51.3085 17.46 51.4249 17.368 51.4329 17.376V17.364Z'
          fill='#414042'
        />
        <path
          d='M19.1305 41.3371C19.0623 41.2851 21.4295 38.0986 24.4105 34.2124C27.3916 30.3301 29.8631 27.2236 29.9314 27.2715C29.9996 27.3235 27.6364 30.5101 24.6513 34.3963C21.6702 38.2785 19.1987 41.3851 19.1305 41.3371Z'
          fill='#414042'
        />
        <path
          d='M41.0051 26.4759C41.0693 26.4199 43.6933 29.3546 46.8629 33.0249C50.0366 36.6992 52.5563 39.7178 52.4921 39.7738C52.4279 39.8297 49.8079 36.8951 46.6342 33.2248C43.4646 29.5545 40.9409 26.5319 41.0051 26.4799V26.4759Z'
          fill='#414042'
        />
        <path
          d='M106.331 88.993L159.518 88.4998L161.074 124.527L106.001 124.135L106.331 88.993Z'
          fill='#E5444B'
        />
        <path
          d='M106.331 88.9928L131.46 68.6453C132.525 67.7821 134.047 67.7858 135.112 68.649L159.518 88.4995'
          fill='#E5444B'
        />
        <g opacity='0.3'>
          <path
            d='M106.331 88.9928L131.46 68.6453C132.525 67.7821 134.047 67.7858 135.112 68.649L159.518 88.4995'
            fill='white'
          />
        </g>
        <path
          d='M106.001 124.135C105.933 124.071 110.182 119.43 115.488 113.769C120.795 108.103 125.152 103.563 125.216 103.627C125.283 103.69 121.035 108.328 115.728 113.993C110.422 119.654 106.068 124.195 106.001 124.135Z'
          fill='#414042'
        />
        <path
          d='M161.074 124.527C161.006 124.587 156.851 120.058 151.793 114.408C146.734 108.757 142.687 104.131 142.755 104.071C142.822 104.012 146.978 108.541 152.036 114.191C157.095 119.837 161.141 124.467 161.074 124.527Z'
          fill='#414042'
        />
        <g opacity='0.5'>
          <path
            d='M159.518 88.4998L132.855 109.815L106.331 88.993'
            fill='white'
          />
        </g>
        <path
          d='M109.015 90.9551L108.895 74.4043L155.467 73.7578L156.03 91.1756L132.851 109.535L109.015 90.9551Z'
          fill='white'
        />
        <g clipPath='url(#clip1_2413_7429)'>
          <mask
            id='mask0_2413_7429'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='116'
            y='80'
            width='33'
            height='14'
          >
            <rect
              x='116.575'
              y='80.5549'
              width='31.7784'
              height='13.0852'
              fill='#C4C4C4'
            />
          </mask>
          <g mask='url(#mask0_2413_7429)'>
            <path
              d='M124.596 86.3948C124.566 86.4097 124.533 86.4172 124.499 86.4166C124.466 86.4159 124.433 86.4073 124.404 86.3913L124.091 86.2107C124.064 86.1931 124.041 86.1693 124.025 86.1413L124.022 86.1362C124.003 86.1075 123.992 86.0745 123.989 86.0405L123.981 85.6866C123.982 85.6539 123.99 85.6219 124.007 85.5934C124.023 85.5648 124.046 85.5406 124.074 85.5228L127.829 83.3949L122.56 80.6011C122.494 80.5704 122.421 80.5544 122.348 80.5544C122.275 80.5544 122.203 80.5704 122.137 80.6011L116.867 83.3949L122.771 86.7375C122.799 86.7545 122.823 86.778 122.84 86.806C122.856 86.8341 122.866 86.8657 122.867 86.8982V91.7744C122.865 91.8071 122.855 91.8388 122.838 91.8667C122.82 91.8947 122.796 91.918 122.767 91.9346L122.449 92.1062C122.42 92.1204 122.387 92.1276 122.354 92.1271H122.348C122.313 92.1286 122.278 92.1214 122.247 92.1062L121.928 91.9346C121.899 91.9178 121.875 91.8944 121.858 91.8665C121.841 91.8385 121.831 91.8069 121.83 91.7744V87.4275C121.827 87.4104 121.822 87.394 121.812 87.3793C121.803 87.3646 121.791 87.3519 121.777 87.3421L116.575 84.3974V90.1654C116.575 90.2945 116.669 90.4536 116.784 90.5193L122.14 93.5915C122.205 93.6234 122.276 93.64 122.348 93.64C122.421 93.64 122.492 93.6234 122.556 93.5915L127.914 90.5188C128.028 90.4529 128.121 90.2945 128.121 90.1649V84.3974L124.596 86.3948Z'
              fill='#DC0032'
            />
            <path
              d='M136.337 90.249C135.756 90.3984 135.001 90.4723 134.343 90.4723C132.657 90.4723 131.54 89.5994 131.54 88.0017C131.54 86.4896 132.58 85.5102 134.103 85.5102C134.442 85.5102 134.803 85.5522 135.022 85.659V83.4646H136.336L136.337 90.249ZM135.023 86.7878C134.815 86.6919 134.542 86.6387 134.213 86.6387C133.414 86.6387 132.877 87.1182 132.877 87.9594C132.877 88.8648 133.457 89.3761 134.388 89.3761C134.552 89.3761 134.804 89.3652 135.023 89.3229V86.7878ZM147.119 90.249C146.538 90.3984 145.783 90.4723 145.126 90.4723C143.439 90.4723 142.322 89.5994 142.322 88.0017C142.322 86.4896 143.363 85.5102 144.885 85.5102C145.224 85.5102 145.586 85.5522 145.805 85.659V83.4646H147.119L147.119 90.249ZM145.805 86.7878C145.597 86.6919 145.323 86.6387 144.994 86.6387C144.195 86.6387 143.659 87.1182 143.659 87.9594C143.659 88.8648 144.239 89.3761 145.17 89.3761C145.334 89.3761 145.586 89.3652 145.805 89.3229V86.7878ZM138.403 86.7773C138.622 86.6919 138.928 86.6602 139.191 86.6602C140.001 86.6602 140.56 87.1182 140.56 87.9374C140.56 88.9043 139.941 89.3418 139.114 89.353V90.4711C139.136 90.4711 139.158 90.4711 139.18 90.4711C140.877 90.4711 141.896 89.5449 141.896 87.8943C141.896 86.3925 140.812 85.5093 139.213 85.5093C138.403 85.5093 137.603 85.6904 137.077 85.903V92.2841H138.403L138.403 86.7773Z'
              fill='#414042'
            />
          </g>
        </g>
        <path
          d='M109.016 90.955C109.016 90.955 109.17 91.0671 109.458 91.2838C109.747 91.5081 110.175 91.8332 110.73 92.2592C111.843 93.1187 113.471 94.378 115.548 95.9886C119.707 99.2173 125.666 103.847 132.904 109.468H132.806C139.481 104.172 147.401 97.8907 155.974 91.0933L155.936 91.1717C155.828 87.8496 155.715 84.3631 155.603 80.8167C155.528 78.4289 155.449 76.0709 155.374 73.7577L155.471 73.8511C136.706 74.0978 120.153 74.3145 108.9 74.4603L108.96 74.3967C108.982 79.6022 109.001 83.7353 109.016 86.5716C109.016 87.9879 109.02 89.0791 109.023 89.8227C109.023 90.1889 109.023 90.4692 109.023 90.6635C109.023 90.8541 109.016 90.9513 109.016 90.9513C109.016 90.9513 109.008 90.8578 109.005 90.671C109.005 90.4804 108.997 90.2001 108.99 89.8377C108.982 89.0977 108.967 88.0066 108.952 86.594C108.926 83.7502 108.885 79.6097 108.836 74.3967V74.3332H108.896C120.146 74.1688 136.699 73.9259 155.464 73.6531H155.558V73.7465C155.633 76.0597 155.708 78.4176 155.786 80.8055C155.899 84.3519 156.011 87.8384 156.12 91.1605V91.2091L156.083 91.239C147.506 98.0252 139.579 104.3 132.896 109.587L132.847 109.628L132.799 109.591C125.572 103.941 119.617 99.2808 115.466 96.0335C113.4 94.4116 111.783 93.1411 110.681 92.2741C110.137 91.8444 109.717 91.5118 109.432 91.2876C109.151 91.0634 109.012 90.9475 109.012 90.9475L109.016 90.955Z'
          fill='#414042'
        />
        <circle
          cx='158'
          cy='73'
          r='10.5'
          fill='#E5444B'
          stroke='white'
          strokeWidth='3'
        />
        <path
          d='M159.306 68.095V77H157.548V70.1213L155.437 70.7927V69.4072L159.117 68.095H159.306Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2413_7429'>
          <rect width='268' height='148' fill='white' />
        </clipPath>
        <clipPath id='clip1_2413_7429'>
          <rect
            width='31.7784'
            height='13.0852'
            fill='white'
            transform='translate(116.575 80.5549)'
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default CheckEmail;
