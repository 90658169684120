import _ from 'lodash';

export function pickDeep(object, paths) {
  return paths.reduce((result, p) => {
    let path = p;
    let defaultValue;
    if (Array.isArray(p)) {
      [path, defaultValue] = p;
    }
    const value = _.get(object, path, defaultValue);
    if (value !== undefined) {
      _.set(result, path, value);
    }
    return result;
  }, {});
}

export function convertToNestedObject(obj) {
  return Object.keys(obj).reduce((result, key) => {
    _.set(result, key, obj[key]);
    return result;
  }, {});
}
