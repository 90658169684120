import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, redirectTo, isEnabled }) =>
  isEnabled ? element : <Navigate to={redirectTo} replace />;

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  redirectTo: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};

export default ProtectedRoute;
