import { useState } from 'react';
import Box from '@mui/material/Box';
import { Card, Typography } from '@mui/material';
import ActionButton from '../../components/ActionButton';

const Playground = () => {
  const [selectedActionButton, setSelectedActionButton] = useState(1);

  return (
    <Box sx={{ flexGrow: 1, p: { xs: 3, md: 2 }, display: 'flex' }}>
      <Box
        sx={{
          flexGrow: 1,
          ml: { md: '78px' },
          mr: { md: '78px' },
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant='h2' sx={{ mt: 2 }}>
          Dev Playground.
        </Typography>

        <Card
          sx={{
            mt: 2,
            p: 2,
          }}
        >
          <Box display='flex' justifyContent='space-evenly' gap={2}>
            <ActionButton
              selected={selectedActionButton === 1}
              sizingChipPosition='underTitle'
              sizingChip='small'
              highlightChip='Most Popular'
              textButton='TEXT BUTTON'
              printerRequired={true}
              title='Title'
              bodyText='Body Text'
              cardOnclick={() => setSelectedActionButton(1)}
            />
            <ActionButton
              selected={selectedActionButton === 2}
              sizingChipPosition='withTitle'
              sizingChip='small'
              textButton='TEXT BUTTON Alternative'
              title='Title Alternative'
              bodyText='Body Text alternative'
              price='2.25'
              buttonOnclick={() => alert('test')}
              cardOnclick={() => setSelectedActionButton(2)}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Playground;
