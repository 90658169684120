import { Typography, Grid } from '@mui/material';

const BasketCardDetails = ({ data, title }) => {
  const { county, locality, organisation, postcode, street, town } =
    data?.address || {};

  const { contactName, email, telephone } = data.contactDetails || {};

  return (
    <Grid item flexGrow={1}>
      <Typography variant='subtitle2' color='primary.main' spacing={2}>
        {title}
      </Typography>
      {organisation && <Typography variant='body1'>{organisation}</Typography>}
      {contactName && <Typography variant='body1'>{contactName}</Typography>}
      {street && <Typography variant='body1'>{street}</Typography>}
      {town && <Typography variant='body1'>{town}</Typography>}
      {county && <Typography variant='body1'>{county}</Typography>}
      {locality && <Typography variant='body1'>{locality}</Typography>}
      {postcode && <Typography variant='body1'>{postcode}</Typography>}
      {telephone && (
        <Typography variant='body1' sx={{ mt: 3 }}>
          {telephone}
        </Typography>
      )}
      {email && <Typography variant='body1'>{email}</Typography>}
    </Grid>
  );
};

export default BasketCardDetails;
