import { APIS_BASE_URL } from '../constants/env';

export const generateMapboxStaticImageUrl = ({
  longitude = 0,
  latitude = 0,
  width = 300,
  height = 150,
  zoom = 17,
  markerColor = '000000',
  customMarker,
}) => {
  const accessToken = import.meta.env.VITE_MAPBOX_API_TOKEN;

  // Determine the marker style based on the presence of a custom marker
  const marker = customMarker
    ? `url-${encodeURIComponent(customMarker)}(${longitude},${latitude})`
    : `pin-s+${markerColor}(${longitude},${latitude})`;

  const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${marker}/${longitude},${latitude},${zoom}/${width}x${height}?access_token=${accessToken}`;
  return url;
};

export const generateRouteMapStaticImageUrlByParcelCode = ({
  parcelCode,
  withMarkers = true,
  width = 600,
  height = 150,
  theme = 'light',
}) =>
  `${APIS_BASE_URL}/map/route?withMarkers=${withMarkers}&size=${width}%2A${height}&theme=${theme}&parcelCode=${parcelCode}`;
