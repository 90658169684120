import { AuthErrorCodes } from 'firebase/auth';

import { STRINGS } from '../../constants';

export const wrapAuthHandler =
  fn =>
  (...args) =>
    fn(...args).catch(err => {
      switch (err.code) {
        case AuthErrorCodes.NETWORK_REQUEST_FAILED:
          throw {
            ...err,
            message: STRINGS.LOST_INTERNET_CONNECTION,
          };
        default:
          throw err;
      }
    });
