import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { ADDRESSES_SECTION } from '../../../../constants/strings';

const AddressBookDeleteModal = ({ isOpen, onClose, onDelete, contactName }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: '8px' } }}
    >
      <DialogTitle
        variant='h3'
        sx={{
          padding: theme.spacing(2),
          paddingBottom: theme.spacing(1),
          fontSize: '20px',
        }}
      >
        {ADDRESSES_SECTION.DELETE_MODAL.TITLE}
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{ padding: theme.spacing(2), maxWidth: { xs: 340, md: 328 } }}
      >
        <Typography variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>
          {ADDRESSES_SECTION.DELETE_MODAL.TEXT_$(
            contactName ?? ADDRESSES_SECTION.TABLE_HEADINGS.CONTACT_NAME
          )}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: theme.spacing(2),
          paddingTop: theme.spacing(1),
          flexDirection: { xs: 'column', md: 'row' },
        }}
        disableSpacing
      >
        <Button
          variant='outlined'
          onClick={onClose}
          sx={{ flex: 1, width: '100%' }}
        >
          {ADDRESSES_SECTION.DELETE_MODAL.CANCEL_BUTTON}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            onDelete();
            onClose();
          }}
          sx={{
            flex: 1,
            width: '100%',
            ml: { xs: 0, md: theme.spacing(1) },
            mt: { xs: theme.spacing(1), md: 0 },
          }}
        >
          {ADDRESSES_SECTION.DELETE_MODAL.DELETE_BUTTON}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddressBookDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  contactName: PropTypes.string,
};

export default AddressBookDeleteModal;
