import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { replaceLineBreakWithBr } from '../../../utils/strings.jsx';

const HelpfulDetails = ({ title, details }) => (
  <Box>
    <Typography
      variant='h2'
      sx={{
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '32px',
        paddingBottom: '16px',
      }}
    >
      {title}
    </Typography>
    {details &&
      details.map(({ TITLE: title, DESCRIPTION: description }, index) => (
        <Box key={index}>
          <Typography
            variant={'h3'}
            sx={{
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              padding: '8px 0px',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant={'body1'}
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: '24px',
              letterSpacing: '0.15px',
              paddingBottom: '16px',
            }}
          >
            {replaceLineBreakWithBr(description)}
          </Typography>
        </Box>
      ))}
  </Box>
);

export default HelpfulDetails;
