import { Box, IconButton } from '@mui/material';

import { GENERAL_SOCIAL_LINKS } from '../../../constants/socialsLinks.js';
import LinkedinIcon from '../../../components/icons/LinkedinIcon.jsx';
import InstagramIcon from '../../../components/icons/InstagramIcon.jsx';

const SocialBox = () => {
  const socials = [
    {
      alt: 'social instagram icon',
      IconComponent: props => <InstagramIcon {...props} />,
      src: GENERAL_SOCIAL_LINKS.INSTAGRAM,
    },
    {
      alt: 'social linkedin icon',
      IconComponent: props => <LinkedinIcon {...props} />,
      src: GENERAL_SOCIAL_LINKS.LINKEDIN,
    },
  ];

  return (
    <Box>
      {socials.map(({ IconComponent, alt, src }, index) => (
        <IconButton
          alt={alt}
          href={src}
          target={'_blank'}
          sx={{
            padding: '16px 12px',
            paddingLeft: index === 0 && '0px',
          }}
          key={index}
          disableRipple
        >
          <IconComponent />
        </IconButton>
      ))}
    </Box>
  );
};

export default SocialBox;
