import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ArrowDropDown } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import { ORDER_DETAILS, ORDERS_SECTION } from '../../constants/strings';
import parcelSlice from '../../redux/parcelSlice';
import { formatAddressLine } from '../../utils/formatters';
import Loader from '../../features/Loader/Loader';
import useErrorHandler from '../../hooks/useErrorHandler';
import { generateRouteMapStaticImageUrlByParcelCode } from '../../utils/map';
import { getImageUrl } from '../../utils/images';
import notFoundImg from '../../assets/images/image-not-found.png';
import GalleryIconButton from '../GalleryIconButton';
import GalleryWidget from '../GalleryWidget';

const Label = ({ children, sx }) => (
  <Typography
    variant='body2'
    color='textSecondary'
    sx={{
      fontFamily: 'Roboto',
      marginY: 1,
      ...sx,
    }}
  >
    {children}
  </Typography>
);

const Text = ({ children, color, sx, ...props }) => (
  <Typography
    variant='body1'
    color={color || 'textSecondary'}
    sx={{
      fontFamily: 'Roboto',
      marginY: 1,
      fontWeight: 400,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Typography>
);

const AddressDetails = ({ label, text }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: theme.palette.grey[200],
      }}
    >
      <Label>{label}</Label>
      <Text>{text}</Text>
    </Box>
  );
};

const EventDetails = ({ date, text, color, last }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 10,
              height: 10,
              border: 2,
              borderColor: color,
              borderRadius: 10,
              mr: 1,
            }}
          />
        </Box>
        <Box>
          <Label sx={{ fontSize: 12, m: 0 }}>
            {date || ORDER_DETAILS.UNKNOWN}
          </Label>
          <Text sx={{ m: 0 }}>{text || ORDER_DETAILS.UNKNOWN}</Text>
        </Box>
      </Box>
      {last ? (
        <Box
          sx={{ borderBottom: 1, borderColor: theme.palette.grey[200], mt: 2 }}
        />
      ) : (
        <Box
          sx={{
            height: 35,
            borderLeft: 2,
            borderColor: theme.palette.grey[200],
            ml: 0.5,
            marginY: -0.5,
          }}
        />
      )}
    </Box>
  );
};

const OrderDetails = ({ parcelCode, onBackClick }) => {
  const theme = useTheme();
  const { errorHandler } = useErrorHandler();
  const dispatch = useDispatch();
  const { data: parcel } = useSelector(
    parcelSlice.selectors.getParcel(parcelCode)
  );
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const fetchParcel = useCallback(async () => {
    try {
      await dispatch(parcelSlice.actions.fetchParcel(parcelCode)).unwrap();
    } catch (error) {
      errorHandler(ORDERS_SECTION.NOTIFICATIONS.FETCHING_ERROR, error);
      throw new Error(error.message);
    }
  }, []);

  const {
    collectionDetails,
    deliveryDetails,
    parcelEvents = [],
    parcelNumber,
    parcelStatus,
    parcelStatusHtml,
    service,
  } = { ...parcel };

  let images = [];

  if (parcelEvents.length > 0) {
    const firstEvent = parcelEvents[0];
    images = firstEvent?.images || [];
  }

  const mapSrc = useMemo(
    () =>
      generateRouteMapStaticImageUrlByParcelCode({
        parcelCode,
        width: 600,
        height: 340,
      }),
    [parcelCode]
  );

  return (
    <Loader
      promiseFn={fetchParcel}
      pendingProps={{
        sx: { marginTop: { xs: '50px', md: '100px' } },
      }}
    >
      {parcel ? (
        <SectionWrapper
          titleLeftComponent={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <IconButton
                aria-label='back'
                sx={{ mr: 0.5, p: 0.5 }}
                onClick={onBackClick}
              >
                <ChevronLeft />
              </IconButton>
              <Box>
                <Typography>{deliveryDetails.address.organisation}</Typography>
                <Label sx={{ m: 0 }}>
                  {parcelNumber} • {service.serviceDescription}
                </Label>
              </Box>
            </Box>
          }
          titleRightComponent={
            <Typography
              color={theme.palette.parcelStatus[parcelStatus]}
              sx={{ ml: 2, mt: { xs: 2, md: 0 } }}
            >
              {parcelStatus}
            </Typography>
          }
        >
          <Box sx={{ maxWidth: 600 }}>
            <Label>Tracking</Label>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
                lineHeight: 0,
              }}
            >
              <Box
                sx={{
                  width: { xs: '100%', md: images.length > 0 ? '50%' : '100%' },
                }}
              >
                <Box
                  component='img'
                  src={mapSrc}
                  width='100%'
                  sx={{ borderRadius: 1 }}
                />
              </Box>
              {images.length > 0 ? (
                <>
                  <GalleryWidget
                    title={ORDER_DETAILS.GALLERY_TITLE}
                    images={images}
                    isOpen={isGalleryOpen}
                    onClose={() => setIsGalleryOpen(false)}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      width: { xs: '100%', md: '50%' },
                      position: 'relative',
                    }}
                  >
                    <Box
                      component='img'
                      width='100%'
                      src={getImageUrl(images[0])}
                      onError={e => (e.target.src = notFoundImg)}
                      sx={{ borderRadius: 1, cursor: 'pointer' }}
                      onClick={() => setIsGalleryOpen(true)}
                    />
                    <GalleryIconButton
                      imagesNumber={images.length}
                      onClick={() => setIsGalleryOpen(true)}
                    />
                  </Box>
                </>
              ) : null}
            </Box>
            <Text
              color={theme.palette.parcelStatus[parcelStatus]}
              sx={{
                borderBottom: 1,
                borderColor: theme.palette.grey[200],
                pb: 1,
              }}
              dangerouslySetInnerHTML={{ __html: parcelStatusHtml }}
            />
            <AddressDetails
              label={ORDER_DETAILS.RECIPIENT_ADDRESS}
              text={formatAddressLine(deliveryDetails.address)}
            />
            <AddressDetails
              label={ORDER_DETAILS.PICKUP_ADDRESS}
              text={formatAddressLine(collectionDetails.address)}
            />
            {parcelEvents.length > 0 ? (
              <>
                <Label>{ORDER_DETAILS.HISTORY}</Label>
                {parcelEvents.map((event, key) => (
                  <EventDetails
                    key={key}
                    date={event.date}
                    text={event.text}
                    color={
                      theme.palette.parcelStatus[event.status] ||
                      theme.palette.parcelStatus['Complete']
                    }
                    last={parcelEvents.length === key + 1}
                  />
                ))}
              </>
            ) : null}
          </Box>
          <Divider sx={{ mt: 2, marginX: -2 }} />
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, gap: 1 }}
          >
            <Button variant='outlined'>{ORDER_DETAILS.SEND_AGAIN}</Button>
            <Button variant='outlined' sx={{ pr: 1 }}>
              {ORDER_DETAILS.DOWNLOAD} <ArrowDropDown />
            </Button>
          </Box>
        </SectionWrapper>
      ) : null}
    </Loader>
  );
};

export default OrderDetails;
