export const TIME = 'HH:mm';
export const DAY = 'dddd';
export const EUROPE_LONDON_TIME_ZONE = 'Europe/London';

export const TIME_FORMAT = 'H:mm';
export const HH_MM_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DATAPICKER_DATE_FORMAT = 'dddd - DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';
