import { SvgIcon } from '@mui/material';

const LabelPrint = ({ styles }) => (
  <SvgIcon
    sx={{ width: '35px', height: '35px', ...styles }}
    viewBox='0 0 36 35'
  >
    <g clipPath='url(#clip0_6609_4314)'>
      <path
        d='M28.2948 27.7957H7.70654V7.20435H28.2948V27.7957ZM8.73595 26.7663H27.2654V8.23376H8.73595V26.7663Z'
        fill='black'
        fillOpacity='0.56'
      />
      <path
        d='M21.0881 15.4412H14.9116V9.77942C14.9116 9.49478 15.1417 9.26471 15.4263 9.26471C15.711 9.26471 15.941 9.49478 15.941 9.77942V14.4118H20.0587V9.77942C20.0587 9.49478 20.2888 9.26471 20.5734 9.26471C20.858 9.26471 21.0881 9.49478 21.0881 9.77942V15.4412Z'
        fill='#414042'
      />
      <path
        d='M21.0881 19.7343L19.3371 20.7843C19.3175 20.7957 19.2928 20.8018 19.2671 20.8018C19.2388 20.8018 19.212 20.7946 19.1914 20.7818L19.1374 20.7483L18.9593 20.6382C18.9197 20.614 18.8888 20.5584 18.8888 20.5121V20.3278V20.2362C18.8888 20.1893 18.9207 20.1234 18.9614 20.1003L18.9969 20.0787L20.7875 19.005L18.1286 17.5314C18.0663 17.4969 17.9644 17.4969 17.9026 17.5309L15.1603 19.0199L18.3401 20.8399C18.3813 20.8626 18.4127 20.9161 18.4127 20.9645V20.9768V21.1508V21.5533L18.4116 23.59C18.4116 23.6373 18.3792 23.6919 18.3375 23.714L18.0977 23.8468C18.0966 23.8473 18.0951 23.8473 18.0941 23.8479C18.0745 23.8576 18.0514 23.8633 18.0261 23.8633H18.0205C17.9916 23.8638 17.9649 23.8582 17.9443 23.8473L17.7935 23.764L17.7039 23.7146C17.6633 23.6929 17.6308 23.6379 17.6308 23.5905L17.6303 21.5538H17.6293V21.3793C17.6272 21.3546 17.6092 21.3248 17.5891 21.3135L14.9122 19.7652L14.9111 22.7927C14.9111 22.8637 14.9611 22.9512 15.0223 22.9873L17.9072 24.6776C17.9685 24.7136 18.0689 24.7131 18.1296 24.6771L20.9764 22.9852C21.0382 22.9461 21.0881 22.8586 21.0881 22.7876V19.7343Z'
        fill='#DC0032'
      />
    </g>
    <defs>
      <clipPath id='clip0_6609_4314'>
        <rect
          width='34.8'
          height='34.8'
          fill='white'
          transform='translate(0.599609)'
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default LabelPrint;
