export const PHONE_NUMBER_REGEXP = /^(\+44|0044)?0?7[0-9]{9}$/;

export const COMMON_PHONE_NUMBER_REGEXP = /^\+?\d{1,15}$/;

export const UK_POST_CODE_FULL =
  /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const COUNTRY_CODE = /^[A-Z][A-Z]$/;
