import { useState } from 'react';
import Sentry from '../sentry';
import { useCustomSnackbar } from '../features/CustomSnackbar';

const useErrorHandler = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { showError } = useCustomSnackbar();

  const errorHandler = (message, error, component) => {
    setErrorMessage(message);

    showError({ message });

    Sentry.captureException(error, { component, message });
  };

  return {
    errorMessage,
    setErrorMessage,
    errorHandler,
  };
};

export default useErrorHandler;
