import { useEffect } from 'react';
import { Typography } from '@mui/material';
import useAnalytics from '../../utils/useAnalytics';
import { RETURN } from '../../constants/analytics';

const Return = () => {
  const { setInterfaceId, Trackable } = useAnalytics();

  useEffect(() => {
    setInterfaceId(RETURN.INTERFACE_ID);
  }, []);
  return (
    <Trackable loadId={RETURN.LOAD}>
      <Typography variant='h2' sx={{ m: 2 }}>
        Return page placeholder
      </Typography>
    </Trackable>
  );
};

export default Return;
