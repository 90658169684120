import { Box, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';

const ListWithTitle = ({ title, listItems }) => (
  <Box>
    <Typography variant='h2'>{title}</Typography>
    <List
      sx={{
        marginBottom: '32px',
        paddingBottom: 0,
      }}
    >
      {listItems.map(item => (
        <ListItem
          key={item}
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Typography
            variant={'body1'}
            sx={{
              '&::before': {
                content: '"•"',
                color: 'text.primary',
                display: 'inline-block',
                width: '1em',
                marginLeft: '-1em',
              },
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px' /* 24px */,
              letterSpacing: '0.15px',
            }}
          >
            {item}
          </Typography>
        </ListItem>
      ))}
    </List>
  </Box>
);

export default ListWithTitle;
