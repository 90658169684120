export const trimFirstSpace = value => {
  if (typeof value === 'string') {
    return value.replace(/^\s+/, '');
  }
  return value;
};

export const fieldTrimOnBlur = {
  formatOnBlur: true,
  format: value => (typeof value === 'string' ? value.trim() : ''),
};
