import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useOverlay } from '../../features/Overlay';
import { useAuth } from '../../features/Auth/Context';
import loginImg2 from '../../assets/images/loginImg2.png';
import {
  ANOTHER_EMAIL_CHECK,
  BACK_TO_HOME,
  CHECK_SPAM_TOO,
  CHECK_YOUR_EMAIL,
  CHECK_YOUR_EMAIL_DESCRIPTION,
  EMAIL_NOT_RECEIVE,
  SEND_ON_EMAIL_$,
} from '../../constants/strings';
import { CheckEmail } from '../../components/icons';
import { EMAIL_SENT } from '../../constants/analytics';
import useAnalytics from '../../utils/useAnalytics';
import { TOO_MANY_REQUESTS_CODE } from '../../constants/error';
import { VerifyEmailSlice } from './slice';

const EmailSent = ({ values, setValues, previousStep, goToNamedStep }) => {
  const overlay = useOverlay();
  const auth = useAuth();
  const email = values?.email;
  const [resendError, setResendError] = useState(null);
  const { trackAction } = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!email) {
      previousStep();
    }

    if (auth.currentSession.uid) {
      navigate('/', { replace: true });
    }
  }, []);

  const onResendEmail = useCallback(
    async event => {
      try {
        trackAction(event);
        overlay.show();
        await auth.sendMagicLink(email).unwrap();
        setValues({ ...values, attempts: values.attempts + 1 });
      } catch (error) {
        if (error.code === TOO_MANY_REQUESTS_CODE) {
          dispatch(VerifyEmailSlice.actions.clearAll());
          goToNamedStep('error', { error });
        } else {
          setResendError(error.message);
        }
      } finally {
        overlay.hide();
      }
    },
    [overlay, auth, email]
  );

  const handleToHome = useCallback(
    event => {
      trackAction(event);
      dispatch(VerifyEmailSlice.actions.clearAll());
      navigate('/', { replace: true });
    },
    [dispatch, navigate, trackAction]
  );

  return (
    <Trackable interfaceId={EMAIL_SENT.INTERFACE_ID} loadId={EMAIL_SENT.LOAD}>
      <Box
        sx={{
          flexGrow: 1,
          p: { xs: 3, md: 2 },
          display: 'flex',
          justifyContent: { xs: 'center', md: 'start' },
          width: '100%',
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img src={loginImg2} style={{ width: '100%' }} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: { sx: 0, md: '78px' },
            justifyContent: 'center',
            maxWidth: '500px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CheckEmail styles={{ alignSelf: 'center' }} />
          <Typography variant='h2' sx={{ mt: 2, textAlign: 'center' }}>
            {values.attempts > 1 ? ANOTHER_EMAIL_CHECK : CHECK_YOUR_EMAIL}
          </Typography>
          <Typography
            sx={{ mt: 2, textAlign: 'center', overflowWrap: 'break-word' }}
          >
            {SEND_ON_EMAIL_$(email)}
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            {CHECK_YOUR_EMAIL_DESCRIPTION}
          </Typography>
          {values.attempts > 1 && (
            <Typography sx={{ mt: 3, textAlign: 'center' }}>
              {CHECK_SPAM_TOO}
            </Typography>
          )}
          {resendError && (
            <Box>
              <Typography>{resendError}</Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              gap: 2,
              mt: { xs: 5, md: 0 },
              alignItems: { xs: 'center', md: 'flex-end' },
            }}
          >
            <Button
              sx={{
                mt: { md: 4 },
                alignSelf: { md: 'flex-end' },
              }}
              type='reset'
              onClick={onResendEmail}
              variant='outlined'
              color='inherit'
              actionid={EMAIL_SENT.ON_RESEND}
            >
              {EMAIL_NOT_RECEIVE}
            </Button>

            <Button
              sx={{
                mt: { md: 4 },
                alignSelf: { md: 'flex-end' },
              }}
              onClick={handleToHome}
              variant='contained'
              actionid={EMAIL_SENT.ON_BACK}
            >
              {BACK_TO_HOME}
            </Button>
          </Box>
        </Box>
      </Box>
    </Trackable>
  );
};

export default EmailSent;
