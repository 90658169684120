import { SvgIcon } from '@mui/material';

const PrimaryCube = () => (
  <SvgIcon>
    <svg
      width='22'
      height='24'
      viewBox='0 0 22 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='primaryCubeBlack'
        d='M20.6469 5.19632L20.3655 5.37503C18.3233 6.57575 16.2867 7.78206 14.239 8.9772C13.9959 9.08792 13.8526 9.34618 13.8857 9.61386C13.9244 10.3287 13.8857 10.2394 14.4708 10.608C14.6784 10.7721 14.9679 10.779 15.1828 10.6247C17.0594 9.50775 18.947 8.3908 20.8236 7.27385L21.1658 7.07838C21.1699 7.15651 21.1699 7.23481 21.1658 7.31294V17.5386C21.1731 17.8856 20.9787 18.2047 20.669 18.354L14.3218 22.1572C13.4 22.7157 12.4728 23.2742 11.5621 23.8327C11.2392 24.0558 10.8142 24.0558 10.4914 23.8327C7.45571 22.0009 4.42008 20.184 1.38444 18.3819C1.02759 18.1989 0.811139 17.82 0.832503 17.4158C0.832503 14.0649 0.832503 10.7308 0.832503 7.41347C0.832503 7.31294 0.832503 7.218 0.832503 7.07838L1.13607 7.24592L9.77384 12.3281C9.97056 12.4147 10.089 12.6204 10.0664 12.8363C10.0664 15.3159 10.0664 17.79 10.0664 20.2696V20.5209C10.0592 20.6865 10.1504 20.8403 10.2982 20.9118L10.7618 21.1743C10.9077 21.2637 11.0906 21.2637 11.2365 21.1743L11.678 20.9286C11.8371 20.8596 11.9349 20.6955 11.9209 20.5209C11.9209 20.4539 11.9209 20.3869 11.9209 20.3199V11.898C11.9209 11.8198 11.9209 11.7472 11.9209 11.6691C11.9261 11.5158 11.8448 11.3728 11.7111 11.3005L11.0102 10.8872L1.67144 5.37503L1.38444 5.2019L1.61625 5.03994L10.4472 0.158859C10.7854 -0.052953 11.2129 -0.052953 11.5511 0.158859C14.5039 1.80078 17.4457 3.41478 20.3875 5.03994L20.6469 5.19632Z'
        fill='#DC0032'
      />
    </svg>
  </SvgIcon>
);

export default PrimaryCube;
