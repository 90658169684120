import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ParcelsTable } from '../OrdersSection/components';
import useNormalizeParcels from '../OrdersSection/hooks/useNormalizeParcels';
import { AddressBookTable } from '../AddressesSection/components';
import {
  OVERVIEW_ADDRESS_BOOK_TABLE_CONFIG,
  RECENT_PARCELS_TABLE_CONFIG,
} from '../../../constants/table';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import {
  ADDRESSES_SECTION,
  ORDERS_SECTION,
  OVERVIEW_SECTION,
} from '../../../constants/strings';
import {
  getAccountDetailsFields,
  fetchAccountDetails,
} from '../../../redux/accountDetailsSlice';
import parcelsSlice from '../../../redux/parcelsSlice';
import addressBooksSlice from '../../../redux/addressBooksSlice';
import { DEFAULT_PAGINATION_SEARCH_OVERVIEW_SIZE } from '../../../constants/pagination';
import useBreakpoint from '../../../hooks/useBreakpoint';
import useErrorHandler from '../../../hooks/useErrorHandler';
import Loader from '../../../features/Loader/Loader';

const OverviewSection = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const isSmallScreen = useBreakpoint('sm');
  const navigate = useNavigate();
  const { errorHandler } = useErrorHandler();
  const dispatch = useDispatch();
  const sectionStyle = { mt: 2 };

  const { email, firstName, lastName, phone } =
    useSelector(state => getAccountDetailsFields(state)) || {};

  const accountFields = [
    {
      label: OVERVIEW_SECTION.NAME,
      value: [firstName, lastName].filter(i => i).join(' '),
    },
    {
      label: OVERVIEW_SECTION.EMAIL_ADDRESS,
      value: email,
    },
    {
      label: OVERVIEW_SECTION.PHONE_NUMBER,
      value: phone,
    },
  ];

  const parcels = useSelector(parcelsSlice.selectors.getParcels);
  const normalizedParcels = useNormalizeParcels(parcels);
  // temporary workaround
  // TODO: revert when getParcels endpoint is able to handle different pagination options
  if (normalizedParcels.length > 0) {
    normalizedParcels.length = 3;
  }

  const addressBooks = useSelector(state =>
    addressBooksSlice.selectors.getAddressBooksByPage(state, {
      page: 1,
      pageSize: DEFAULT_PAGINATION_SEARCH_OVERVIEW_SIZE,
    })
  );

  const fetchParcels = useCallback(async () => {
    try {
      await dispatch(
        parcelsSlice.actions.fetchParcels({
          searchPage: 1,
          // temporary workaround
          // TODO: revert to "3" when getParcels endpoint is able to handle different pagination options
          //searchPageSize: DEFAULT_PAGINATION_SEARCH_OVERVIEW_SIZE,
          searchPageSize: 25,
        })
      ).unwrap();
    } catch (error) {
      errorHandler(ORDERS_SECTION.NOTIFICATIONS.FETCHING_ERROR, error);
    }
  }, [dispatch]);

  const fetchAddressBooks = useCallback(async () => {
    try {
      await dispatch(addressBooksSlice.actions.fetchAddressBooks());
    } catch (error) {
      errorHandler(ADDRESSES_SECTION.NOTIFICATIONS.ERROR.FETCHING_ERROR, error);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAccountDetails());
  }, [dispatch]);

  return (
    <Box
      sx={{
        background: theme => theme.palette.primary.pageBackground,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 2,
        paddingBottom: 4,
        paddingX: isSmallScreen ? 0 : 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '680px',
        }}
      >
        {firstName && (
          <Typography variant='h1' sx={{ textAlign: 'center', m: 2 }}>
            {OVERVIEW_SECTION.GREETINGS_$(firstName)}
          </Typography>
        )}
        <Typography variant='body1' sx={{ textAlign: 'center', paddingX: 2 }}>
          {OVERVIEW_SECTION.TRACK_PARCELS}
        </Typography>

        <SectionWrapper
          titleLeftComponent={
            <Typography>{OVERVIEW_SECTION.YOUR_RECENT_ORDERS}</Typography>
          }
          titleRightComponent={
            parcels?.length > 0 ? (
              <Button
                variant='outlined'
                size='small'
                onClick={() => navigate('/profile/orders')}
              >
                {OVERVIEW_SECTION.VIEW_ALL}
              </Button>
            ) : null
          }
          sx={sectionStyle}
        >
          <Loader promiseFn={fetchParcels}>
            <ParcelsTable
              rows={normalizedParcels}
              config={RECENT_PARCELS_TABLE_CONFIG}
            />
          </Loader>
        </SectionWrapper>
        <SectionWrapper
          titleLeftComponent={
            <Typography>{OVERVIEW_SECTION.YOUR_ADDRESSES}</Typography>
          }
          titleRightComponent={
            addressBooks?.length > 0 ? (
              <Button
                variant='outlined'
                size='small'
                onClick={() => navigate('/profile/addresses')}
              >
                {OVERVIEW_SECTION.VIEW_ALL}
              </Button>
            ) : null
          }
          sx={sectionStyle}
        >
          <Loader promiseFn={fetchAddressBooks}>
            <AddressBookTable
              rows={addressBooks}
              config={OVERVIEW_ADDRESS_BOOK_TABLE_CONFIG}
              searchPageSize={DEFAULT_PAGINATION_SEARCH_OVERVIEW_SIZE}
              isSidebarOpen={isSidebarOpen}
              isEditMode={isEditMode}
              onEditRowHandler={value => {
                setIsEditMode(value);
                setSidebarOpen(value);
              }}
            />
          </Loader>
        </SectionWrapper>
        <SectionWrapper
          titleLeftComponent={
            <Typography>{OVERVIEW_SECTION.ACCOUNT_SUMMARY}</Typography>
          }
          titleRightComponent={
            <Button
              variant='outlined'
              size='small'
              onClick={() => navigate('/profile/account')}
            >
              {OVERVIEW_SECTION.MANAGE_ACCOUNT}
            </Button>
          }
          sx={sectionStyle}
        >
          {accountFields.map(field =>
            field.value ? (
              <>
                <Typography variant='caption' color='GrayText'>
                  {field.label}
                </Typography>
                <Typography variant='body2' sx={{ mb: 1 }}>
                  {field.value}
                </Typography>
              </>
            ) : null
          )}
        </SectionWrapper>
      </Box>
    </Box>
  );
};

export default OverviewSection;
