import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { Marker } from 'react-map-gl';
import PickupPinShop from '../../icons/PickupPinShop';
import PickupPinLocker from '../../icons/PickupPinLocker';
import { PICKUP_POINT_TYPE } from '../../../constants/pickupPoint';

const StyledPickupPinShop = styled(PickupPinShop, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' && prop !== 'isActive' && prop !== 'interactive',
})(({ interactive }) => ({
  width: '2.7rem',
  height: '3.4rem',
  ...(interactive && {
    cursor: 'pointer',
  }),
}));

const StyledPickupPinLocker = styled(PickupPinLocker, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' && prop !== 'isActive' && prop !== 'interactive',
})(({ interactive }) => ({
  width: '2.7rem',
  height: '3.4rem',
  ...(interactive && {
    cursor: 'pointer',
  }),
}));

const PickupShop = ({
  pickupShop,
  number,
  interactive,
  onPickupClick,
  isSelected,
  isActive,
}) => {
  const onClick = useCallback(
    () => onPickupClick(pickupShop),
    [onPickupClick, pickupShop]
  );

  return (
    <Marker
      latitude={pickupShop.pickupLocation.addressPoint.latitude}
      longitude={pickupShop.pickupLocation.addressPoint.longitude}
      key={pickupShop.pickupLocationCode}
      anchor='bottom'
      onClick={onClick}
      style={{ zIndex: (isSelected && 2) || (isActive && 3) || 1 }}
    >
      {pickupShop &&
      pickupShop.pickupLocation.kind == PICKUP_POINT_TYPE.SHOP ? (
        <StyledPickupPinShop
          isSelected={isSelected}
          isActive={isActive}
          interactive={interactive}
          label={number}
        />
      ) : (
        <StyledPickupPinLocker
          isSelected={isSelected}
          isActive={isActive}
          interactive={interactive}
          label={number}
        />
      )}
    </Marker>
  );
};

export default PickupShop;
