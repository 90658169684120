import { Box, styled } from '@mui/material';
import MapBox from '../../components/MapBox';

const MapContainer = styled(Box)({
  display: 'flex',
  flexGrow: 1,
});

function MapSandbox() {
  return (
    <MapContainer id='map'>
      <MapBox />
    </MapContainer>
  );
}

export default MapSandbox;
