import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ADDRESSES_SECTION } from '../../../constants/strings';
import { DEFAULT_PAGINATION_SEARCH_PAGE_SIZE } from '../../../constants/pagination';
import { SectionLayout } from '../components';
import addressBooksSlice from '../../../redux/addressBooksSlice';
import Loader from '../../../features/Loader/Loader';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { ADDRESS_BOOK_TABLE_CONFIG } from '../../../constants/table';
import { AddressBookTable, AddressBookBulkUploadModal } from './components';

const AddressesSection = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);

  const { errorHandler } = useErrorHandler();
  const dispatch = useDispatch();

  const addressBooks = useSelector(state =>
    addressBooksSlice.selectors.getAddressBooksByPage(state, {
      page: currentPage,
      pageSize: DEFAULT_PAGINATION_SEARCH_PAGE_SIZE,
    })
  );

  const fetchAddressBooks = useCallback(async () => {
    try {
      const data = await dispatch(
        addressBooksSlice.actions.fetchAddressBooks({
          searchPage: currentPage,
          searchPageSize: DEFAULT_PAGINATION_SEARCH_PAGE_SIZE,
        })
      ).unwrap();
      setTotalPages(data.totalPages);
    } catch (error) {
      errorHandler(ADDRESSES_SECTION.NOTIFICATIONS.ERROR.FETCHING_ERROR, error);
    }
  }, [currentPage, dispatch]);

  return (
    <SectionLayout
      title={ADDRESSES_SECTION.SECTION_TITLE}
      actionButtons={
        <Box
          sx={{
            marginTop: { xs: 1, sm: 0 },
            display: 'flex',
            justifyContent: { xs: 'space-between', md: 'flex-start' },
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              fontSize: '13px',
              flexGrow: { xs: 1, md: 0 },
            }}
            variant='outlined'
            endIcon={<FileUploadOutlinedIcon />}
            onClick={() => setIsBulkUpdateModalOpen(true)}
          >
            {ADDRESSES_SECTION.BULK_UPLOAD}
          </Button>
          <Button
            sx={{
              ml: { xs: 1, md: 2 },
              fontSize: '13px',
              flexGrow: { xs: 1, md: 0 },
            }}
            variant='outlined'
            onClick={() => {
              setIsEditMode(false);
              setSidebarOpen(true);
            }}
          >
            {ADDRESSES_SECTION.ADD_NEW_ADDRESS}
          </Button>
        </Box>
      }
      content={
        <Loader
          promiseFn={fetchAddressBooks}
          pendingProps={{
            sx: { marginTop: { xs: '50px', md: '100px' } },
          }}
        >
          <AddressBookTable
            rows={addressBooks}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            config={ADDRESS_BOOK_TABLE_CONFIG}
            searchPageSize={DEFAULT_PAGINATION_SEARCH_PAGE_SIZE}
            isSidebarOpen={isSidebarOpen}
            isEditMode={isEditMode}
            onEditRowHandler={value => {
              setIsEditMode(value);
              setSidebarOpen(value);
            }}
          />
        </Loader>
      }
      currentPage={currentPage}
      totalPages={totalPages}
      onPaginationChange={setCurrentPage}
    >
      <AddressBookBulkUploadModal
        isOpen={isBulkUpdateModalOpen}
        onClose={() => setIsBulkUpdateModalOpen(false)}
        currentPage={currentPage}
      />
    </SectionLayout>
  );
};

export default AddressesSection;
