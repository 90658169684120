import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import useBreakpoint from '../../../hooks/useBreakpoint';
import OrderDetails from '../../../components/OrderDetails/index';

const OrderView = () => {
  const isSmallScreen = useBreakpoint('sm');
  const { parcelCode } = useParams();
  const navigate = useNavigate();
  const navigateToOrders = () => navigate('/profile/orders');

  return (
    <Box
      sx={{
        background: theme => theme.palette.primary.pageBackground,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 2,
        paddingBottom: 6,
        paddingX: isSmallScreen ? 0 : 1,
      }}
    >
      <OrderDetails parcelCode={parcelCode} onBackClick={navigateToOrders} />
    </Box>
  );
};

export default OrderView;
