import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  MenuItem,
  Menu,
  IconButton,
  styled,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useAnalytics from '../../utils/useAnalytics';
import { BASKET } from '../../constants/analytics';
import { CONFIRMATION, VIEW_NEARBY_PICKUPS } from '../../constants/strings';
import PickupModal from '../../components/PickupModal';
import ShareModal from '../../components/ShareModal/ShareModal';
import useBreakpoint from '../../hooks/useBreakpoint';
import { DROP_OFF_STEPS_CONFIG } from './config';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const ActionMenuBtn = ({ actionMenuBtnHandleClick }) => (
  <IconButton
    sx={{ paddingY: 0 }}
    disableRipple
    aria-label='more'
    onClick={e => actionMenuBtnHandleClick(e)}
  >
    <MoreVertIcon />
  </IconButton>
);

const DropOffLabel = ({ pickupPoint }) => {
  const theme = useTheme();
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);

  const { setInterfaceId, Trackable } = useAnalytics();

  const handlePickupPointChange = point => {
    setSelectedPickupPoint(point);
  };

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  useEffect(() => {
    setInterfaceId(BASKET.INTERFACE_ID);
  }, []);

  const actionEditBtnHandleClick = (e, row) => {
    setActionMenuAnchorEl(e.currentTarget);
  };
  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
  };

  const isSmallScreen = useBreakpoint('sm');

  return (
    <Trackable loadId={BASKET.LOAD}>
      <Grid sx={{ background: theme.palette.primary.pageBackground, pb: 4 }}>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid
            alignItems='center'
            justifyContent='center'
            spacing={2}
            sx={{ mt: 0 }}
          >
            <Grid item xs={12} md={12}>
              <Grid sx={{ mb: 4, mt: 4 }} item>
                <Typography variant='body1' color=''>
                  {CONFIRMATION.THANK_YOU}
                </Typography>
                <Typography variant='h2' sx={{ mt: 1 }} color=''>
                  {CONFIRMATION.ALL_DONE}
                </Typography>
              </Grid>
              <Grid sx={{ mt: 1 }}>
                <Paper
                  sx={{ p: 0, maxWidth: isSmallScreen ? '100%' : '400px' }}
                >
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='h3'>
                      {CONFIRMATION.HOME_COLLECTION_INSTRUCTIONS}
                    </Typography>
                    <ActionMenuBtn
                      actionMenuBtnHandleClick={actionEditBtnHandleClick}
                    />
                  </Box>
                  <Menu
                    anchorEl={actionMenuAnchorEl}
                    open={!!actionMenuAnchorEl}
                    onClose={actionMenuBtnHandleClose}
                    MenuListProps={{
                      sx: { width: '154px' },
                    }}
                    anchorOrigin={{
                      horizontal: 'left',
                      vertical: 'bottom',
                    }}
                    transformOrigin={{
                      horizontal: 120,
                      vertical: 90,
                    }}
                  >
                    <StyledMenuItem disableRipple theme={theme}>
                      {CONFIRMATION.DOWNLOAD_INVOICE}
                    </StyledMenuItem>
                    <StyledMenuItem
                      disableRipple
                      theme={theme}
                      onClick={handleOpenShareModal}
                    >
                      {CONFIRMATION.SHARE_PARCEL_INFO}
                    </StyledMenuItem>
                  </Menu>
                  <Divider />

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                        padding: theme.spacing(2),
                        gap: theme.spacing(2),
                      }}
                    >
                      {DROP_OFF_STEPS_CONFIG.map(step => (
                        <Box
                          key={step.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            marginBottom: theme.spacing(2),
                            gap: theme.spacing(2),
                          }}
                        >
                          <step.icon
                            style={{ width: '35px', height: '35px' }}
                          />
                          <Typography
                            variant='body2'
                            sx={{ display: 'inline-flex' }}
                          >
                            <span style={{ marginRight: theme.spacing(1) }}>
                              {step.id}.
                            </span>
                            <span>{step.text}</span>
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Divider />
                  <Box sx={{ pt: 0, display: 'flex', flexDirection: 'column' }}>
                    <Button
                      href='/order'
                      sx={{ mx: 1, mb: 0 }}
                      variant='contained'
                    >
                      {CONFIRMATION.DOWNLOAD_LABEL_TO_PRINT}
                    </Button>
                    <PickupModal
                      label={VIEW_NEARBY_PICKUPS}
                      id='pickup-modal'
                      variant='outlined'
                      disabled={false}
                      sx={{ mt: 1 }}
                      selectedPickupPoint={selectedPickupPoint}
                      onChange={handlePickupPointChange}
                    />
                    <Button
                      href='/order'
                      sx={{ mx: 1, mb: 2, color: theme.palette.primary.main }}
                      variant='none'
                    >
                      {CONFIRMATION.SEND_ANOTHER_PARCEL}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShareModal
          open={openShareModal}
          onClose={() => setOpenShareModal(false)}
        />
      </Grid>
    </Trackable>
  );
};

export default DropOffLabel;
