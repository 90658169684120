const getMobileOS = () => {
  const userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  } else {
    return 'Unknown';
  }
};

export default getMobileOS;
