import { Box, styled } from '@mui/material';
import { useEffect } from 'react';
import useAnalytics from '../../utils/useAnalytics';
import MapBox from '../../components/MapBox';
import { PICKUPFINDER } from '../../constants/analytics';

const ShopFinderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    width: '100%',
    height: '100vh',
  },
}));

const ShopFinder = () => {
  const { setInterfaceId, Trackable } = useAnalytics();

  useEffect(() => {
    setInterfaceId(PICKUPFINDER.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={PICKUPFINDER.LOAD}>
      <ShopFinderContainer id='shop-finder'>
        <MapBox />
      </ShopFinderContainer>
    </Trackable>
  );
};

export default ShopFinder;
