import { useEffect } from 'react';
import { Typography } from '@mui/material';
import useAnalytics from '../../utils/useAnalytics';
import { TRACK } from '../../constants/analytics';

const Track = () => {
  const { setInterfaceId, Trackable } = useAnalytics();

  useEffect(() => {
    setInterfaceId(TRACK.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={TRACK.LOAD}>
      <Typography variant='h2' sx={{ m: 2 }}>
        Track page placeholder
      </Typography>
    </Trackable>
  );
};

export default Track;
