import { get, cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Checkboxes, Radios, TextField } from 'mui-rff';
import { useDispatch, useSelector } from 'react-redux';

import { useSearchParams } from 'react-router-dom';
import {
  composeValidators,
  email,
  maxLength,
  phoneNumber,
  required,
} from '../../../../utils/validators';
import useAnalytics from '../../../../utils/useAnalytics';
import {
  ADDRESS_DETAILS,
  BACK,
  CHANGE_YOUR_PICKUP_POINT,
  NEXT_STEP,
  RECEIVER_PICKUP_POINT,
  YOUR_PICKUP_POINT,
} from '../../../../constants/strings';
import { ORDER_ADDRESS_DETAILS } from '../../../../constants/analytics';
import { SummaryDetails } from '../../../../components/SummaryDetails/index';
import InformationCollecting from '../../../../components/InformationCollecting/index';
import { trim } from '../../../../utils/formatters';
import AddressWidget from '../../../../components/AddressWidget';
import WizardForm from '../../../../features/Wizard/Form';
import PickupMapImage from '../../../../components/MapBox/components/PickupMapImage';
import { useReferencesLoader } from '../../hooks';
import { useOverlay } from '../../../../features/Overlay';
import { REFERENCE_NAME, USER_TYPE } from '../../constants';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { BasketActions } from '../../../../redux/basket';
import * as STRINGS from '../../../../constants/strings';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import * as AddressDetailsSelectors from './selectors';

// Form field wrapper styled for flex layout
const FieldWrapper = ({ children, sx }) => (
  <Box
    sx={{
      width: { xs: '100%', md: '50%' },
      p: 1,
      pb: 2,
      ...sx,
    }}
  >
    {children}
  </Box>
);

const AddressDetails = ({ references, nextStep, previousStep, values }) => {
  const { setInterfaceId, Trackable, trackAction } = useAnalytics();
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const snackbar = useCustomSnackbar();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const companyNameRef = useRef(null);
  const receiverCompanyNameRef = useRef(null);
  const overlay = useOverlay();
  const referencesLoader = useReferencesLoader();
  const reduxInitialValues = useSelector(
    AddressDetailsSelectors.getInitialFormValues
  );

  const initialValues = useMemo(() => reduxInitialValues, []);

  const companyName = get(values, SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY);
  const receiverCompanyName = get(
    values,
    SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
  );

  useEffect(() => {
    setInterfaceId(ORDER_ADDRESS_DETAILS.INTERFACE_ID);
    overlay.show();
    (async () =>
      Promise.all([
        referencesLoader.loadDropOffCollection(),
        referencesLoader.loadDeliveryPickup(),
        referencesLoader.loadCollectionAddress(),
        referencesLoader.loadDeliveryAddress(),
      ]))().finally(() => {
      overlay.hide();
    });
  }, []);

  useEffect(() => {
    if (receiverCompanyNameRef.current && receiverCompanyName?.length > 15) {
      receiverCompanyNameRef.current.focus();
      receiverCompanyNameRef.current.blur();
    }
    if (companyNameRef.current && companyName?.length > 15) {
      companyNameRef.current.focus();
      companyNameRef.current.blur();
    }
  }, [receiverCompanyNameRef.current, companyNameRef.current]);

  const handleNext = event => {
    trackAction(event);
  };

  const handleBack = useCallback(
    event => {
      trackAction(event);
      previousStep();
    },
    [previousStep]
  );

  const onSubmit = useCallback(
    async formData => {
      try {
        overlay.show();
        const values = cloneDeep(formData);
        const basketItemId = searchParams.get('basketItemId');

        const updatedBasketItem = {
          basketItemId: basketItemId,
          shipment: values,
        };
        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();
        nextStep(values);
      } catch (e) {
        snackbar.showError({
          message: STRINGS.FAILED_TO_SAVE_BASKET,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, nextStep, overlay, searchParams]
  );

  const activeCollectionPickupPoint =
    references[REFERENCE_NAME.COLLECTION_PICKUP];

  const activeDestinationPickupPoint =
    references[REFERENCE_NAME.DELIVERY_PICKUP];

  return (
    <Trackable loadId={ORDER_ADDRESS_DETAILS.LOAD}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={8}>
          <WizardForm initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, submitting, invalid, values }) => {
              setIsInvalid(invalid);
              setIsSubmitting(submitting);
              return (
                <form id='addressForm' onSubmit={handleSubmit}>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant='h3'>
                      {ADDRESS_DETAILS.DETAILS_TITLE}
                    </Typography>
                    <FormControl component='fieldset' sx={{ p: 2 }}>
                      <Radios
                        radioGroupProps={{
                          row: true,
                        }}
                        name={SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY}
                        required={true}
                        data={[
                          {
                            label: ADDRESS_DETAILS.USER_TYPE.INDIVIDUAL.LABEL,
                            value: USER_TYPE.INDIVIDUAL,
                          },
                          {
                            label: ADDRESS_DETAILS.USER_TYPE.COMPANY.LABEL,
                            value: USER_TYPE.COMPANY,
                          },
                        ]}
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        margin: -1,
                      }}
                    >
                      {get(values, SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY) ===
                        USER_TYPE.COMPANY && (
                        <FieldWrapper sx={{ width: '100%' }}>
                          <TextField
                            label={ADDRESS_DETAILS.COMPANY_NAME}
                            fullWidth
                            name={SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY}
                            required={true}
                            inputRef={companyNameRef}
                            fieldProps={{
                              validate: composeValidators(
                                required,
                                maxLength(35)
                              ),
                              formatOnBlur: true,
                              format: trim,
                            }}
                          />
                        </FieldWrapper>
                      )}
                      <FieldWrapper>
                        <TextField
                          label={ADDRESS_DETAILS.CONTACT_NAME}
                          fullWidth
                          name={SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY}
                          required
                          fieldProps={{
                            validate: composeValidators(
                              required,
                              maxLength(35)
                            ),
                            formatOnBlur: true,
                            format: trim,
                          }}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <TextField
                          label={ADDRESS_DETAILS.EMAIL}
                          fullWidth
                          name={SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY}
                          required={true}
                          fieldProps={{
                            validate: composeValidators(
                              required,
                              email,
                              maxLength(100)
                            ),
                            formatOnBlur: true,
                            format: trim,
                          }}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <TextField
                          label={ADDRESS_DETAILS.PHONE}
                          fullWidth
                          name={
                            SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY
                          }
                          required={true}
                          fieldProps={{
                            validate: composeValidators(required, phoneNumber),
                          }}
                        />
                      </FieldWrapper>
                      <AddressWidget
                        address={get(
                          values,
                          SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY
                        )}
                        coordinates={{
                          longitude: get(
                            references,
                            `${REFERENCE_NAME.COLLECTION_ADDRESS}.Longitude`
                          ),
                          latitude: get(
                            references,
                            `${REFERENCE_NAME.COLLECTION_ADDRESS}.Latitude`
                          ),
                        }}
                        onEditClick={handleBack}
                      />
                      <FormControl sx={{ p: 1, pl: 2 }}>
                        <Checkboxes
                          name='saveForNextTime'
                          data={{
                            label: ADDRESS_DETAILS.SAVE_FOR_NEXT_TIME,
                            value: 1,
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Paper>
                  {activeCollectionPickupPoint && (
                    <PickupMapImage
                      title={YOUR_PICKUP_POINT}
                      pickupPoint={activeCollectionPickupPoint}
                      buttonLabel={CHANGE_YOUR_PICKUP_POINT}
                      buttonOnClick={handleBack}
                    />
                  )}
                  <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant='h3'>
                      {ADDRESS_DETAILS.RECEIVER_DETAILS_TITLE}
                    </Typography>
                    <FormControl component='fieldset' sx={{ p: 2 }}>
                      <Radios
                        radioGroupProps={{
                          row: true,
                        }}
                        name={SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY}
                        required={true}
                        data={[
                          {
                            label: ADDRESS_DETAILS.USER_TYPE.INDIVIDUAL.LABEL,
                            value: USER_TYPE.INDIVIDUAL,
                          },
                          {
                            label: ADDRESS_DETAILS.USER_TYPE.COMPANY.LABEL,
                            value: USER_TYPE.COMPANY,
                          },
                        ]}
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        margin: -1,
                      }}
                    >
                      {get(values, SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY) ===
                        USER_TYPE.COMPANY && (
                        <FieldWrapper sx={{ width: '100%' }}>
                          <TextField
                            label={ADDRESS_DETAILS.COMPANY_NAME}
                            fullWidth
                            name={SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY}
                            required={true}
                            inputRef={receiverCompanyNameRef}
                            fieldProps={{
                              validate: composeValidators(
                                required,
                                maxLength(35)
                              ),
                              formatOnBlur: true,
                              format: trim,
                            }}
                          />
                        </FieldWrapper>
                      )}
                      <FieldWrapper>
                        <TextField
                          label={ADDRESS_DETAILS.RECEIVER_CONTACT_NAME}
                          fullWidth
                          name={SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY}
                          required={true}
                          fieldProps={{
                            validate: composeValidators(
                              required,
                              maxLength(35)
                            ),
                            formatOnBlur: true,
                            format: trim,
                          }}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <TextField
                          label={ADDRESS_DETAILS.RECEIVER_EMAIL}
                          fullWidth
                          name={SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY}
                          required={true}
                          fieldProps={{
                            validate: composeValidators(
                              required,
                              email,
                              maxLength(100)
                            ),
                            formatOnBlur: true,
                            format: trim,
                          }}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <TextField
                          label={ADDRESS_DETAILS.RECEIVER_PHONE}
                          fullWidth
                          name={SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY}
                          required={true}
                          fieldProps={{
                            validate: composeValidators(required, phoneNumber),
                          }}
                        />
                      </FieldWrapper>
                      <AddressWidget
                        address={get(
                          values,
                          SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY
                        )}
                        coordinates={{
                          longitude: get(
                            references,
                            `${REFERENCE_NAME.DELIVERY_ADDRESS}.Longitude`
                          ),
                          latitude: get(
                            references,
                            `${REFERENCE_NAME.DELIVERY_ADDRESS}.Latitude`
                          ),
                        }}
                        onEditClick={handleBack}
                      />
                      <FormControl sx={{ p: 1, pl: 2 }}>
                        <Checkboxes
                          name='saveForNextTimeReceiver'
                          data={{
                            label: ADDRESS_DETAILS.SAVE_FOR_NEXT_TIME,
                            value: 1,
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Paper>
                  {activeDestinationPickupPoint && (
                    <PickupMapImage
                      title={RECEIVER_PICKUP_POINT}
                      pickupPoint={activeDestinationPickupPoint}
                      buttonLabel={CHANGE_YOUR_PICKUP_POINT}
                      buttonOnClick={handleBack}
                    />
                  )}
                </form>
              );
            }}
          </WizardForm>

          <InformationCollecting sx={{ pl: 2, pr: 2 }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ position: 'sticky', top: theme.spacing(2) }}>
            <SummaryDetails />
            <Box
              sx={{
                gap: 1,
                display: 'flex',
                justifyContent: 'space-between',
                pl: { xs: 2, md: 0 },
                pr: { xs: 2, md: 0 },
              }}
            >
              <Button
                actionid={ORDER_ADDRESS_DETAILS.CLICK_BACK}
                variant='outlined'
                onClick={handleBack}
                sx={{ width: '100%' }}
              >
                {BACK}
              </Button>
              <Button
                actionid={ORDER_ADDRESS_DETAILS.CLICK_NEXT}
                variant='contained'
                onClick={handleNext}
                sx={{ width: '100%' }}
                form='addressForm'
                type='submit'
                disabled={isSubmitting || isInvalid}
              >
                {NEXT_STEP}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Trackable>
  );
};

export default AddressDetails;
