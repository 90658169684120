const LinkedinIcon = ({ ...props }) => (
  <svg
    width='33'
    height='32'
    viewBox='0 0 33 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.0056 0H16.9328C8.11892 0 0.973877 7.14713 0.973877 15.9636V16.0364C0.973877 24.8529 8.11892 32 16.9328 32H17.0056C25.8194 32 32.9645 24.8529 32.9645 16.0364V15.9636C32.9645 7.14713 25.8194 0 17.0056 0Z'
      fill='#383D38'
    />
    <path
      d='M8.60316 10.6359C8.18174 10.2446 7.97217 9.7603 7.97217 9.18405C7.97217 8.60779 8.18286 8.10217 8.60316 7.70978C9.02457 7.31851 9.56704 7.12231 10.2317 7.12231C10.8963 7.12231 11.4175 7.31851 11.8378 7.70978C12.2592 8.10105 12.4687 8.59322 12.4687 9.18405C12.4687 9.77487 12.258 10.2446 11.8378 10.6359C11.4163 11.0272 10.8817 11.2233 10.2317 11.2233C9.58161 11.2233 9.02457 11.0272 8.60316 10.6359ZM12.1146 12.8804V24.8763H8.3252V12.8804H12.1146Z'
      fill='#FEFFFC'
    />
    <path
      d='M24.729 14.0656C25.555 14.9624 25.9674 16.1934 25.9674 17.7608V24.6646H22.3686V18.2473C22.3686 17.4569 22.1635 16.8426 21.7544 16.4053C21.3453 15.9681 20.7939 15.7483 20.1035 15.7483C19.4131 15.7483 18.8617 15.967 18.4526 16.4053C18.0435 16.8426 17.8384 17.4569 17.8384 18.2473V24.6646H14.2183V12.8469H17.8384V14.4142C18.2049 13.8918 18.6992 13.4792 19.3201 13.1754C19.941 12.8716 20.6392 12.7202 21.4159 12.7202C22.799 12.7202 23.9041 13.1687 24.729 14.0644V14.0656Z'
      fill='#FEFFFC'
    />
  </svg>
);

export default LinkedinIcon;
