import apis from './apis';

export const getAccountDetails = () => apis.get(`/user/preferences/`);

export const updateAccountDetails = accountDetailsBody =>
  apis.put(`/user/preferences/`, accountDetailsBody);

export const updateCommunicationDetails = accountDetailsBody =>
  apis.put(`/user/preferences/communications`, accountDetailsBody);

export const deleteAccount = () => apis.delete(`/user/`);
