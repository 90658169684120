import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import Joi from 'joi';
import { OrderSliceSelectors } from '../../slice';
import { ObjectUtil } from '../../../../utils';
import {
  getMaxWeightAndParcelNumber,
  getNetworksByParcelSizeAndWeight,
  sortNetworksByPrice,
} from '../../../../utils/networks';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { POINT_TYPE } from '../../../../constants';
import { parcelSchema } from '../../validators/parcel';
import { requireKeysSchema } from '../../../../utils/validators';
import { REQUIRED_TYPE } from '../../../../constants/shipment';
import { ShipmentHelper } from '../../helpers';
import { REFERENCE_NAME } from '../../constants';

export const getOutboundNetworkParameters = createSelector(
  [OrderSliceSelectors.getValues],
  values => {
    const val = ObjectUtil.pickDeep(values, [
      'outboundConsignment.deliveryDetails.address.countryCode',
      'outboundConsignment.deliveryDetails.address.town',
      'outboundConsignment.deliveryDetails.address.county',
      'outboundConsignment.deliveryDetails.address.postcode',

      'outboundConsignment.collectionDetails.address.countryCode',
      'outboundConsignment.collectionDetails.address.town',
      'outboundConsignment.collectionDetails.address.county',
      'outboundConsignment.collectionDetails.address.postcode',

      'outboundConsignment.totalWeight',
      'outboundConsignment.numberOfParcels',
    ]);
    return val.outboundConsignment;
  }
);

export const validateParcelsValues = createSelector(
  [OrderSliceSelectors.getValues],
  values => {
    const { error } = Joi.array()
      .items(
        requireKeysSchema(parcelSchema(), [
          '_weight',
          '_quantity',
          '_width',
          '_height',
          '_length',
        ])
      )
      .min(1)
      .validate(values?.outboundConsignment?.parcels);
    return error;
  }
);

export const getFilteredNetworks = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  ({ networks }, values) => {
    let filteredNetworks = getNetworksByParcelSizeAndWeight(networks, values);

    const collectFrom = get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY);
    const deliveryTo = get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY);

    if (deliveryTo === POINT_TYPE.SHOP) {
      filteredNetworks = filteredNetworks.filter(
        ({ networkDetails }) => networkDetails?.shipToPickup === 'Y'
      );
    } else {
      filteredNetworks = filteredNetworks.filter(
        ({ price, networkDetails }) => {
          if (collectFrom === POINT_TYPE.SHOP) {
            return price.fromShop && networkDetails?.shipToPickup !== 'Y';
          } else {
            return price.fromDoor && networkDetails?.shipToPickup !== 'Y';
          }
        }
      );
    }

    return sortNetworksByPrice(
      filteredNetworks,
      collectFrom === POINT_TYPE.SHOP
    );
  }
);

export const getSelectedNetwork = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  (references, shipment) =>
    references.networks.find(
      n => n.networkKey === shipment.outboundConsignment.networkCode
    )
);

export const getMaxWeightAndNumberOfParcels = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  (references, values) => {
    const {
      networks,
      activeCollectionPickupPoint,
      activeDestinationPickupPoint,
    } = references;
    const dropAtShop =
      values?.outboundConsignment?.collectionDetails?.dropAtShop;

    return getMaxWeightAndParcelNumber({
      networks,
      collectionPickup: activeCollectionPickupPoint,
      deliveryPickup: activeDestinationPickupPoint,
      dropAtShop,
    });
  }
);

export const getParcelDetailsRequiredFields = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  () =>
    // do all business logic here to make fields required
    [
      'outboundConsignment.deliveryDetails._deliverTo',
      'outboundConsignment.deliveryDetails.address.countryCode',
      'outboundConsignment.deliveryDetails.address.postcode',

      'outboundConsignment.collectionDetails._collectFrom',
      'outboundConsignment.collectionDetails.address.countryCode',
      'outboundConsignment.collectionDetails.address.postcode',

      'outboundConsignment.networkCode',
      'outboundConsignment.totalWeight',
      'outboundConsignment.numberOfParcels',
    ]
);

export const getShipToShopAvailable = createSelector(
  [OrderSliceSelectors.getReferences],
  references => {
    const { networks } = references;

    return networks.some(
      ({ networkDetails }) =>
        networkDetails.shipToPickup === REQUIRED_TYPE.OPTIONAL
    );
  }
);

// @see: https://github.com/dpdgroupuk/customer-collections-web/blob/e991f0d05a5b7af805c8985ca9689e34a0900bfd/src/pages/CreateCollection/actions.js#L143
export const getCollectionDates = createSelector(
  [OrderSliceSelectors.getReferences],
  references => {
    const collectionDates = references[REFERENCE_NAME.COLLECTION_DATES];
    const cutOffDates = ShipmentHelper.getCutOffDates(collectionDates);
    return cutOffDates.map(ShipmentHelper.formatShipmentDate);
  }
);
