import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ORDERS_SECTION } from '../../../constants/strings';
import { PARCELS_TABLE_CONFIG } from '../../../constants/table';
import { DEFAULT_PAGINATION_SEARCH_PAGE_SIZE } from '../../../constants/pagination';
import SectionLayout from '../components/SectionLayout';
import { ParcelsTable } from '../OrdersSection/components';
import parcelsSlice from '../../../redux/parcelsSlice';
import Loader from '../../../features/Loader/Loader';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useNormalizeParcels from './hooks/useNormalizeParcels';

const OrdersSection = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { errorHandler } = useErrorHandler();
  const dispatch = useDispatch();

  const parcels = useSelector(parcelsSlice.selectors.getParcels);
  const normalizedParcels = useNormalizeParcels(parcels);

  const fetchParcels = useCallback(async () => {
    try {
      const data = await dispatch(
        parcelsSlice.actions.fetchParcels({
          searchPage: currentPage,
          searchPageSize: DEFAULT_PAGINATION_SEARCH_PAGE_SIZE,
        })
      ).unwrap();
      setTotalPages(data.totalPages);
    } catch (error) {
      errorHandler(ORDERS_SECTION.NOTIFICATIONS.FETCHING_ERROR, error);
    }
  }, [currentPage, dispatch]);

  return (
    <SectionLayout
      title={ORDERS_SECTION.SECTION_TITLE}
      content={
        <Loader
          promiseFn={fetchParcels}
          pendingProps={{
            sx: { marginTop: { xs: '50px', md: '100px' } },
          }}
        >
          <ParcelsTable
            rows={normalizedParcels}
            config={PARCELS_TABLE_CONFIG}
          />
        </Loader>
      }
      currentPage={currentPage}
      totalPages={totalPages}
      onPaginationChange={setCurrentPage}
    />
  );
};

export default OrdersSection;
