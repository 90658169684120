export const updateURLWithParams = (key, value) => {
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.pushState(null, '', url);
};

export const getURLParam = name => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const removeURLParam = key => {
  const url = new URL(window.location);
  url.searchParams.delete(key);
  window.history.pushState({}, '', url);
};
