import { Route, Routes } from 'react-router';
import Home from '../pages/Home/Home';
import VerifyEmail from '../pages/Auth/VerifyEmail';
import TermsAndConditions from '../pages/Auth/Terms';
import MapSandbox from '../pages/MapSandbox';
import Order from '../pages/Order';
import Quotes from '../pages/Quotes';
import DevRoutes from '../components/DevRoutes';
import Playground from '../pages/Playground';
import ShopFinder from '../pages/ShopFinder';
import Basket from '../pages/Basket';
import UserPreferences from '../pages/UserPreferences';
import AddressesSection from '../pages/UserPreferences/AddressesSection';
import {
  OrdersSection,
  OrderView,
} from '../pages/UserPreferences/OrdersSection';
import ProductAndServices from '../pages/ProductAndServices';
import Track from '../pages/Track';
import Return from '../pages/Return';
import { useAuth } from '../features/Auth/Context';

import {
  ACCOUNT,
  ADDRESSES,
  ORDERS,
  ORDER_VIEW,
  PICKUP_FINDER,
  PROFILE,
  SIGN_IN_WITH_EMAIL,
} from '../constants/routes';
import PaymentSuccess from '../pages/Order/components/PaymentSuccess';
import { AccountSection } from '../pages/UserPreferences/AccountSection/index.js';
import OverviewSection from '../pages/UserPreferences/OverviewSection/OverviewSection.jsx';
import DropOff from '../pages/Confirm/DropOff';
import DropOffLabel from '../pages/Confirm/DropOffLabel';
import Collection from '../pages/Confirm/Collection';
import ProtectedRoute from './ProtectedRoute';

function AppRoutes() {
  const { currentSession } = useAuth();

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/track' element={<Track />} />
      <Route path='/return' element={<Return />} />
      <Route path='/product-and-services' element={<ProductAndServices />} />
      <Route path='/basket' element={<Basket />} />
      <Route path='confirm' element={<DropOff />} />
      <Route path='confirm/label' element={<DropOffLabel />} />
      <Route path='confirm/collection' element={<Collection />} />
      <Route
        path={`${PROFILE}/*`}
        element={
          <ProtectedRoute
            isEnabled={!!currentSession.uid}
            redirectTo={SIGN_IN_WITH_EMAIL}
            element={<UserPreferences />}
          />
        }
      >
        <Route index element={<OverviewSection />} />
        <Route path={ACCOUNT} element={<AccountSection />} />
        <Route path={ADDRESSES} element={<AddressesSection />} />
        <Route path={ORDER_VIEW} element={<OrderView />} />
        <Route path={ORDERS} element={<OrdersSection />} />
      </Route>
      <Route path='signIn'>
        <Route path='email' element={<VerifyEmail />} />
        <Route path='terms' element={<TermsAndConditions />} />
      </Route>
      <Route path='mapsandbox' element={<MapSandbox />} />
      <Route path='order' element={<Order />} />
      <Route path='quotes' element={<Quotes />} />
      <Route path='payment/success' element={<PaymentSuccess />} />
      <Route path={PICKUP_FINDER} element={<ShopFinder />} />
      <Route path='dev' element={<DevRoutes />}>
        <Route path='/dev/playground' element={<Playground />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
