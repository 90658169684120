import HomeCollection from '../../components/icons/HomeCollection';
import LabelDropOff from '../../components/icons/LabelDropOff';
import LabelPack from '../../components/icons/LabelPack';
import LabelPrint from '../../components/icons/LabelPrint';

export const DROP_OFF_STEPS_CONFIG = [
  {
    id: 1,
    icon: LabelPrint,
    text: 'Pack your item to avoid any transport damage.',
  },
  {
    id: 2,
    icon: LabelPack,
    text: 'Print your label and stick it on the parcel. Make sure to cover other labels on the package.',
  },
  {
    id: 3,
    icon: LabelDropOff,
    text: 'Bring the parcel to a drop-off of your choice.',
  },
];

export const COLLECTION_STEPS_CONFIG = [
  {
    id: 1,
    icon: LabelPrint,
    text: 'Pack your item to avoid any transport damage.',
  },
  {
    id: 2,
    icon: LabelPack,
    text: 'Print your label and stick it on the parcel. Make sure to cover other labels on the package.',
  },
  {
    id: 3,
    icon: HomeCollection,
    text: 'Tuesday, 12 July /nThe driver will collect your parcel on your selected day between 8:00 and 17:00.',
  },
];
