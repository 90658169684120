import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';

export const initState = {
  activeStep: null,
  activeNamedStep: null,
  values: {},
  references: {},
};

const emptyObject = {};

export const createWizardSelectors = name => {
  const exports = {};
  exports.getWizardState = state => state[name];

  exports.getActiveNamedStep = createSelector(
    exports.getWizardState,
    state => state.activeNamedStep
  );
  exports.getValues = createSelector(
    exports.getWizardState,
    state => state.values || emptyObject
  );
  exports.getReferences = createSelector(
    exports.getWizardState,
    state => state.references || emptyObject
  );
  exports.getReferencesByName = createSelector(
    exports.getValues,
    (state, name) => name,
    (references, name) => references[name] || emptyObject
  );
  exports.getValuesByStepName = createSelector(
    exports.getValues,
    (state, stepName) => stepName,
    (values, stepName) => values[stepName] || emptyObject
  );
  exports.getActiveStepValues = createSelector(
    exports.getValues,
    exports.getActiveNamedStep,
    (values, activeStep) => values[activeStep] || emptyObject
  );
  return exports;
};

export const createWizardSlice = (
  name,
  initialState = initState,
  reducers = {}
) => {
  const slice = createSlice({
    name,
    initialState,
    reducers: {
      initialize(state, { payload }) {
        state.values = payload.values;
        state.references = payload.references;
      },

      setActiveStep(state, { payload }) {
        const { activeStep, activeNamedStep } = payload;
        state.activeStep = activeStep;
        state.activeNamedStep = activeNamedStep;
      },

      // used in pair with prop isStepValues = false
      setValues(state, { payload }) {
        state.values = payload;
      },

      setStepValues(state, { payload }) {
        state.values[payload.stepName] = payload.values;
      },

      setActiveStepValues(state, { payload }) {
        state.values[state.activeNamedStep] = payload;
      },

      setReferences(state, { payload }) {
        if (Array.isArray(payload)) {
          const [name, value] = payload;
          state.references[name] = value;
        } else {
          state.references = payload;
        }
      },

      clear: () => initialState,

      ...reducers,
    },
  });
  return {
    reducer: slice.reducer,
    actions: slice.actions,
    selectors: createWizardSelectors(name),
  };
};
