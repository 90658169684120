import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';

import { OrderSliceSelectors } from '../../slice';

export const getBillingAddress = createSelector(
  [OrderSliceSelectors.getValues],
  values => {
    const collectionLocality =
      values.outboundConsignment.collectionDetails.address.locality;
    return {
      phoneNumber:
        values.outboundConsignment.collectionDetails.contactDetails.telephone,
      streetAddress:
        values.outboundConsignment.collectionDetails.address.street,
      extendedAddress: collectionLocality, // ?
      locality: collectionLocality,
      region: values.outboundConsignment.collectionDetails.address.county,
      postalCode: values.outboundConsignment.collectionDetails.address.postcode,
      countryCodeAlpha2:
        values.outboundConsignment.collectionDetails.address.countryCode,
    };
  }
);

export const getBillingEmail = createSelector(
  [OrderSliceSelectors.getValues],
  values => values.outboundConsignment.collectionDetails.contactDetails.email
);

export const getBillingMobilePhone = createSelector(
  [OrderSliceSelectors.getValues],
  values =>
    values.outboundConsignment.collectionDetails.contactDetails.telephone
);

export const getThreeDSecureData = createStructuredSelector({
  email: getBillingEmail,
  mobilePhoneNumber: getBillingMobilePhone,
  billingAddress: getBillingAddress,
});
