import PropTypes from 'prop-types';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Chip,
  Box,
  Grid,
} from '@mui/material';
import { CheckCircle, InfoOutlined } from '@mui/icons-material';

const SIZING_CHIP_POSITION_OPTIONS = {
  underTitle: 'underTitle',
  withTitle: 'withTitle',
};

const ActionButton = ({
  title,
  bodyText,
  selected,
  sizingChipPosition = SIZING_CHIP_POSITION_OPTIONS.underTitle,
  sizingChip,
  highlightChip,
  textButton,
  printerRequired,
  price = '0.00',
  buttonOnclick,
  cardOnclick,
  disabled,
}) => {
  const sizingChipElement = sizingChip ? (
    <Chip
      label={sizingChip}
      size='small'
      color='primary'
      sx={{
        borderRadius: 1,
        mb: 1,
        mt:
          sizingChipPosition === SIZING_CHIP_POSITION_OPTIONS.withTitle
            ? 1
            : 0.5,
      }}
    />
  ) : null;

  return (
    <div style={{ width: '100%' }}>
      <Card
        variant='outlined'
        sx={{
          borderColor: selected ? 'primary.main' : null,
          borderWidth: 2,
          position: 'relative',
          overflow: 'visible',
          cursor: !disabled && 'pointer',
          height: '100%',
          opacity: disabled ? 0.4 : 1,
        }}
        onClick={!disabled ? cardOnclick : () => {}}
      >
        {highlightChip ? (
          <Chip
            label={highlightChip}
            color='primary'
            size='small'
            sx={{
              position: 'absolute',
              top: -12,
              left: 8,
              borderRadius: 1,
              zIndex: 10000,
            }}
          />
        ) : null}
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Grid>
            <Grid container alignItems='center' justifyContent='space-between'>
              <Typography variant='h6' component='h2'>
                {title}
              </Typography>
              <Box display='flex' alignItems='center'>
                {sizingChipPosition === SIZING_CHIP_POSITION_OPTIONS.withTitle
                  ? sizingChipElement
                  : null}
                {selected ? <CheckCircle color='primary' /> : null}
              </Box>
            </Grid>
            {sizingChipPosition === SIZING_CHIP_POSITION_OPTIONS.underTitle
              ? sizingChipElement
              : null}
            <Typography variant='body2' color='textSecondary'>
              {bodyText}
            </Typography>
          </Grid>
          <Box
            display='flex'
            justifyContent={printerRequired ? 'space-between' : 'flex-end'}
          >
            {printerRequired ? (
              <Box display='flex' alignItems='center' mt={1}>
                <InfoOutlined />
                <Typography variant='body1' ml={0.5}>
                  Printer required
                </Typography>
              </Box>
            ) : null}
            <Typography variant='body1' color='textSecondary' mt={1}>
              {price}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      {textButton ? (
        <CardActions>
          <Button
            size='small'
            sx={{ color: 'primary.main' }}
            onClick={buttonOnclick}
            disabled={disabled}
          >
            {textButton}
          </Button>
        </CardActions>
      ) : null}
    </div>
  );
};

ActionButton.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  sizingChipPosition: PropTypes.oneOf([
    SIZING_CHIP_POSITION_OPTIONS.underTitle,
    SIZING_CHIP_POSITION_OPTIONS.withTitle,
  ]),
  sizingChip: PropTypes.string,
  highlightChip: PropTypes.string,
  textButton: PropTypes.string,
  printerRequired: PropTypes.bool,
  price: PropTypes.string,
  buttonOnclick: PropTypes.func,
  cardOnclick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ActionButton;
