import { createContext, useContext } from 'react';

export const WizardContext = createContext({});

export const useWizard = () => useContext(WizardContext);

// eslint-disable-next-line react/display-name
export const withWizard = Component => props =>
  (
    <WizardContext.Consumer>
      {context => <Component {...props} wizard={context} />}
    </WizardContext.Consumer>
  );
