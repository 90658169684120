import { upperFirst } from 'lodash';
import { Button, Divider, Typography, Grid, Paper } from '@mui/material';
import { Delete, Edit, Add, Remove } from '@mui/icons-material';

import BasketCardDetails from '../BasketCardDetails';
import {
  SHIPPING_DETAILS,
  EDIT,
  REMOVE,
  REMOVE_FROM_CHECKOUT,
  ADD_TO_CHECKOUT,
  PARCEL_TO_$,
} from '../../constants/strings';
import { getReceiverContactName } from '../../utils/basketItem';

const BasketCard = ({
  basketItem,
  onRemove,
  isCheckoutListEditable,
  isInCheckoutList,
  onAddToCheckout,
  onRemoveFromCheckout,
}) => {
  const {
    shipment: { outboundConsignment },
  } = basketItem;
  const receiverContactName = getReceiverContactName(basketItem);

  return (
    <>
      <Grid item sx={{ width: '100%' }}>
        {/* TODO: set proper text */}
        <Divider variant='middle' sx={{ textTransform: 'uppercase' }}>
          to be collected 13 July 2024
        </Divider>
      </Grid>
      <Grid sx={{ mt: 1 }}>
        <Paper sx={{ mb: 3 }}>
          <Grid sx={{ p: 2 }}>
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Typography variant='h3'>
                {upperFirst(
                  PARCEL_TO_$(
                    receiverContactName,
                    outboundConsignment.parcels.length
                  )
                )}
              </Typography>
              {outboundConsignment.customsValue && (
                <Typography variant='h3' color='primary.main'>
                  {`£${outboundConsignment.customsValue}`}
                </Typography>
              )}
            </Grid>
            {/* TODO: set proper text */}
            <Typography variant='body2'>Parcel Next Day</Typography>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container sx={{ p: 2 }}>
            <BasketCardDetails
              data={outboundConsignment.collectionDetails}
              title={SHIPPING_DETAILS.SENDER}
            />
            <BasketCardDetails
              data={outboundConsignment.deliveryDetails}
              title={SHIPPING_DETAILS.RECEIVER}
            />
          </Grid>
          <Divider />
          <Grid container sx={{ p: 2 }}>
            <Grid item display='flex' flexGrow={1} justifyContent='center'>
              <Button
                startIcon={<Edit />}
                href={`/order?basketItemId=${basketItem.basketItemId}`}
              >
                {EDIT}
              </Button>
            </Grid>
            <Grid item display='flex' flexGrow={1} justifyContent='center'>
              <Button startIcon={<Delete />} onClick={onRemove}>
                {REMOVE}
              </Button>
            </Grid>
            {isCheckoutListEditable && (
              <Grid item justifyContent='flex-end'>
                <Button
                  variant={isInCheckoutList ? 'contained' : 'outlined'}
                  onClick={() =>
                    isInCheckoutList
                      ? onRemoveFromCheckout(basketItem.basketItemId)
                      : onAddToCheckout(basketItem.basketItemId)
                  }
                  startIcon={isInCheckoutList ? <Remove /> : <Add />}
                  sx={{ width: { xs: '100%', md: '240px' } }}
                >
                  {isInCheckoutList ? REMOVE_FROM_CHECKOUT : ADD_TO_CHECKOUT}
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default BasketCard;
