import { Box } from '@mui/material';
import useBreakpoint from '../../../../hooks/useBreakpoint';

const AccountSectionLayout = ({ children }) => {
  const isSmallScreen = useBreakpoint('sm');

  return (
    <Box
      sx={{
        borderRadius: isSmallScreen ? 0 : '8px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        marginBottom: 1,
        boxShadow: 1,
        backgroundColor: theme => theme.palette.background.default,
      }}
    >
      {children}
    </Box>
  );
};

export default AccountSectionLayout;
