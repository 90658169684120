import Joi from 'joi';
import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CITY,
  COUNTRY,
  COUNTY,
  ORGANISATION,
  POSTAL_ZIP_CODE,
  QUANTITY,
} from '../../../constants/forms';
import { POINT_TYPE, REGEX } from '../../../constants';
import { USER_TYPE } from '../constants';
import { parcelSchema } from './parcel';

const empty = [null, ''];

const numberOfParcels = Joi.number().min(1).max(99).label(QUANTITY);
const totalWeight = Joi.number().greater(0);

const addressSchema = {
  street: Joi.string().max(35).label(ADDRESS_LINE_1),
  locality: Joi.string().max(35).allow(null, '').label(ADDRESS_LINE_2),
  town: Joi.string().max(35).label(CITY),
  county: Joi.string().max(35).allow(null, '').label(COUNTY),
  postcode: Joi.string().max(15).label(POSTAL_ZIP_CODE),
  countryCode: Joi.string().label(COUNTRY),
};

export const addressDetailsSchema = Joi.object({
  addressId: Joi.string().allow(null),
  ...addressSchema,
  organisation: Joi.string().max(35).allow(null, '').label(ORGANISATION),
  locality: Joi.string().max(35).allow(null, '').label(ADDRESS_LINE_2),
}).unknown();

export const contactDetails = () =>
  Joi.object({
    contactName: Joi.string().allow(null),
    telephone: Joi.string().allow(null, ''),
  });

export const email = () => Joi.string().max(100).regex(REGEX.EMAIL_REGEX);

export const notificationDetails = () =>
  Joi.object({
    mobile: Joi.string(),
    email: email(),
  });

export const pointType = () =>
  Joi.string().valid(POINT_TYPE.SHOP, POINT_TYPE.DOOR);

export const shipmentUserType = () =>
  Joi.string().valid(USER_TYPE.INDIVIDUAL, USER_TYPE.COMPANY);

export const shipmentSchema = () =>
  Joi.object({
    shipmentDate: Joi.string().allow(null),
    outboundConsignment: Joi.object({
      deliveryDetails: {
        address: addressDetailsSchema,
        contactDetails: contactDetails().allow(null),
        notificationDetails: notificationDetails().allow(null),
        _deliverTo: pointType(),
        _userType: shipmentUserType().allow(null),
      },

      collectionDetails: {
        address: addressDetailsSchema,
        contactDetails: contactDetails()
          .keys({
            email: email().allow(null),
          })
          .allow(null),
        dropAtShop: Joi.boolean().allow(null),
        _collectFrom: pointType(),
        _userType: shipmentUserType().allow(null),
      },

      pickupDetails: Joi.object({
        pickupLocationCode: Joi.string().max(20),
        pickupCountryCode: Joi.string().max(2).regex(REGEX.COUNTRY_CODE),
        pickupOrganisation: Joi.string()
          .max(35)
          .allow(...empty),
        pickupPostcode: Joi.string().allow(...empty),
        pickupAddressLine1: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine2: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine3: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine4: Joi.string()
          .max(35)
          .allow(...empty),
        allowRemotePickup: Joi.boolean().allow(null),
      }).allow(null),

      _dropOffDetails: Joi.object({
        pickupLocationCode: Joi.string().max(20),
        pickupCountryCode: Joi.string().max(2).regex(REGEX.COUNTRY_CODE),
        pickupOrganisation: Joi.string()
          .max(35)
          .allow(...empty),
        pickupPostcode: Joi.string().allow(...empty),
        pickupAddressLine1: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine2: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine3: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine4: Joi.string()
          .max(35)
          .allow(...empty),
      }).allow(null),
      numberOfParcels,
      totalWeight,
      parcelDescription: Joi.string().max(45).allow('', null),
      networkCode: Joi.string(),
      liabilityValue: Joi.number(),
      parcels: Joi.array().items(
        parcelSchema({
          maxWeight: Joi.ref('$maxWeight'),
          maxQuantity: Joi.ref('$maxParcelNumber'),
        })
      ),
    }),
    invoice: Joi.object().allow(null),
  });
