export const formatMessage = (template, ...args) =>
  template.replace(/{(\d)}/g, (s, num) => args[num]);

export const replaceLineBreakWithBr = string => {
  if (typeof string !== 'string') {
    return null;
  }
  const splittedString = string.split('\n');
  return splittedString.length > 1
    ? splittedString.map((item, index) => (
        <span key={index}>
          {item}
          <br />
        </span>
      ))
    : string;
};

export const removeExtraSpaces = string => string?.replace(/ +(?= )/g, '');
