import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, includes } from 'lodash';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import useAnalytics from '../../utils/useAnalytics';
import { getReceiverContactName } from '../../utils/basketItem';
import { BASKET } from '../../constants/analytics';
import {
  WANT_TO_REMOVE_$,
  ADDRESSES_SECTION,
  YOUR_CART,
  PROCESS_$_PARCELS,
  PARCEL_TO_$,
  EMPTY_CARD,
} from '../../constants/strings';
import { SummaryDetails } from '../../components/SummaryDetails';
import Voucher from '../../components/Voucher';
import SalesConditions from '../../components/SalesConditions';
import InformationCollecting from '../../components/InformationCollecting';
import BasketCard from '../../components/BasketCard/BasketCard';
import ConfirmDialog from '../../components/modals/ConfirmModal';
import useBreakpoint from '../../hooks/useBreakpoint';
import { BasketActions, BasketSelectors } from '../../redux/basket';

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: theme.spacing(2),
  height: '100%',
  marginTop: theme.spacing(4),
}));

const Basket = () => {
  const dispatch = useDispatch();
  const basketItems = useSelector(BasketSelectors.getBasketItems);
  const isSmallScreen = useBreakpoint('sm');
  const theme = useTheme();
  const { setInterfaceId, Trackable } = useAnalytics();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [voucher, setVoucher] = useState(); // TODO: use voucher data on 'Your Summary'
  const [checkoutList, setCheckoutList] = useState(
    basketItems.map(basketItem => basketItem.basketItemId)
  );
  const [selectedBasketItem, setSelectedBasketItem] = useState();
  const receiverContactName = getReceiverContactName(selectedBasketItem);
  const parcels = get(
    selectedBasketItem,
    'shipment.outboundConsignment.parcels',
    []
  );

  const isCheckoutButtonDisabled = !acceptTerms || !checkoutList.length;

  const onAddToCheckout = basketItemId =>
    setCheckoutList([...checkoutList, basketItemId]);

  const onRemoveFromCheckout = basketItemIdToRemove =>
    setCheckoutList(
      checkoutList.filter(basketItemId => basketItemId !== basketItemIdToRemove)
    );

  const onDeleteBasketItem = async () => {
    await dispatch(
      BasketActions.deleteBasketItem(selectedBasketItem.basketItemId)
    ).unwrap();
    onRemoveFromCheckout(selectedBasketItem.basketItemId);
    setSelectedBasketItem(null);
  };

  useEffect(() => {
    setInterfaceId(BASKET.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={BASKET.LOAD}>
      <ConfirmDialog
        open={!!selectedBasketItem}
        onConfirm={onDeleteBasketItem}
        onClose={() => setSelectedBasketItem(null)}
        title={ADDRESSES_SECTION.DELETE_MODAL.TITLE}
        confirmButtonText={ADDRESSES_SECTION.DELETE_MODAL.DELETE_BUTTON}
        containerStyle={{
          maxWidth: isSmallScreen ? '100%' : '400px',
        }}
      >
        {WANT_TO_REMOVE_$(PARCEL_TO_$(receiverContactName, parcels.length))}
      </ConfirmDialog>
      <Grid
        sx={{
          background: theme.palette.primary.pageBackground,
          pb: 4,
          flexGrow: 1,
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
            mt: 1,
          }}
        >
          <Grid item>
            <Typography variant='h2' sx={{ m: 2 }} color='primary.main'>
              {YOUR_CART}
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems='center' justifyContent='center'>
          {basketItems.length ? (
            <Grid container spacing={2} sx={{ mt: 0 }} xs={12} md={10}>
              <Grid item xs={12} md={8}>
                <Grid sx={{ mt: 1 }}>
                  {basketItems.map(basketItem => (
                    <BasketCard
                      key={basketItem.basketItemId}
                      basketItem={basketItem}
                      onRemove={() => setSelectedBasketItem(basketItem)}
                      isCheckoutListEditable={basketItems.length > 1}
                      isInCheckoutList={includes(
                        checkoutList,
                        basketItem.basketItemId
                      )}
                      onAddToCheckout={onAddToCheckout}
                      onRemoveFromCheckout={onRemoveFromCheckout}
                    />
                  ))}
                  <SalesConditions onSetCheck={setAcceptTerms} />
                  <InformationCollecting sx={{ pl: 2, pr: 2 }} />
                </Grid>
              </Grid>
              <StyledGrid item xs={12} md={4}>
                <SummaryDetails />
                <Voucher voucher={voucher} onChange={setVoucher} />
                <Box>
                  <Button
                    id='basket-button-next'
                    disabled={isCheckoutButtonDisabled}
                    variant='contained'
                    sx={{ width: '100%' }}
                  >
                    {PROCESS_$_PARCELS(checkoutList.length)}
                  </Button>
                </Box>
              </StyledGrid>
            </Grid>
          ) : (
            <Typography sx={{ mt: 1, color: 'text.secondary' }}>
              {EMPTY_CARD.toUpperCase()}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Trackable>
  );
};

export default Basket;
