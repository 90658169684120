import { SvgIcon } from '@mui/material';

const LabelDropOff = ({ styles }) => (
  <SvgIcon
    sx={{ width: '35px', height: '35px', ...styles }}
    viewBox='0 0 36 35'
  >
    <g clipPath='url(#clip0_6609_4361)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.9205 23.4646C31.9205 23.4606 31.9179 23.458 31.9139 23.458H4.08622C4.08224 23.458 4.07959 23.4606 4.07959 23.4646V29.4318C4.07959 30.5302 4.96984 31.4205 6.06823 31.4205H29.9319C31.0303 31.4205 31.9205 30.5302 31.9205 29.4318V23.4646ZM11.3712 28.1021H24.6288C24.9954 28.1021 25.2917 27.8058 25.2917 27.4392C25.2917 27.0726 24.9954 26.7763 24.6288 26.7763H11.3712C11.0053 26.7763 10.7084 27.0726 10.7084 27.4392C10.7084 27.8058 11.0053 28.1021 11.3712 28.1021Z'
        fill='#DC0032'
      />
      <path
        d='M31.9203 24.1208H4.07938C3.71347 24.1208 3.4165 23.8245 3.4165 23.458C3.4165 23.0914 3.71347 22.7951 4.07938 22.7951H31.9203C32.2869 22.7951 32.5832 23.0914 32.5832 23.458C32.5832 23.8245 32.2869 24.1208 31.9203 24.1208Z'
        fill='#414042'
      />
      <path
        d='M29.9317 32.0833H6.06802C4.60571 32.0833 3.4165 30.8941 3.4165 29.4318V5.56819C3.4165 4.10588 4.60571 2.91667 6.06802 2.91667H29.9317C31.394 2.91667 32.5832 4.10588 32.5832 5.56819V29.4318C32.5832 30.8941 31.394 32.0833 29.9317 32.0833ZM6.06802 4.24243C5.33686 4.24243 4.74226 4.83703 4.74226 5.56819V29.4318C4.74226 30.163 5.33686 30.7576 6.06802 30.7576H29.9317C30.6628 30.7576 31.2574 30.163 31.2574 29.4318V5.56819C31.2574 4.83703 30.6628 4.24243 29.9317 4.24243H6.06802Z'
        fill='#414042'
      />
      <path
        d='M24.2967 10.7221L20.7257 12.8632C20.686 12.8864 20.6349 12.8983 20.5826 12.8983C20.5249 12.8983 20.4699 12.8837 20.4288 12.8579L20.3181 12.7889L19.9548 12.5635C19.8739 12.5138 19.811 12.4005 19.811 12.3057V11.9318V11.7449C19.811 11.6494 19.8759 11.5149 19.9588 11.4671L20.0317 11.4234L23.6828 9.23391L18.2612 6.22842C18.1345 6.15815 17.9271 6.15749 17.8005 6.22775L12.2091 9.26374L18.6927 12.9745C18.7769 13.0203 18.8405 13.1296 18.8405 13.2284V13.2529V13.6076L18.8399 14.4289L18.8379 18.5818C18.8379 18.6786 18.7716 18.79 18.6867 18.835L18.1982 19.1055C18.1982 19.1055 18.1929 19.1062 18.1909 19.1075C18.1511 19.1267 18.1034 19.1393 18.0524 19.1393H18.0431C17.9847 19.1406 17.9297 19.1294 17.8873 19.1062L17.5791 18.9358L17.3968 18.835C17.3132 18.7906 17.2476 18.6786 17.2476 18.5818L17.2463 14.4289H17.245V14.0736C17.2403 14.0232 17.2039 13.9616 17.1634 13.939L11.7053 10.7817L11.7026 16.9538C11.7026 17.0983 11.8047 17.2773 11.93 17.3502L17.8124 20.7965C17.9377 20.8701 18.1418 20.8694 18.2665 20.7952L24.0713 17.3456C24.1959 17.2713 24.2973 17.0923 24.2973 16.9478L24.2967 10.7221Z'
        fill='#DC0032'
      />
    </g>
    <defs>
      <clipPath id='clip0_6609_4361'>
        <rect width='35' height='35' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default LabelDropOff;
