import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import Sentry, { getSentryUser } from '../../sentry';
import { useOverlay } from '../Overlay';
import { getAuthData } from './selectors';
import { AuthContext } from './Context';
import * as Actions from './actions';

const AuthProvider = props => {
  const dispatch = useDispatch();
  const { currentSession, isLoading: isCurrentSessionLoading } =
    useSelector(getAuthData);
  const overlay = useOverlay();

  const value = useMemo(() => {
    const actions = bindActionCreators(Actions, dispatch);
    return {
      ...actions,
      currentSession,
    };
  }, [dispatch, currentSession]);

  useEffect(() => {
    Sentry.setUser(getSentryUser(currentSession));
  }, [currentSession]);

  useEffect(() => {
    overlay.show();
    dispatch(Actions.getCurrentSession())
      .unwrap()
      .finally(() => {
        overlay.hide();
      });
  }, []);

  return (
    <AuthContext.Provider value={value}>
      {isCurrentSessionLoading ? null : props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
