import { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { PAYMENT_SUCCESS } from '../../../constants/analytics';
import useAnalytics from '../../../utils/useAnalytics';
import { SummaryDetails } from '../../../components/SummaryDetails';

const PaymentSuccess = () => {
  const { setInterfaceId, Trackable } = useAnalytics();
  useEffect(() => {
    setInterfaceId(PAYMENT_SUCCESS.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={PAYMENT_SUCCESS.LOAD}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          <Typography sx={{ m: 2 }}>
            Payment success Mock. Waiting for design
          </Typography>
          <SummaryDetails />
        </Grid>
      </Grid>
    </Trackable>
  );
};

export default PaymentSuccess;
