import apis from './apis';

export const createBasket = () => apis.post('/baskets/');

export const getBasketItems = basketId =>
  apis.get(`/baskets/${basketId}/items`);

export const createBasketItem = (basketId, data) =>
  apis.post(`/baskets/${basketId}/items`, data);

export const fetchBasketItem = (basketId, basketItemId) =>
  apis.get(`/baskets/${basketId}/items/${basketItemId}`);

export const updateBasketItem = (basketId, basketItemId, shipment) =>
  apis.put(`/baskets/${basketId}/items/${basketItemId}`, shipment);

export const deleteBasketItem = (basketId, basketItemId) =>
  apis.delete(`/baskets/${basketId}/items/${basketItemId}`);
