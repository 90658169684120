import { createSelector } from '@reduxjs/toolkit';
import { OrderSliceSelectors } from './slice';

export const getTotalAmount = createSelector(
  [OrderSliceSelectors.getValues],
  values => ({
    liabilityAmount: 2,
    parcelAmount: 8,
    amount: 10, // liabilityAmount + parcelAmount
    taxAmount: 2,
    totalIncVatAmount: '14.00',
  })
);
