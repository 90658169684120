export const INVALID_AUTH_CODE = 'auth/invalid-verification-id';
export const INVALID_VERIFICATION_ID_CODE = 'auth/verification-id-invalid';
export const EXPIRED_AUTH_CODE = 'auth/link-expired';
export const REACH_MAX_LOGIN_ATTEMPTS_CODE = 'auth/reached-max-login-attempts';
export const TOO_MANY_REQUESTS_CODE = 'auth/too-many-requests';

export const EXPIRED_AUTH_CODES = [
  EXPIRED_AUTH_CODE,
  INVALID_AUTH_CODE,
  INVALID_VERIFICATION_ID_CODE,
];
