export const normalizeAddressBookDataForPayload = ({
  data,
  isUpdate = false,
}) => {
  const { countryCode, street, locality, town, county, postcode } =
    data.address;
  const { nickname, contactName, email, phoneNumber } = data;

  return {
    countryCode,
    addressLine1: street,
    addressLine3: town,
    postcode,
    nickname,
    contactName,
    email,
    phoneNumber,
    ...(isUpdate && { isDefault: data.isDefault }),
    ...(locality && { addressLine2: locality }),
    ...(county && { addressLine4: county }),
  };
};

export const normalizeAddressBookDataForDefaultMarkChangePayload = data => {
  const baseAddressBookData = {
    countryCode: data?.countryCode,
    addressLine1: data?.addressLine1,
    addressLine3: data?.addressLine3,
    postcode: data?.postcode,
    nickname: data?.nickname,
    contactName: data?.contactName,
    email: data?.email,
    phoneNumber: data?.phoneNumber,
  };

  return {
    ...baseAddressBookData,
    ...(data?.addressLine2 && { addressLine2: data.addressLine2 }),
    ...(data?.addressLine4 && { addressLine4: data.addressLine4 }),
  };
};

export const normalizeAddressBookDataFromResponse = data => ({
  addressBookId: data?.addressBookId,
  isDefault: data?.isDefault,
  address: {
    countryCode: data?.countryCode,
    street: data?.addressLine1,
    locality: data?.addressLine2,
    town: data?.addressLine3,
    county: data?.addressLine4,
    postcode: data?.postcode,
  },
  nickname: data?.nickname,
  contactName: data?.contactName,
  email: data?.email,
  phoneNumber: data?.phoneNumber,
});

export const normalizeFormValuesForValidation = ({ address, ...rest }) => {
  const { contactName, nickname, email, phoneNumber } = rest;

  return {
    address: {
      countryCode: address?.countryCode,
      street: address?.street,
      locality: address?.locality,
      town: address?.town,
      county: address?.county,
      postcode: address?.postcode,
    },
    contactName,
    nickname,
    email,
    phoneNumber,
  };
};

export const sortAddressBooks = (addressBooks = []) => {
  if (addressBooks.length === 0) {
    return addressBooks;
  }

  return addressBooks.sort((a, b) => {
    // First criterion: isDefault
    if (a.isDefault !== b.isDefault) {
      return a.isDefault ? -1 : 1; // Pin true values to the top
    }

    return a.nickname.toLowerCase().localeCompare(b.nickname.toLowerCase());
  });
};
