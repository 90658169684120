import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  interfaceId: null,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setInterfaceId(state, action) {
      state.interfaceId = action.payload;
    },
  },
});

export const { setInterfaceId } = analyticsSlice.actions;
export const selectInterfaceId = state => state.analytics.interfaceId;
export default analyticsSlice.reducer;
