import { Typography, Paper, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { SHIPPING_DETAILS } from '../../constants/strings';
import { countriesSelectors } from '../../redux/countriesSlice';

const StyledTypography = styled(Typography)({
  wordWrap: 'break-word',
});

const ShippingDetails = ({ sx, shipment }) => {
  const collectionCountryName = useSelector(state =>
    countriesSelectors.getCountryName(
      state,
      shipment.outboundConsignment.collectionDetails.address.countryCode
    )
  );
  const deliveryCountryName = useSelector(state =>
    countriesSelectors.getCountryName(
      state,
      shipment.outboundConsignment.deliveryDetails.address.countryCode
    )
  );
  const organisation =
    shipment.outboundConsignment.deliveryDetails.address.organisation;
  return (
    <Paper sx={{ p: 2, ...sx }}>
      <Typography variant='h3'>{SHIPPING_DETAILS.TITLE}</Typography>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={6}>
          <Typography variant='body1' sx={{ color: 'primary.main' }}>
            {SHIPPING_DETAILS.SENDER}
          </Typography>
          <StyledTypography variant='body1'>
            {
              shipment.outboundConsignment.collectionDetails.contactDetails
                .contactName
            }
          </StyledTypography>
          {organisation ? (
            <Typography variant='body1'>{organisation}</Typography>
          ) : null}
          <StyledTypography variant='body1'>
            {shipment.outboundConsignment.collectionDetails.address.street}
          </StyledTypography>
          <StyledTypography variant='body1'>
            {shipment.outboundConsignment.collectionDetails.address.town}
          </StyledTypography>
          <StyledTypography variant='body1'>
            {shipment.outboundConsignment.collectionDetails.address.postcode}
          </StyledTypography>
          <StyledTypography variant='body1'>
            {collectionCountryName}
          </StyledTypography>
          <StyledTypography variant='body1' sx={{ mt: 3 }}>
            {
              shipment.outboundConsignment.collectionDetails.contactDetails
                .telephone
            }
          </StyledTypography>
          <StyledTypography variant='body1'>
            {
              shipment.outboundConsignment.collectionDetails.contactDetails
                .email
            }
          </StyledTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='body1' sx={{ color: 'primary.main' }}>
            {SHIPPING_DETAILS.RECEIVER}
          </Typography>
          <StyledTypography variant='body1'>
            {
              shipment.outboundConsignment.deliveryDetails.contactDetails
                .contactName
            }
          </StyledTypography>
          {organisation ? (
            <StyledTypography variant='body1'>{organisation}</StyledTypography>
          ) : null}
          <StyledTypography variant='body1'>
            {shipment.outboundConsignment.deliveryDetails.address.street}
          </StyledTypography>
          <StyledTypography variant='body1'>
            {shipment.outboundConsignment.deliveryDetails.address.town}
          </StyledTypography>
          <StyledTypography variant='body1'>
            {shipment.outboundConsignment.deliveryDetails.address.postcode}
          </StyledTypography>
          <StyledTypography variant='body1'>
            {deliveryCountryName}
          </StyledTypography>
          <StyledTypography variant='body1' sx={{ mt: 3 }}>
            {
              shipment.outboundConsignment.deliveryDetails.contactDetails
                .telephone
            }
          </StyledTypography>
          <StyledTypography variant='body1'>
            {shipment.outboundConsignment.deliveryDetails.contactDetails.email}
          </StyledTypography>
        </Grid>
      </Grid>
    </Paper>
  );
};

ShippingDetails.propTypes = {
  sx: PropTypes.object,
  shipment: PropTypes.object,
};

export default ShippingDetails;
